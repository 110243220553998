import React, { useState, useEffect } from 'react'
import { Container, Card, Row, Col, Dropdown, OverlayTrigger, Tooltip, Button, Modal } from 'react-bootstrap'
import { Link, useParams, useNavigate } from 'react-router-dom'
import ProfileHeader from '../../../../components/profile-header'
import CustomToggle from '../../../../components/dropdowns'
import ShareOffcanvas from '../../../../components/share-offcanvas'
import LoaderModal from '../../../../components/loaderModal'
import { InView } from "react-intersection-observer";
import Moment from 'moment';
import { apiHandler } from '../../../../api/apihandler';
import { EVENT_DETAILS, FEEDS_LIST, PUBLIC_EVENT_DETAILS } from '../../../../api/constants'

//image
import loader from '../../../../assets/images/page-img/page-load-loader.gif'
import user1 from '../../../../assets/images/user/05.jpg'
import user2 from '../../../../assets/images/user/06.jpg'
import user3 from '../../../../assets/images/user/07.jpg'
import user4 from '../../../../assets/images/user/08.jpg'
import user5 from '../../../../assets/images/user/09.jpg'
import user6 from '../../../../assets/images/user/10.jpg'
import user7 from '../../../../assets/images/user/11.jpg'
import user8 from '../../../../assets/images/user/12.jpg'
import header from '../../../../assets/images/page-img/profile-bg7.jpg'
import FeedPostCard from '../../../../components/feeds/feedPostCard'
import CreatePostModal from '../../../../components/createPost/createPostModal'
import NoDataFound from '../../../../components/noDataFound'
import Swal from 'sweetalert2'

// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux';
import { setPostModalShow, setCreatePostType } from '../../../../store/setting/actions'
import CreatePostInputBox from '../../../../components/createPost/createPostInputBox'


const PublicEventDetails = () => {
    const dispatch = useDispatch()
    const { slug } = useParams();
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [eventDetailsData, setEventDetailsData] = useState('');
    const [eventId, setEventId] = useState('');
    const [feedsList, setFeedsList] = useState([]);
    const [page, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [showLoader, setShowLoader] = useState(false);
    const [showMoreDataLoader, setShowMoreDataLoader] = useState(false);

    const [isFirst, setIsFirst] = useState(true);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        setIsFirst(false)
    }, [isFirst]);

    useEffect(() => {
        dispatch(setCreatePostType("5"))
        if (localStorage.getItem("uzoneAuthToken")) {
            getEventDetails()
        } else {
            getPublicEventDetails()
        }
    }, []);

    async function getEventDetails() {
        setShowLoader(true)
        try {
            const res = await apiHandler(EVENT_DETAILS, "POST", {
                slug: slug
            })
            if (res.data?.response?.status === 200) {
                setEventId(res?.data?.data?.event?.id)
                setEventDetailsData(res?.data?.data?.event)
                getFeedsList(1, res?.data?.data?.event?.id)
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setShowLoader(false)
        }
    }

    async function getPublicEventDetails() {
        setShowLoader(true)
        try {
            const res = await apiHandler(PUBLIC_EVENT_DETAILS, "POST", {
                slug: slug
            })
            if (res.data?.response?.status === 200) {
                setEventId(res?.data?.data?.event?.id)
                setEventDetailsData(res?.data?.data?.event)
                getFeedsList(1, res?.data?.data?.event?.id)
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setShowLoader(false)
        }
    }

    const loadMore = (inView) => {
        if (inView) {
            if (page < maxPage) {
                getFeedsList(page + 1, eventId)
                setPage(page + 1)
            }
        }
    };

    function refreshApi() {
        setPage(1)
        getFeedsList(1, eventId)
    }

    async function getFeedsList(pageVal, evId) {
        setShowMoreDataLoader(true)
        try {
            const res = await apiHandler(FEEDS_LIST, "POST", {
                page: pageVal,
                limit: 10,
                event_id: evId,
                type: "5"
            })
            if (res.data?.response?.status === 200) {
                setMaxPage(res?.data?.data?.feeds?.meta?.last_page)
                if (pageVal === 1) {
                    setFeedsList(res?.data?.data?.feeds?.data)
                } else {
                    setFeedsList(feedsList.concat(res?.data?.data?.feeds?.data))
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setShowMoreDataLoader(false)
        }
    }

    return (
        <>
            <LoaderModal showLoader={showLoader} />
            {/* <ProfileHeader img={header} title="Events" /> */}
            <div id="content-page" className="content-page">
                <Container>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <Card.Body className=" profile-page p-0">
                                    <div className="profile-header">
                                        <div className="position-relative">
                                            <img loading="lazy" src={eventDetailsData?.banner_image ? eventDetailsData?.banner_image : header} alt="profile-bg" className="rounded img-fluid cover"
                                                style={{ height: "100%", width: "100%", objectFit: "fill", maxHeight: "250px" }} />
                                        </div>
                                        <div className="user-detail text-center mb-3">
                                            <div className="profile-img">
                                                <img style={{ height: 130, width: 130 }} loading="lazy"
                                                    src={eventDetailsData?.image ?
                                                        eventDetailsData?.image :
                                                        "https://uzone360live.s3.amazonaws.com/noimage.png"}
                                                    alt="profile-img1" className="avatar-130 img-fluid" />
                                            </div>
                                            <div className="profile-detail">
                                                <h3>{eventDetailsData?.name}</h3>
                                            </div>
                                        </div>
                                        <div className="profile-info p-3 d-flex align-items-center justify-content-between position-relative">

                                        </div>

                                        <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap">
                                            <div className="group-info d-flex align-items-center">
                                                {/* <div className="me-3">
                                                    <img className="rounded-circle img-fluid avatar-100" src={eventDetailsData?.user?.user_setting?.photo ? eventDetailsData?.user?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="" />
                                                </div>
                                                <div className="info">
                                                    <h4>{eventDetailsData?.name}</h4>
                                                    <p className="mb-0"><i className="ri-lock-fill pe-2"></i>Private Group . 323 members</p>
                                                </div> */}
                                            </div>
                                            <div className="group-member d-flex align-items-center  mt-md-0 mt-2 me-2">
                                                {/* <div className="iq-media-group me-3">
                                                    <Link to="#" className="iq-media">
                                                        <img className="img-fluid avatar-40 rounded-circle" src={user1} alt="" />
                                                    </Link>
                                                    <Link to="#" className="iq-media">
                                                        <img className="img-fluid avatar-40 rounded-circle" src={user2} alt="" />
                                                    </Link>
                                                    <Link to="#" className="iq-media">
                                                        <img className="img-fluid avatar-40 rounded-circle" src={user3} alt="" />
                                                    </Link>
                                                    <Link to="#" className="iq-media">
                                                        <img className="img-fluid avatar-40 rounded-circle" src={user4} alt="" />
                                                    </Link>
                                                    <Link to="#" className="iq-media">
                                                        <img className="img-fluid avatar-40 rounded-circle" src={user5} alt="" />
                                                    </Link>
                                                    <Link to="#" className="iq-media">
                                                        <img className="img-fluid avatar-40 rounded-circle" src={user6} alt="" />
                                                    </Link>
                                                    <Link to="#" className="iq-media">
                                                        <img className="img-fluid avatar-40 rounded-circle" src={user7} alt="" />
                                                    </Link>
                                                    <Link to="#" className="iq-media">
                                                        <img className="img-fluid avatar-40 rounded-circle" src={user8} alt="" />
                                                    </Link>
                                                </div> */}
                                                <button type="submit" className="btn btn-primary mb-2 me-1"
                                                    onClick={() => {
                                                        Swal.fire({
                                                            title: 'Warning!',
                                                            text: 'Are you sure, want to buy this ticket?',
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            showConfirmButton: true,
                                                            //showDenyButton: true,
                                                            cancelButtonText: 'Cancel',
                                                            confirmButtonText: 'Login with OTP',
                                                            confirmButtonColor: "#5c518b"
                                                            //denyButtonText: 'Sign Up'
                                                        }).then((result) => {
                                                            if (result.isConfirmed) {
                                                                //console.log("data",elm?.id)
                                                                localStorage.setItem('uzoneSelectedTicketId', eventId)
                                                                navigate('/auth/login-with-otp')
                                                            }
                                                            // else if (result.isDenied) {
                                                            //     navigate('/auth/sign-up')
                                                            // }
                                                        })
                                                    }
                                                    }
                                                >Buy a Ticket</button>
                                                <button type="submit" className="btn btn-primary mb-2 ms-1"
                                                    onClick={() => {
                                                        navigate('/auth/sign-up')
                                                    }}
                                                >Participate</button>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="4">
                            {/* <Card>
                                <Card.Header className="d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">Events</h4>
                                    </div>
                                    <div className="card-post-toolbar">
                                        <Dropdown>
                                            <Dropdown.Toggle as={CustomToggle}>
                                                <i className="ri-more-fill h4"></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="dropdown-menu m-0 p-0">
                                                <Dropdown.Item className="dropdown-item p-3" to="#">
                                                    <div className="d-flex align-items-top">
                                                        <div className="icon h4"><i className="ri-notification-line"></i></div>
                                                        <div className="data ms-2">
                                                            <h6>Notifications</h6>
                                                            <p className="mb-0">Turn on notifications<br /> for this post</p>
                                                        </div>
                                                    </div>
                                                </Dropdown.Item>
                                                <Dropdown.Item className="dropdown-item p-3" to="#">
                                                    <div className="d-flex align-items-top">
                                                        <div className="icon h4"><i className="ri-save-line"></i></div>
                                                        <div className="data ms-2">
                                                            <h6>Pins</h6>
                                                            <p className="mb-0">Pin your favourite groups<br /> for quick access.</p>
                                                        </div>
                                                    </div>
                                                </Dropdown.Item>
                                                <Dropdown.Item className="dropdown-item p-3" to="#">
                                                    <div className="d-flex align-items-top">
                                                        <div className="icon h4"><i className="ri-pencil-line"></i></div>
                                                        <div className="data ms-2">
                                                            <h6>Following</h6>
                                                            <p className="mb-0">Follow or unfollow groups <br />to control in News Feed.</p>
                                                        </div>
                                                    </div>
                                                </Dropdown.Item>
                                                <Dropdown.Item className="dropdown-item p-3" to="#">
                                                    <div className="d-flex align-items-top">
                                                        <div className="icon h4"><i className="ri-close-circle-line"></i></div>
                                                        <div className="data ms-2">
                                                            <h6>Membership</h6>
                                                            <p className="mb-0">Leave groups that no<br /> longer interest you.</p>
                                                        </div>
                                                    </div>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <ul className="list-inline p-0 m-0">
                                        <li className="mb-md-3 pb-md-3 border-bottom members-list">
                                            <div className="iq-search-bar members-search p-0">
                                                <form action="#" className="searchbox w-auto">
                                                    <input type="text" className="text search-input bg-soft-primary" placeholder="Type here to search..." />
                                                    <Link className="search-link" to="#"><i className="ri-search-line"></i></Link>
                                                </form>
                                            </div>
                                        </li>
                                        <li className="mb-3 d-flex align-items-center">
                                            <div className="me-2"><i className="ri-arrow-right-fill h4"></i></div>
                                            <h6 className="mb-0">Birthday Celebration</h6>
                                        </li>
                                        <li className="mb-3 d-flex align-items-center">
                                            <div className="me-2"><i className="ri-arrow-right-fill h4"></i></div>
                                            <h6 className="mb-0">New Year Celebration</h6>
                                        </li>
                                        <li className="mb-3 d-flex align-items-center">
                                            <div className="me-2"><i className="ri-arrow-right-fill h4"></i></div>
                                            <h6 className="mb-0">Atlanta Retail Show</h6>
                                        </li>
                                        <li className="mb-3 d-flex align-items-center">
                                            <div className="me-2"><i className="ri-arrow-right-fill h4"></i></div>
                                            <h6 className="mb-0">Holi Celebration</h6>
                                        </li>
                                        <li className="mb-3 d-flex align-items-center">
                                            <div className="me-2"><i className="ri-arrow-right-fill h4"></i></div>
                                            <h6 className="mb-0">Republic Day</h6>
                                        </li>
                                    </ul>
                                </Card.Body>
                            </Card> */}
                            <Card>
                                <Card.Header className="d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">About</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <ul className="list-inline p-0 m-0">
                                        <li className="mb-3">
                                            <p className="mb-0">{eventDetailsData?.description}</p>
                                        </li>
                                        <li className="mb-3 d-flex align-items-center">
                                            <div className="avatar-40 rounded-circle bg-gray text-center me-1"><i className="ri-map-pin-line h4"></i></div>
                                            <h6 className="mb-0">{eventDetailsData?.city + ", " + eventDetailsData?.state + ", " + eventDetailsData?.zip}</h6>
                                        </li>
                                        <li className="mb-3 d-flex align-items-center">
                                            <div className="avatar-40 rounded-circle bg-gray text-center me-1"><i className="ri-calendar-check-line h4"></i></div>
                                            <h6 className="mb-0">{Moment(eventDetailsData?.start_time).format('DD MMMM YYYY - hh:mm A')}</h6>
                                        </li>
                                        <li className="mb-3 d-flex align-items-center">
                                            <div className="avatar-40 rounded-circle bg-gray text-center me-1"><i className="ri-calendar-todo-line h4"></i></div>
                                            <h6 className="mb-0">{Moment(eventDetailsData?.end_time).format('DD MMMM YYYY - hh:mm A')}</h6>
                                        </li>
                                        <li className="mb-3 d-flex align-items-center">
                                            <div className="avatar-40 rounded-circle bg-gray text-center me-1"><i className="ri-vip-diamond-line h4"></i></div>
                                            <h6 className="mb-0">{"For Participation $" + eventDetailsData?.price}</h6>
                                        </li>
                                        <li className="mb-3 d-flex align-items-center">
                                            <div className="avatar-40 rounded-circle bg-gray text-center me-1"><i className="ri-coupon-line h4"></i></div>
                                            <h6 className="mb-0">{"Per Ticket $" + eventDetailsData?.ticket_price}</h6>
                                        </li>
                                        {/* <li className="mb-3 d-flex align-items-center">
                                            <div className="avatar-40 rounded-circle bg-gray text-center me-3"><i className="ri-coupon-line h4"></i></div>
                                            <h6 className="mb-0">{"Total " + eventDetailsData?.total_tickets + " tickets"}</h6>
                                        </li> */}
                                        {/* <li className="mb-3 d-flex align-items-center">
                                            <div className="avatar-40 rounded-circle bg-gray text-center me-3"><i className="ri-slideshow-4-line h4"></i></div>
                                            <h6 className="mb-0">{eventDetailsData?.total_tickets}</h6>
                                        </li> */}
                                        {/* <li className="mb-3 d-flex align-items-center">
                                            <div className="avatar-40 rounded-circle bg-gray text-center me-3"><i className="ri-truck-line h4"></i></div>
                                            <h6 className="mb-0">Coordinating Outside vendors</h6>
                                        </li>
                                        <li className="mb-3 d-flex align-items-center">
                                            <div className="avatar-40 rounded-circle bg-gray text-center me-3"><i className="ri-restaurant-2-line h4"></i></div>
                                            <h6 className="mb-0">Managing Staff</h6>
                                        </li>
                                        <li className="mb-3 d-flex align-items-center">
                                            <div className="avatar-40 rounded-circle bg-gray text-center me-3"><i className="ri-home-2-line h4"></i></div>
                                            <h6 className="mb-0">Selecting an overall event theme</h6>
                                        </li>
                                        <li className="mb-3 d-flex align-items-center">
                                            <div className="avatar-40 rounded-circle bg-gray text-center me-3"><i className="ri-hotel-line h4"></i></div>
                                            <h6 className="mb-0">Negotating hotel contracts</h6>
                                        </li>
                                        <li className="mb-3 d-flex align-items-center">
                                            <div className="avatar-40 rounded-circle bg-gray text-center me-3"><i className="ri-briefcase-line h4"></i></div>
                                            <h6 className="mb-0">hiring a caterer</h6>
                                        </li>
                                        <li className="mb-3 d-flex align-items-center">
                                            <div className="avatar-40 rounded-circle bg-gray text-center me-3"><i className="ri-git-repository-line h4"></i></div>
                                            <h6 className="mb-0">Developing invitations</h6>
                                        </li> */}
                                    </ul>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="8">
                            {
                                localStorage.getItem("uzoneAuthToken") ?
                                <CreatePostInputBox
                                        postType={"5"}
                                        refreshApi={refreshApi}
                                        eventId={eventDetailsData?.id} /> : null
                                    // <CreatePostModal
                                    //     postType={"5"}
                                    //     refreshApi={refreshApi}
                                    //     eventId={eventDetailsData?.id} /> : null
                            }

                            {
                                feedsList?.length > 0 ?
                                    feedsList?.map((elm, i) => (
                                        <FeedPostCard elm={elm} getFeedsList={refreshApi} key={elm?.id} feedDetails={false} />
                                    )) : <NoDataFound />
                            }
                            {
                                showMoreDataLoader ?
                                    <div className="col-sm-12 text-center">
                                        <img src={loader} alt="loader" style={{ height: "100px" }} />
                                    </div> : null
                            }
                            <InView as="div" onChange={(inView, entry) => loadMore(inView)} />
                            {/* <div className="post-item">
                                        <div className="user-post-data pb-3">
                                            <div className="d-flex justify-content-between">
                                                <div className=" me-3">
                                                    <img className="rounded-circle img-fluid" src={user10} alt="" />
                                                </div>
                                                <div className="w-100">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <h5 className="mb-0 d-inline-block"><Link to="#">Paige Turner</Link></h5>
                                                            <p className="mb-0">8 hour ago</p>
                                                        </div>
                                                        <div className="card-post-toolbar">
                                                            <Dropdown>
                                                                <Dropdown.Toggle as={CustomToggle}>
                                                                    <i className="ri-more-fill h4"></i>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className="dropdown-menu m-0 p-0">
                                                                    <Dropdown.Item className="dropdown-item p-3" to="#">
                                                                        <div className="d-flex align-items-top">
                                                                            <i className="ri-save-line h4"></i>
                                                                            <div className="data ms-2">
                                                                                <h6>Save Post</h6>
                                                                                <p className="mb-0">Add this to your saved items</p>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className="dropdown-item p-3" to="#">
                                                                        <div className="d-flex align-items-top">
                                                                            <i className="ri-pencil-line h4"></i>
                                                                            <div className="data ms-2">
                                                                                <h6>Edit Post</h6>
                                                                                <p className="mb-0">Update your post and saved items</p>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className="dropdown-item p-3" to="#">
                                                                        <div className="d-flex align-items-top">
                                                                            <i className="ri-close-circle-line h4"></i>
                                                                            <div className="data ms-2">
                                                                                <h6>Hide From Timeline</h6>
                                                                                <p className="mb-0">See fewer posts like this.</p>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className="dropdown-item p-3" to="#">
                                                                        <div className="d-flex align-items-top">
                                                                            <i className="ri-delete-bin-7-line h4"></i>
                                                                            <div className="data ms-2">
                                                                                <h6>Delete</h6>
                                                                                <p className="mb-0">Remove thids Post on Timeline</p>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className="dropdown-item p-3" to="#">
                                                                        <div className="d-flex align-items-top">
                                                                            <i className="ri-notification-line h4"></i>
                                                                            <div className="data ms-2">
                                                                                <h6>Notifications</h6>
                                                                                <p className="mb-0">Turn on notifications for this post</p>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="user-post">
                                            <Link to="#"><img src={img2} alt="postimage" className="img-fluid w-100" /></Link>
                                        </div>
                                        <div className="comment-area mt-3">
                                            <div className="d-flex justify-content-between align-items-center flex-wrap">
                                                <div className="like-block position-relative d-flex align-items-center">
                                                    <div className="d-flex align-items-center">
                                                        <div className="like-data">
                                                            <Dropdown>
                                                                <Dropdown.Toggle as={CustomToggle}>
                                                                    <img src={icon1} className="img-fluid" alt="" />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className="dropdown-menu py-2">
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Like</Tooltip>} className="ms-2 me-2" ><img src={icon1} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Love</Tooltip>} className="me-2" ><img src={icon2} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Happy</Tooltip>} className="me-2" ><img src={icon3} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>HaHa</Tooltip>} className="me-2" ><img src={icon4} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Think</Tooltip>} className="me-2" ><img src={icon5} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Sade</Tooltip>} className="me-2" ><img src={icon6} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Lovely</Tooltip>} className="me-2" ><img src={icon7} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                        <div className="total-like-block ms-2 me-3">
                                                            <Dropdown>
                                                                <Dropdown.Toggle as={CustomToggle}>
                                                                    140 Likes
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item className="dropdown-item" href="#">Max Emum</Dropdown.Item>
                                                                    <Dropdown.Item className="dropdown-item" href="#">Bill Yerds</Dropdown.Item>
                                                                    <Dropdown.Item className="dropdown-item" href="#">Hap E. Birthday</Dropdown.Item>
                                                                    <Dropdown.Item className="dropdown-item" href="#">Tara Misu</Dropdown.Item>
                                                                    <Dropdown.Item className="dropdown-item" href="#">Midge Itz</Dropdown.Item>
                                                                    <Dropdown.Item className="dropdown-item" href="#">Sal Vidge</Dropdown.Item>
                                                                    <Dropdown.Item className="dropdown-item" href="#">Other</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                    <div className="total-comment-block">
                                                        <Dropdown>
                                                            <Dropdown.Toggle as={CustomToggle}>
                                                                20 Comment
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item className="dropdown-item" to="#">Max Emum</Dropdown.Item>
                                                                <Dropdown.Item className="dropdown-item" to="#">Bill Yerds</Dropdown.Item>
                                                                <Dropdown.Item className="dropdown-item" to="#">Hap E. Birthday</Dropdown.Item>
                                                                <Dropdown.Item className="dropdown-item" to="#">Tara Misu</Dropdown.Item>
                                                                <Dropdown.Item className="dropdown-item" to="#">Midge Itz</Dropdown.Item>
                                                                <Dropdown.Item className="dropdown-item" to="#">Sal Vidge</Dropdown.Item>
                                                                <Dropdown.Item className="dropdown-item" to="#">Other</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                                <ShareOffcanvas />
                                            </div>
                                            <hr />
                                            <ul className="post-comments p-0 m-0">
                                                <li className="mb-2">
                                                    <div className="d-flex">
                                                        <div className="user-img">
                                                            <img src={user11} alt="userimg" className="avatar-35 rounded-circle img-fluid" />
                                                        </div>
                                                        <div className="comment-data-block ms-3">
                                                            <h6>Monty Carlo</h6>
                                                            <p className="mb-0">Lorem ipsum dolor sit amet</p>
                                                            <div className="d-flex flex-wrap align-items-center comment-activity">
                                                                <Link to="#">like</Link>
                                                                <Link to="#">reply</Link>
                                                                <Link to="#">translate</Link>
                                                                <span> 5 min </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="d-flex">
                                                        <div className="user-img">
                                                            <img src={user12} alt="userimg" className="avatar-35 rounded-circle img-fluid" />
                                                        </div>
                                                        <div className="comment-data-block ms-3">
                                                            <h6>Paul Molive</h6>
                                                            <p className="mb-0">Lorem ipsum dolor sit amet</p>
                                                            <div className="d-flex flex-wrap align-items-center comment-activity">
                                                                <Link to="#">like</Link>
                                                                <Link to="#">reply</Link>
                                                                <Link to="#">translate</Link>
                                                                <span> 5 min </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                            <form className="comment-text d-flex align-items-center mt-3">
                                                <input type="text" className="form-control rounded" placeholder="Enter Your Comment" />
                                                <div className="comment-attagement d-flex">
                                                    <Link to="#"><i className="ri-link me-3"></i></Link>
                                                    <Link to="#"><i className="ri-user-smile-line me-3"></i></Link>
                                                    <Link to="#"><i className="ri-camera-line me-3"></i></Link>
                                                </div>
                                            </form>
                                        </div>
                                    </div> */}
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default PublicEventDetails