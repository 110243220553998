import React, { useState, useEffect } from 'react'
import { Container, Modal, Row, Col, Button, Form, Dropdown } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link } from 'react-router-dom'
import ProfileHeader from '../../../components/profile-header'
import { InView } from "react-intersection-observer";

import { apiHandler } from '../../../api/apihandler';
import { CHAT_USERS, GET_CONNECTED_USERS, GROUP_ADD_MEMBER, GROUP_DELETE, GROUP_LIST, GROUP_REMOVE_MEMBER } from '../../../api/constants';

import LoaderModal from '../../../components/loaderModal'

// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux';

// images
import img1 from '../../../assets/images/page-img/profile-bg1.jpg'
import img7 from '../../../assets/images/page-img/profile-bg7.jpg'
import GroupMember from '../../../components/chat/groupMember'
import CreateNewGroup from '../../../components/chat/createNewGroup'
import Swal from 'sweetalert2'
import CustomToggle from '../../../components/dropdowns'
import AddNewMemberModal from '../../../components/chat/addNewMemberModal'
import loader from '../../../assets/images/page-img/page-load-loader.gif'
import EditGroup from '../../../components/chat/editGroup'
import NoDataFound from '../../../components/noDataFound'

const Groups = () => {

    let profileData = (useSelector((state) => state.setting.setting.user_data))
    profileData = profileData ? JSON.parse(profileData) : "";

    const [groupList, setGroupList] = useState([]);
    const [userListForAddNewMember, setUserListForAddNewMember] = useState([]);
    const [showAddMemberModal, setShowAddMemberModal] = useState(false);
    const [groupDetails, setGroupDetails] = useState('');
    const [page, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [showLoader, setShowLoader] = useState(false);
    const [showMoreDataLoader, setShowMoreDataLoader] = useState(false);
    const [showEditGroup, setShowEditGroup] = useState(false);

    const handleClose = () => {
        setUserListForAddNewMember([])
        setShowAddMemberModal(false)
    };

    const [isFirst, setIsFirst] = useState(true);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        setIsFirst(false)
    }, [isFirst]);

    useEffect(() => {
        getGroupList(1)
    }, []);

    const loadMore = (inView) => {
        if (inView) {
            if (page < maxPage) {
                getGroupList(page + 1)
                //console.log("scroll working...", page + 1)
                setPage(page + 1)
            }
        }
    };

    function refreshApi() {
        setPage(1)
        getGroupList(1)
    }

    async function getGroupList(pageVal) {
        setShowMoreDataLoader(true)
        //setShowLoader(true)
        try {
            const res = await apiHandler(GROUP_LIST, 'POST', {
                page: pageVal,
                limit: 10
            });
            if (res.data?.response?.status === 200) {
                setMaxPage(res?.data?.data?.groups?.meta?.last_page)
                if (pageVal === 1) {
                    setGroupList(res?.data?.data?.groups?.data)
                } else {
                    setGroupList(groupList.concat(res?.data?.data?.groups?.data))
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setShowMoreDataLoader(false)
            //setShowLoader(false)
        }
    }

    async function deleteGroupApi(groupId) {
        try {
            const res = await apiHandler(GROUP_DELETE, 'DELETE', {
                group_id: groupId
            });
            if (res.data?.response?.status === 200) {
                refreshApi()
            } else {
                Swal.fire({
                    title: 'Error',
                    text: res?.response?.data?.data?.message,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    }

    async function userListForAddNewMemberApi(groupId) {
        try {
            const res = await apiHandler(GET_CONNECTED_USERS, 'POST', {
                group_id: groupId,
                page: 1,
                limit: 10
            });
            if (res.data?.response?.status === 200) {
                setMaxPage(res?.data?.data?.users?.meta?.last_page)
                setUserListForAddNewMember(userListForAddNewMember.concat(res?.data?.data?.users?.data))
            } else {
                //setUserListForAddNewMember([])
                Swal.fire({
                    title: 'Error',
                    text: res?.response?.data?.data?.message,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    }

    return (
        <>
            <LoaderModal showLoader={showLoader} />
            <ProfileHeader img={img7} title="Groups" />
            <div id="content-page" className="content-page">
                <Container>
                    <Row>
                        <Col lg="12" style={{ marginBottom: "15px" }}>

                            <CreateNewGroup getGroupListApi={refreshApi} />

                            <div className="d-grid gap-3 d-grid-template-1fr-19">
                                {
                                    groupList?.length > 0 ? groupList?.map((elm, index) => (
                                        <Card className="mb-0" key={elm?.id}>
                                            <div className="d-flex top-bg-image" >
                                                <img src={img1} className="img-fluid w-100" alt="group-bg" />
                                            </div>
                                            <Card.Body className=" text-center">
                                                <div className="group-icon">
                                                    <Link to={`/chat/index?type=group&slug=${elm?.user_group?.slug}`} target='_blank noopener,noreferrer'>
                                                        <img src={elm?.user_group?.image ? elm?.user_group?.image : "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="profile-img" className="rounded-circle img-fluid avatar-120" />
                                                    </Link>
                                                </div>
                                                <div className="group-info pt-3 pb-1">
                                                    {/* <h4><Link to="/dashboards/app/group-detail">{elm?.user_group?.name}</Link></h4>
                                                    <p>Lorem Ipsum data</p> */}
                                                    <h4><Link to={`/chat/index?type=group&slug=${elm?.user_group?.slug}`} target='_blank noopener,noreferrer'>{elm?.user_group?.name}</Link></h4>
                                                    {/* <h4><Link to={`/chat/index#group#${elm?.user_group?.slug}`} target='_blank noopener,noreferrer'>{elm?.user_group?.name}</Link></h4> */}
                                                </div>
                                                {/* <div className="group-details d-inline-block pb-3">
                                                    <ul className="d-flex align-items-center justify-content-between list-inline m-0 p-0">
                                                        <li className="pe-3 ps-3">
                                                            <p className="mb-0">Member</p>
                                                            <h6>320</h6>
                                                        </li>
                                                    </ul>
                                                </div> */}
                                                <div className="group-member mb-3">
                                                    <GroupMember groupId={elm?.group_id} />
                                                </div>
                                                {
                                                    elm?.is_owner === 1 ?
                                                        <div className="d-flex">
                                                            <button type="submit" className="btn btn-primary d-block w-100" style={{ marginRight: "5px" }}
                                                                onClick={() => {
                                                                    setGroupDetails(elm)
                                                                    userListForAddNewMemberApi(elm?.group_id)
                                                                    setShowAddMemberModal(true)
                                                                }}
                                                            >Add Members</button>
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="bg-transparent" className="btn btn-primary d-block w-20">
                                                                    {/* <span className="material-symbols-outlined">
                                                                        more_horiz
                                                                    </span> */}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className=" m-0 p-0">
                                                                    <Dropdown.Item className="pl-3 pr-3 pt-3" to="#">
                                                                        <div className="d-flex align-items-top"
                                                                            onClick={() => {
                                                                                setGroupDetails(elm)
                                                                                setShowEditGroup(true)
                                                                            }}>
                                                                            <i className="ri-edit-2-fill h4"></i>
                                                                            <div className="data ms-2">
                                                                                <h6>Edit</h6>
                                                                                <p className="mb-0">Change name and image </p>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className="pl-3 pr-3 pt-3 pb-3" to="#">
                                                                        <div className="d-flex align-items-top"
                                                                            onClick={() => {
                                                                                Swal.fire({
                                                                                    title: 'Warning!',
                                                                                    text: 'Are you sure, want to delete this ' + elm?.user_group?.name + '?',
                                                                                    icon: 'warning',
                                                                                    showCancelButton: true,
                                                                                    showConfirmButton: true,
                                                                                    cancelButtonText: 'Cancel',
                                                                                    confirmButtonText: 'Confirm',
                                                                                }).then((result) => {
                                                                                    if (result.isConfirmed) {
                                                                                        deleteGroupApi(elm?.group_id)
                                                                                    }
                                                                                })
                                                                            }}>
                                                                            <i className="ri-delete-bin-7-line h4"></i>
                                                                            <div className="data ms-2">
                                                                                <h6>Delete</h6>
                                                                                <p className="mb-0">Remove this group from your profile</p>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                            {/* <button type="submit" className="btn btn-primary d-block w-20"
                                                                onClick={() => {
                                                                    Swal.fire({
                                                                        title: 'Warning!',
                                                                        text: 'Are you sure, want to delete this ' + elm?.user_group?.name + '?',
                                                                        icon: 'warning',
                                                                        showCancelButton: true,
                                                                        showConfirmButton: true,
                                                                        cancelButtonText: 'Cancel',
                                                                        confirmButtonText: 'Confirm',
                                                                    }).then((result) => {
                                                                        if (result.isConfirmed) {
                                                                            deleteGroupApi(elm?.group_id)
                                                                        }
                                                                    })
                                                                }}
                                                            ><i className="ri-delete-bin-4-line me-0"></i></button> */}
                                                            {/* ><i className="ri-more-2-fill me-0"></i></button> */}
                                                        </div> : null
                                                }
                                            </Card.Body>
                                        </Card>
                                    )) : <NoDataFound />
                                }

                            </div>
                            {
                                showMoreDataLoader ?
                                    <div className="col-sm-12 text-center">
                                        <img src={loader} alt="loader" style={{ height: "100px" }} />
                                    </div> : null
                            }
                            <InView as="div" onChange={(inView, entry) => loadMore(inView)} />
                        </Col>
                        {/* <Col lg="4">
                            <Card>
                                <Card.Header className="card-header d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">Groups</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <ul className="list-inline p-0 m-0">
                                        <li className="mb-3 pb-3 border-bottom">
                                            <div className="iq-search-bar members-search p-0">
                                                <form action="#" className="searchbox w-auto">
                                                    <input type="text" className="text search-input bg-grey" placeholder="Type here to search..." />
                                                    <Link className="search-link" to="#"><i className="ri-search-line"></i></Link>
                                                </form>
                                            </div>
                                        </li>
                                        <li className="mb-3 d-flex align-items-center">
                                            <div className="avatar-40 rounded-circle bg-gray d-flex align-items-center justify-content-center me-3">
                                                <i className="material-symbols-outlined">credit_card</i>
                                            </div>
                                            <h6 className="mb-0">Your Feed</h6>
                                        </li>
                                        <li className="mb-3 d-flex align-items-center">
                                            <div className="avatar-40 rounded-circle bg-gray d-flex align-items-center justify-content-center me-3">
                                                <i className="material-symbols-outlined">explore</i>
                                            </div>
                                            <h6 className="mb-0">Discover</h6>
                                        </li>
                                        <li>
                                            <button type="submit" className="btn btn-primary d-block w-100">
                                                <i className="ri-add-line pe-2"></i>Create New Group
                                            </button>
                                        </li>
                                    </ul>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header className="card-header d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">About</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <ul className="list-inline p-0 m-0">
                                        <li className="mb-3">
                                            <p className="mb-0">Developer's Group...</p>
                                        </li>
                                        <li className="mb-3">
                                            <div className="d-flex">
                                                <div className="flex-shrink-0">
                                                    <i className="material-symbols-outlined">lock</i>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <h6>Private</h6>
                                                    <p className="mb-0">Success in slowing economic activity.</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="mb-3">
                                            <div className="d-flex">
                                                <div className="flex-shrink-0">
                                                    <i className="material-symbols-outlined">visibility</i>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <h6>Visible</h6>
                                                    <p className="mb-0">Various versions have evolved over the years</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li >
                                            <div className="d-flex">
                                                <div className="flex-shrink-0">
                                                    <i className="material-symbols-outlined">group</i>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <h6>General group</h6>
                                                    <p className="mb-0">There are many variations of passages</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </Card.Body>
                            </Card>
                        </Col> */}
                    </Row>
                </Container>
                {
                    showAddMemberModal ?
                        <AddNewMemberModal
                            showAddMemberModal={showAddMemberModal}
                            setShowAddMemberModal={setShowAddMemberModal}
                            groupDetails={groupDetails} /> : null
                }

                {
                    showEditGroup ?
                        <EditGroup
                            showEditGroup={showEditGroup}
                            setShowEditGroup={setShowEditGroup}
                            groupDetails={groupDetails}
                            getGroupListApi={refreshApi} /> : null
                }

                {/* <Modal scrollable={true} show={showModal} onHide={handleClose} size="lg" style={{ marginTop: 80 }}>
                    <Modal.Header className="d-flex justify-content-between">
                        <div className="d-flex justify-content-between">
                            <div className="w-100">
                                <div className="d-flex justify-content-between flex-wrap">
                                    <div className="me-3">
                                        <img loading="lazy" className="rounded-circle  avatar-60"
                                            src={groupDetails?.user_group?.image ? groupDetails?.user_group?.image : "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="" />
                                    </div>
                                    <div>
                                        <h4 className="mb-0 d-inline-block"><Link to="#">{groupDetails?.user_group?.name}</Link></h4>
                                        <p className="mb-0">+Add more members</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="button" className="btn btn-secondary lh-1" onClick={handleClose} ><span className="material-symbols-outlined">close</span></button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="post-item" style={{ maxHeight: "500px" }}>
                            <ul className="request-list m-0 p-0">
                                {userListForAddNewMember.map((elm, i) => (
                                    <li key={elm?.id} className="d-flex align-items-center  flex-wrap">
                                        <div className="user-img img-fluid flex-shrink-0">
                                            <img src={elm?.user_setting?.photo ? elm?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"}
                                                alt="story-img" className="rounded-circle avatar-40" />
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <Link to={`/dashboard/app/friend-profile/${elm?.slug}`}>
                                                <h5>{elm?.first_name && (elm?.first_name + " " + elm?.last_name)}</h5>
                                            </Link>
                                        </div>
                                        <div className="d-flex align-items-center mt-2 mt-md-0">
                                            {
                                                elm?.is_added == 1 ?
                                                    <Link to="#" className="me-3 btn btn-secondary rounded"
                                                        onClick={() => {
                                                            removeGroupMemberApi(groupDetails?.group_id, elm?.id, i, 0)
                                                        }}>Added</Link> :
                                                    <Link to="#" className="me-3 btn btn-primary rounded"
                                                        onClick={() => {
                                                            addGroupMemberApi(groupDetails?.group_id, elm?.id, i, 1)
                                                        }}><i className="ri-user-add-line me-1"></i>Add</Link>
                                            }
                                        </div>
                                    </li>
                                ))}
                                {
                                    page < maxPage ?
                                        <li className="d-block text-center mb-0 pb-3">
                                            <Link to="#" className="me-3 btn btn-primary"
                                                onClick={() => {
                                                    setPage(page + 1)
                                                    userListForAddNewMemberApi(groupDetails?.group_id)
                                                }}>View More Request</Link>
                                        </li> : null
                                }
                            </ul>
                        </div>
                    </Modal.Body>
                </Modal> */}
            </div>
        </>
    )

}

export default Groups;