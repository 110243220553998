import React, { useState, useEffect, useRef, createRef } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { Row, Col, Form, Tab, Nav, Button, Dropdown, Modal, Image } from 'react-bootstrap'
import Swal from 'sweetalert2'
import Card from '../../../components/Card'
import CustomToggle from '../../../components/dropdowns'
import Moment, { now } from 'moment';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import EmojiPicker from 'emoji-picker-react';
import { VideoThumbnailGenerator } from 'browser-video-thumbnail-generator';
import { io } from "socket.io-client";
import { handleImageUpload } from "../../../api/uploads";
import { apiHandler } from '../../../api/apihandler';
import { CHAT_USERS, CHAT_SINGLE_USER, CHAT_SAVE, CHAT_DELETE, CHAT_MEDIA_SAVE, CHAT_READ_ALL, GET_CONNECTED_USERS, GROUP_DETAILS, GROUP_CHAT_SAVE, GROUP_DELETE, GROUP_REMOVE_MEMBER, GROUP_MEMBERS, CHAT_DETAILS } from '../../../api/constants';

import LoaderModal from '../../../components/loaderModal'

// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux';
// import state selectors
import { setPostModalShow, setCreatePostType } from '../../../store/setting/actions'

//img
import playButton from '../../../assets/images/icon/play_button.png'
import cross from '../../../assets/images/icon/cross.png'
import imageIcon from '../../../assets/images/small/07.png'
import smileIcon from '../../../assets/images/small/09.png'
import videoIcon from '../../../assets/images/small/video.png'
import user1 from '../../../assets/images/user/1.jpg'
import user5 from '../../../assets/images/user/05.jpg'
import user6 from '../../../assets/images/user/06.jpg'
import user7 from '../../../assets/images/user/07.jpg'
import user8 from '../../../assets/images/user/08.jpg'
import user9 from '../../../assets/images/user/09.jpg'
import user10 from '../../../assets/images/user/10.jpg'
import ChatMediaView from '../../../components/chat/chatMediaView'
import GroupMemberModal from '../../../components/chat/groupMemberModal'
import AddNewMemberModal from '../../../components/chat/addNewMemberModal'
// import page100 from '../../../assets/images/page-img/100.jpg'

const Chat = () => {
    const socket = io("https://api.knowfirerisk.com/");
    const dispatch = useDispatch()
    let profileData = (useSelector((state) => state.setting.setting.user_data))
    profileData = profileData ? JSON.parse(profileData) : "";

    const [show, setShow] = useState('')
    const [show1, setShow1] = useState('')
    const [show2, setShow2] = useState('')
    const ChatSidebar = () => {
        document.getElementsByClassName('scroller')[0].classList.add('show')
    }
    const ChatSidebarClose = () => {
        document.getElementsByClassName('scroller')[0].classList.remove('show')
    }

    const [selectedMemberDetails, setSelectedMemberDetails] = useState('');
    const [selectedMemberSlug, setSelectedMemberSlug] = useState('');
    const [message, setMessage] = useState('');
    const [chatUsersList, setChatUsersList] = useState([]);
    let isGetChatUsersList = true
    const [messagesList, setMessagesList] = useState([]);
    const chatListRef = useRef(null);
    const [showLoader, setShowLoader] = useState(false);
    const [imageFileArray, setImageFileArray] = useState([]);
    const [imageArray, setImageArray] = useState([]);
    const [videoFileArray, setVideoFileArray] = useState([]);
    const [videoThumbFileArray, setVideoThumbFileArray] = useState([]);
    const [videoThumbArray, setVideoThumbArray] = useState([]);
    const [showPicker, setShowPicker] = useState(false);
    const [searchProfileData, setSearchProfileData] = useState([]);
    const [searchContent, setSearchContent] = useState('');
    const [searchResultShow, setSearchResultShow] = useState(false);
    const chatUserRef = useRef(chatUsersList.map(() => createRef()));
    const lazyLoadRef = useRef(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [chatListCurrentPage, setChatListCurrentPage] = useState(1);
    const [chatListLastPage, setChatListLastPage] = useState(1);
    const [totalMembers, setTotalMembers] = useState(0);
    const [showGroupModalShow, setShowGroupModalShow] = useState(false);
    const [showAddMemberModal, setShowAddMemberModal] = useState(false);

    const handleClose = () => setSearchResultShow(false);
    const handleShow = () => setSearchResultShow(true);

    const deleteVideo = (index) => {
        videoThumbArray.splice(index, 1)
        videoThumbFileArray.splice(index, 1)
        videoFileArray.splice(index, 1)
    }

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                //console.log("uploaded", file)
                setImageFileArray([...imageFileArray, file])
                setImageArray([...imageArray, reader.result])
            };
            reader.readAsDataURL(file);
        }
    };

    const handleVideoChange = (event) => {
        const file = event.target.files[0];
        //console.log("selectedFile", file)
        if (file) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                await handleGenerateThumbnail(reader.result)
                setVideoFileArray([...videoFileArray, file])
                //setVideoArray([...videoArray, reader.result])
            };
            reader.readAsDataURL(file);
        }
    };

    async function handleGenerateThumbnail(videoUrl) {
        const generator = new VideoThumbnailGenerator(videoUrl);
        generator.getThumbnail()
            .then((thumbnail) => {
                //console.log("thumbUrl", thumbnail)
                setVideoThumbArray([...videoThumbArray, thumbnail?.thumbnail])
                fetch(thumbnail?.thumbnail)
                    .then((res) => res.blob())
                    .then((blob) => {
                        const file = new File([blob], `${+new Date()}.jpg`, { type: 'image/jpeg' });
                        //console.log('Converted image to File object:', file);
                        setVideoThumbFileArray([...videoThumbFileArray, file])
                    })
                    .catch((error) => {
                        console.error('Error fetching image data:', error);
                    });
                //generator.revokeUrls();
            });
    };

    async function chatEnterKeyMessage(event) {
        if (event.keyCode === 13) {
            sendMessage()
        }
    }

    async function sendMessage() {
        if (message != "" || imageArray.length > 0 || videoThumbArray.length > 0) {
            setShowLoader(true)
            try {
                if (selectedMemberDetails?.group_id) {
                    const res = await apiHandler(GROUP_CHAT_SAVE, 'POST', {
                        group_id: selectedMemberDetails?.group_id,
                        message: message
                    });
                    if (res.data?.response?.status === 200) {
                        //res.data.data.chat["isSender"] = true;
                        if (imageArray.length > 0 || videoThumbArray.length > 0) {
                            await handleMediaPostSubmit(res.data.data.chat?.id)
                            await getChatDetails(res.data.data.chat?.id)
                        } else {
                            setMessage('')
                            setMessagesList([...messagesList, res.data.data.chat]);
                            setShowLoader(false)
                            setShowPicker(false)
                            chatListRef.current.scrollTop = chatListRef.current.scrollHeight;
                        }
                    } else {
                        Swal.fire({
                            title: 'Error',
                            text: res?.response?.data?.data?.message,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    }
                } else {
                    const res = await apiHandler(CHAT_SAVE, 'POST', {
                        sender_id: profileData?.id,
                        receiver_id: selectedMemberDetails?.member?.id,
                        message: message
                    });
                    if (res.data?.response?.status === 200) {
                        //res.data.data.chat["isSender"] = true;
                        if (imageArray.length > 0 || videoThumbArray.length > 0) {
                            await handleMediaPostSubmit(res.data.data.chat?.id)
                            await getChatDetails(res.data.data.chat?.id)
                        } else {
                            setMessage('')
                            setMessagesList([...messagesList, res.data.data.chat]);
                            setShowLoader(false)
                            chatListRef.current.scrollTop = chatListRef.current.scrollHeight;
                        }
                    } else {
                        Swal.fire({
                            title: 'Error',
                            text: res?.response?.data?.data?.message,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    }
                }
            } catch (error) {
                console.error(error);
            } finally {
            }
        }
    }

    async function handleMediaPostSubmit(chatId) {
        if (imageFileArray.length > 0) {
            for (let elm of imageFileArray) {
                const url = await handleImageUpload(elm, `feedImages/${Date.now()}`, "image/jpeg")
                //console.log("uploaded", url)
                await chatMediaPostApi(url, "", "0", chatId)
            }
        }
        if (videoFileArray.length > 0) {
            //for (let elm of videoFileArray) {
            for (let i = 0; i < videoFileArray?.length; i++) {
                const url = await handleImageUpload(videoFileArray[i], `feedVideos/${Date.now()}`, "video/*")
                const thumb = await handleImageUpload(videoThumbFileArray[i], `feedImages/${Date.now()}`, "image/jpeg")
                //console.log("uploaded", url)
                await chatMediaPostApi(url, thumb, "1", chatId)
            }
        }
        setImageArray([]);
        setImageFileArray([])
        setVideoFileArray([])
        setVideoThumbArray([])
        setVideoThumbFileArray([])
        setMessage('')
        setShowPicker(false)
        //getChatSingleUser(selectedMemberDetails?.member_id, 1)
        setShowLoader(false)
    };

    async function getChatDetails(chatId) {
        try {
            const res = await apiHandler(CHAT_DETAILS, "POST", {
                chat_id: chatId
            })
            if (res.data?.response?.status === 200) {
                // console.log("getChatDetails", res.data)
                setMessagesList([...messagesList, res.data.data.chats]);
            } else {
            }
        } catch (error) {
            console.error(error);
        }
        finally {
        }
    }

    async function chatMediaPostApi(awsPath, awsThumb, mediaType, chatId) {
        try {
            const res = await apiHandler(CHAT_MEDIA_SAVE, "POST", {
                chat_id: chatId,
                path: awsPath,
                media_type: mediaType,
                thumb: awsThumb
            })
            if (res.data?.response?.status === 200) {

            } else {
                Swal.fire({
                    title: 'Error',
                    text: res?.response?.data?.data?.message,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }
        } catch (error) {
            console.error(error);
        }
        finally {
        }
    }


    useEffect(() => {
        socketSetup()
        // getChatUsers()
        if (chatListCurrentPage === 1) {
            chatListRef.current.scrollTop = chatListRef.current.scrollHeight;
        }
    }, [messagesList]); // messagesList
    useEffect(() => {
        if (isGetChatUsersList) {
            isGetChatUsersList = false
            getChatUsers()
        }
    }, []);
    useEffect(() => {
        selectinDummyUser();
    }, [chatUsersList]);

    async function selectinDummyUser() {
        const type = searchParams.get("type")
        let tabValHash = window.location.hash
        let slug = tabValHash.substring(1);
        if (type) {
            if (!slug) {
                slug = searchParams.get("slug")
            }
        }
        //const slug = searchParams.get("slug")
        // let tabValHash = window.location.hash.split("#")
        // let slug = tabValHash[2]? tabValHash[2] : ""
        if (slug != "") {
            let matchedIndex = chatUsersList.findIndex(elm => type === 'group' ? elm.user_group?.slug : elm.member?.slug === slug);
            if (matchedIndex != -1) {
                // console.log(chatUsersList, slug);
                let userType = type === 'group' ? "group" : "user"
                let chatId = chatUsersList[matchedIndex]?.group_id ? chatUsersList[matchedIndex]?.group_id : chatUsersList[matchedIndex]?.member_id

                setSelectedMemberSlug(chatUsersList[matchedIndex]?.group_id ? chatUsersList[matchedIndex]?.user_group?.slug : chatUsersList[matchedIndex]?.member?.slug);
                selectionUser('both', chatUsersList[matchedIndex]?.group_id ? chatUsersList[matchedIndex]?.user_group?.slug : chatUsersList[matchedIndex]?.member?.slug);
                await getChatSingleUser(userType, chatId, 1)
                setSelectedMemberDetails(chatUsersList[matchedIndex])
                setShow('show')
            }
        }
    }

    async function selectionUser(type, slug) {
        let queryClassElementts = document.querySelectorAll(".nav-link");
        queryClassElementts.forEach(element => {
            element.classList.remove('active');
        });
        document.getElementById("left-tabs-example-tab-" + slug).classList.add("active");
        if (type === 'both') {
            document.getElementById("left-tabs-example-tabpane-start").classList.remove("active");
            setTimeout(function () {
                //your code to be executed after 1 second
                document.getElementById("left-tabs-example-tabpane-" + slug).classList.add("active");
            }, 1000);
        }
    }

    function socketSetup() {
        const userId = profileData?.id;
        // Listen for events from the server
        socket.on('connect', () => {
            console.log('Connected to the server');
            socket.emit("join", userId);
        });
        // Listen for events from the server
        socket.on('disconnect', () => {
            console.log('DisConnected from the server');
        });
        socket.on('error', () => {
            console.log('DisConnected from the server');
        });

        socket.on('privateMessage', (message) => {
            console.log('Received message:', message);
            if (message?.sender?.slug === selectedMemberDetails?.member?.slug) {
                setMessagesList([...messagesList, message]);
            }

            // const tabVal = searchParams.get("slug")
            // let matchedIndex = chatUsersList.findIndex(elm => elm.member?.slug === tabVal);
            // let temporaryarray = chatUsersList.slice();
            // temporaryarray[matchedIndex]?.user_chat['message'] = message?.message;
            // setChatUsersList(temporaryarray);
        });
    }

    async function getUserDetails(chatUserLists, slug) {
        try {
            const res = await apiHandler(GET_CONNECTED_USERS, 'POST', {
                slug: slug
            });
            if (res.data?.response?.status === 200) {
                generateDummyChatsUser(chatUserLists, res?.data?.data?.users?.data[0])
            } else {

            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    }

    async function getGroupDetails(chatUserLists, slug) {
        try {
            const res = await apiHandler(GROUP_DETAILS, 'POST', {
                slug: slug
            });
            if (res.data?.response?.status === 200) {
                generateDummyChatsUser(chatUserLists, res?.data?.data?.group)
            } else {

            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    }

    async function generateDummyChatsUser(chatUserLists, elm) {
        let slug = elm?.group_id ? elm?.user_group?.slug : elm?.slug;
        const found = chatUserLists.some(el => el.member?.slug === slug);
        if (!found) {
            var dummyData = null
            if (elm?.group_id) {
                dummyData = {
                    "id": elm?.id,
                    "user_id": elm?.user?.id, //profileData?.id,
                    "member_id": elm?.member_id,
                    "group_id": elm?.group_id,
                    "status": 1,
                    "user_chat_id": '',
                    "unread_count": 0,
                    "created_at": "",
                    "updated_at": "",
                    "user_group": elm?.user_group,
                    "member": elm?.user,
                    "user_chat": null
                }
            } else {
                dummyData = {
                    "id": elm?.id,
                    "user_id": profileData?.id,
                    "member_id": elm?.id,
                    "group_id": null,
                    "status": 1,
                    "user_chat_id": '',
                    "unread_count": 0,
                    "created_at": "",
                    "updated_at": "",
                    "user_group": null,
                    "member": elm,
                    "user_chat": null
                }
            }
            setSelectedMemberSlug(elm?.group_id ? elm?.user_group?.slug : elm?.slug);
            setSelectedMemberDetails(elm)
            setShow('show')
            let userType = dummyData?.group_id ? "group" : "user"
            await getChatSingleUser(userType, dummyData?.group_id ? dummyData?.group_id : dummyData?.member_id, 1)
            setChatUsersList([dummyData, ...chatUserLists]);

            //selectionUser('both', elm?.group_id ? elm?.user_group?.slug : elm?.slug);
        }
    }

    async function getChatUsers() {
        try {
            const res = await apiHandler(CHAT_USERS, 'POST', {
                page: 1,
                limit: 100
            });
            if (res.data?.response?.status === 200) {
                setChatUsersList(res?.data?.data?.chat_users?.data)
                setChatListCurrentPage(1)
                const type = searchParams.get("type")
                //const tabVal = searchParams.get("slug")
                let tabValHash = window.location.hash
                let tabVal = tabValHash.substring(1);
                if (type) {
                    if (!tabVal) {
                        tabVal = searchParams.get("slug")
                    }
                }
                // let tabValHash = window.location.hash.split("#")
                // let type = tabValHash[1]? tabValHash[1] : ""
                // let tabVal = tabValHash[2]? tabValHash[2] : ""
                if (tabVal != "") {
                    let matchedIndex = res?.data?.data?.chat_users?.data.findIndex(elm => elm.member?.slug === tabVal);
                    if (matchedIndex != -1) {
                        setSelectedMemberDetails(res?.data?.data?.chat_users?.data[matchedIndex])
                        setShow('show')
                        setSelectedMemberSlug(tabVal);
                        selectionUser('both', tabVal);

                        let userType = res?.data?.data?.chat_users?.data[matchedIndex]?.group_id ? "group" : "user"
                        let chatId = res?.data?.data?.chat_users?.data[matchedIndex]?.group_id ? res?.data?.data?.chat_users?.data[matchedIndex]?.group_id : res?.data?.data?.chat_users?.data[matchedIndex]?.member_id
                        await getChatSingleUser(userType, chatId, 1)
                    } else if (type === "group") {
                        await getGroupDetails(res?.data?.data?.chat_users?.data, tabVal);
                    } else {
                        await getUserDetails(res?.data?.data?.chat_users?.data, tabVal);
                    }
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    }


    async function scrollTopFun() {
        if (chatListRef.current) {
            const { scrollTop, scrollLeft } = chatListRef.current;
            if (scrollTop === 0) {
                if (chatListCurrentPage <= chatListLastPage) {
                    let memberId = selectedMemberDetails?.group_id ? selectedMemberDetails?.user_group?.id : selectedMemberDetails?.member_id
                    let userType = selectedMemberDetails?.group_id ? "group" : "user"
                    getChatSingleUser(userType, memberId, chatListCurrentPage + 1);
                }
            }
        }
    };
    async function getChatSingleUser(userType, otherUserId, pageParam) {
        setShowLoader(true)
        setChatListCurrentPage(pageParam)
        try {
            let dynamicParams = null
            if (userType === "group") {
                dynamicParams = {
                    // group_id: selectedMemberDetails?.group_id,
                    group_id: otherUserId,
                    page: pageParam,
                    limit: 10
                }
            } else {
                dynamicParams = {
                    other_user_id: otherUserId,
                    page: pageParam,
                    limit: 10
                }
            }
            const res = await apiHandler(CHAT_SINGLE_USER, 'POST', dynamicParams);
            if (res.data?.response?.status === 200) {
                setChatListLastPage(res?.data?.data?.chats?.meta?.last_page)
                const reversedArray = res?.data?.data?.chats?.data.reverse();
                if (pageParam === 1) {
                    setMessagesList(reversedArray)
                    if (selectedMemberDetails?.group_id) {
                        getGroupMembers(selectedMemberDetails?.group_id)
                    }
                } else {
                    setMessagesList([...reversedArray, ...messagesList])
                }
                getChatReadAll(otherUserId)
            }
        } catch (error) {
            console.error(error);
        } finally {
            setShowLoader(false)
        }
    }

    async function getChatReadAll(otherUserId) {
        try {
            let dynamicParams = null
            if (selectedMemberDetails?.group_id) {
                dynamicParams = {
                    group_id: selectedMemberDetails?.group_id
                }
            } else {
                dynamicParams = {
                    other_user_id: otherUserId
                }
            }

            const res = await apiHandler(CHAT_READ_ALL, 'POST', dynamicParams);
            if (res.data?.response?.status === 200) {

            } else {

            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    }

    async function deleteMessage(chatID, index) {
        try {
            const res = await apiHandler(CHAT_DELETE, 'DELETE', {
                chat_id: chatID
            });
            if (res.data?.response?.status === 200) {
                let temporaryarray = messagesList.slice();
                temporaryarray.splice(index, 1)
                setMessagesList(temporaryarray);
            } else {

            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    }

    async function getChatUserSearch(searchText) {
        try {
            const res = await apiHandler(GET_CONNECTED_USERS, 'POST', {
                search_text: searchText
            });
            if (res.data?.response?.status === 200) {
                setSearchProfileData(res?.data?.data?.users?.data)
            } else {
                setSearchProfileData([])
            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    }

    async function deleteGroupApi(groupId) {
        try {
            const res = await apiHandler(GROUP_DELETE, 'DELETE', {
                group_id: groupId
            });
            if (res.data?.response?.status === 200) {
                pageRefreshFunc()
            } else {
                Swal.fire({
                    title: 'Error',
                    text: res?.response?.data?.data?.message,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    }

    async function pageRefreshFunc() {
        window.location = '/chat/index';
    }

    async function exitGroupApi(groupId, memberId) {
        try {
            const res = await apiHandler(GROUP_REMOVE_MEMBER, 'DELETE', {
                group_id: groupId,
                member_id: memberId
            });
            if (res.data?.response?.status === 200) {
                pageRefreshFunc()
            } else {
                Swal.fire({
                    title: 'Error',
                    text: res?.response?.data?.data?.message,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    }

    async function getGroupMembers(groupId) {
        try {
            const res = await apiHandler(GROUP_MEMBERS, 'POST', {
                page: 1,
                limit: 1,
                group_id: groupId
            });
            if (res.data?.response?.status === 200) {
                setTotalMembers(res?.data?.data?.group_members?.meta?.total)
            } else {
            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    }


    return (
        <>
            <LoaderModal showLoader={showLoader} />
            <Tab.Container id="left-tabs-example" defaultActiveKey="start">
                <Row>
                    <Col sm="12">
                        <Card>
                            <Card.Body className="chat-page p-0">
                                <div className="chat-data-block">
                                    <Row>
                                        <Col lg="3" className="chat-data-left scroller">
                                            <div className="chat-search pt-3 ps-3">
                                                <div className="d-flex align-items-center">
                                                    <div className="chat-profile me-3">
                                                        <img loading="lazy" src={profileData?.user_setting?.photo ? profileData?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"}
                                                            alt="chat-user" className="avatar-60 " onClick={() => setShow1('true')} />
                                                    </div>
                                                    <div className="chat-caption">
                                                        <h5 className="mb-0">{profileData?.first_name + " " + profileData?.last_name}</h5>
                                                        <p className="m-0">{profileData?.slug}</p>
                                                    </div>
                                                </div>
                                                <div id="user-detail-popup" className={`scroller ${show1 === 'true' ? 'show' : ''}`}>
                                                    <div className="user-profile">
                                                        <Button type="submit" onClick={ChatSidebarClose} variant=" close-popup p-3"><i className="material-symbols-outlined md-18" onClick={() => setShow1('false')}>close</i></Button>
                                                        <div className="user text-center mb-4">
                                                            <Link className="avatar m-0" to="">
                                                                <img loading="lazy" src={profileData?.user_setting?.photo ? profileData?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="avatar" className="avatar-80 " />
                                                            </Link>
                                                            <div className="user-name mt-4">
                                                                <h4 className="text-center">{profileData?.first_name + " " + profileData?.last_name}</h4>
                                                            </div>
                                                            <div className="user-desc">
                                                                <p className="text-center">{profileData?.slug}</p>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="user-detail text-left mt-4 ps-4 pe-4">
                                                            <h5 className="mt-4 mb-4">About</h5>
                                                            <p>It is long established fact that a reader will be distracted bt the reddable.</p>
                                                            <h5 className="mt-3 mb-3">Status</h5>
                                                            <ul className="user-status p-0">
                                                                <li className="mb-1"><i className="ri-checkbox-blank-circle-fill text-success pe-1"></i><span>Online</span></li>
                                                                <li className="mb-1"><i className="ri-checkbox-blank-circle-fill text-warning pe-1"></i><span>Away</span></li>
                                                                <li className="mb-1"><i className="ri-checkbox-blank-circle-fill text-danger pe-1"></i><span>Do Not Disturb</span></li>
                                                                <li className="mb-1"><i className="ri-checkbox-blank-circle-fill text-light pe-1"></i><span>Offline</span></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="chat-searchbar mt-4">
                                                    <Form.Group className="form-group chat-search-data m-0">
                                                        <input type="text" className="form-control round" id="chat-search" placeholder="Search"
                                                            value={searchContent} onChange={(e) => {
                                                                setSearchContent(e.target.value)
                                                                getChatUserSearch(e.target.value)
                                                            }} />
                                                        <i className="material-symbols-outlined">
                                                            search
                                                        </i>
                                                    </Form.Group>
                                                </div> */}
                                                <div className="iq-search-bar device-search position-relative mt-4">
                                                    <div className="card-header-toolbar d-flex align-items-center">
                                                        <Dropdown >
                                                            <Dropdown.Toggle as={CustomToggle} id="post-option" >
                                                                <form
                                                                    action="#"
                                                                    className="searchbox"
                                                                    onClick={handleShow}
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#exampleModalFullscreenSm"
                                                                >
                                                                    <Link className="search-link d-none d-lg-block" to="/">
                                                                        <span className="material-symbols-outlined">search</span>
                                                                    </Link>
                                                                    <Form.Control
                                                                        type="text"
                                                                        className="text search-input form-control bg-soft-primary  d-none d-lg-block"
                                                                        placeholder="Search here..."
                                                                        value={searchContent} onChange={(e) => {
                                                                            setSearchContent(e.target.value)
                                                                            getChatUserSearch(e.target.value)
                                                                        }}
                                                                    />
                                                                    <Link
                                                                        className="d-lg-none d-flex d-none d-lg-block"
                                                                        to="/"
                                                                        onClick={handleShow}
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#exampleModalFullscreenSm"
                                                                    >
                                                                        <span className="material-symbols-outlined">search</span>
                                                                    </Link>
                                                                </form>
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu className="dropdown-menu-right cardBackgroundColor" style={{ maxHeight: "500px", overflowY: "scroll" }}>
                                                                <div style={{ backgroundColor: "#FFFFFF", margin: "1px" }}>
                                                                    {
                                                                        searchProfileData?.map((elm, i) => (
                                                                            <Dropdown.Item key={elm?.slug} className='border-bottom search-hover'
                                                                                onClick={() => {
                                                                                    generateDummyChatsUser(chatUsersList, elm)
                                                                                }}
                                                                                //href={`#user#${elm?.slug}`}>
                                                                                // href={`?type=user&slug=${elm?.slug}`}>
                                                                                href={`?type=user/#${elm?.slug}`}>
                                                                                <div className="d-flex align-items-center py-1 px-4">
                                                                                    <div className="flex-shrink-0">
                                                                                        <Image
                                                                                            className="align-self-center img-fluid avatar-40 rounded-pill"
                                                                                            src={elm?.user_setting?.photo ? elm?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"}
                                                                                            alt=""
                                                                                            loading="lazy" />
                                                                                    </div>
                                                                                    <div className="d-flex flex-column ms-3">
                                                                                        <h5>{elm?.first_name && (elm?.first_name + " " + elm?.last_name)}</h5>
                                                                                        <h6>{elm?.is_online === 1 ? "Online" : "Offline"}</h6>
                                                                                        {/* <h6>{elm?.follower_count + " Followers " + " ● " + elm?.following_count + " Following "}</h6> */}
                                                                                    </div>
                                                                                </div>
                                                                            </Dropdown.Item>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="chat-sidebar-channel scroller mt-4 ps-3">
                                                <h5 >Chat Users</h5>
                                                <Nav as="ul" variant="pills" className="iq-chat-ui nav flex-column">
                                                    {
                                                        chatUsersList?.length > 0 ? chatUsersList?.map((elm, index) => (
                                                            <Nav.Item as="li" key={elm?.id} >
                                                                <Nav.Link eventKey={elm?.group_id ? elm?.user_group?.slug : elm?.member?.slug} ref={chatUserRef.current[index]} onClick={async () => {
                                                                    // let queryClassElementts = document.querySelectorAll(".nav-link");
                                                                    // queryClassElementts.forEach(element => {
                                                                    //     element.classList.remove('active');
                                                                    // });
                                                                    setSelectedMemberSlug(elm?.group_id ? elm?.user_group?.slug : elm?.member?.slug);
                                                                    selectionUser('user', elm?.group_id ? elm?.user_group?.slug : elm?.member?.slug);
                                                                    let chatId = elm?.group_id ? elm?.group_id : elm?.member_id
                                                                    getChatSingleUser(elm?.group_id ? "group" : "user", chatId, 1)
                                                                    setSelectedMemberDetails(elm)
                                                                    setShow('show')
                                                                    // }} href={elm?.group_id ? `?type=group&slug=${elm?.user_group?.slug}` : `?type=user&slug=${elm?.member?.slug}`} >
                                                                    // }} href={elm?.group_id ? `?type=group/#${elm?.user_group?.slug}` : `?type=user/#${elm?.member?.slug}`} >
                                                                }} href={elm?.group_id ? `#group#${elm?.user_group?.slug}` : `#user#${elm?.member?.slug}`} >
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="avatar me-2">
                                                                            {
                                                                                elm?.group_id ?
                                                                                    <img loading="lazy" src={elm?.user_group?.image ? elm?.user_group?.image : "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="chatuserimage" className="avatar-50 " /> :
                                                                                    <img loading="lazy" src={elm?.member?.user_setting?.photo ? elm?.member?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="chatuserimage" className="avatar-50 " />
                                                                            }

                                                                            {
                                                                                elm?.member?.is_online === 1 ?
                                                                                    <span className="avatar-status"><i className="material-symbols-outlined text-success  md-14 filled">circle</i></span> :
                                                                                    <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-warning"></i></span>
                                                                            }
                                                                        </div>
                                                                        <div className="chat-sidebar-name">
                                                                            {
                                                                                elm?.group_id ?
                                                                                    <h6 className="mb-0">{elm?.user_group?.name}</h6> :
                                                                                    <h6 className="mb-0">{elm?.member?.first_name + " " + elm?.member?.last_name}</h6>
                                                                            }
                                                                            <span style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", width: "250px", display: "block" }}>
                                                                                {elm?.user_chat?.message}</span>
                                                                        </div>
                                                                        {
                                                                            elm?.unread_count > 0 ?
                                                                                <div className="chat-meta float-right text-center mt-2 me-1">
                                                                                    <div className="chat-msg-counter bg-primary text-white">{elm?.unread_count}</div>
                                                                                    <span className="text-nowrap" style={{ fontSize: "10px" }}>{Moment(elm?.user_chat?.updated_at).format('DD MMMM, hh:mm A')}</span>
                                                                                </div> : null
                                                                        }
                                                                    </div>
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                        )) : null
                                                    }
                                                    {/* <li>
                                                        <Nav.Link eventKey="second" onClick={() => setShow('second')} href="#chatbox2">
                                                            <div className="d-flex align-items-center">
                                                                <div className="avatar me-2">
                                                                    <img loading="lazy" src={user6} alt="chatuserimage" className="avatar-50 " />
                                                                    <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-success"></i></span>
                                                                </div>
                                                                <div className="chat-sidebar-name">
                                                                    <h6 className="mb-0">Announcement</h6>
                                                                    <span>This Sunday we</span>
                                                                </div>
                                                                <div className="chat-meta float-right text-center mt-2 me-1">
                                                                    <div className="chat-msg-counter bg-primary text-white">10</div>
                                                                    <span className="text-nowrap">10 min</span>
                                                                </div>
                                                            </div>
                                                        </Nav.Link>
                                                    </li> */}
                                                </Nav>
                                                {/* <h5 className="mt-3">Private Channels</h5>
                                                <Nav variant="pills" className="iq-chat-ui nav flex-column ">
                                                    <li>
                                                        <Nav.Link eventKey="third" onClick={() => setShow('third')} href="#chatbox3">
                                                            <div className="d-flex align-items-center">
                                                                <div className="avatar me-2">
                                                                    <img loading="lazy" src={user7} alt="chatuserimage" className="avatar-50 " />
                                                                    <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-warning"></i></span>
                                                                </div>
                                                                <div className="chat-sidebar-name">
                                                                    <h6 className="mb-0">Designer</h6>
                                                                    <span>There are many </span>
                                                                </div>
                                                            </div>
                                                        </Nav.Link>
                                                    </li>
                                                    <li>
                                                        <Nav.Link eventKey="forth" onClick={() => setShow('forth')} href="#chatbox4">
                                                            <div className="d-flex align-items-center">
                                                                <div className="avatar me-2">
                                                                    <img loading="lazy" src={user8} alt="chatuserimage" className="avatar-50 " />
                                                                    <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-success"></i></span>
                                                                </div>
                                                                <div className="chat-sidebar-name">
                                                                    <h6 className="mb-0">Developer</h6>
                                                                    <span>passages of Lorem</span>
                                                                </div>
                                                            </div>
                                                        </Nav.Link>
                                                    </li>
                                                    <li>
                                                        <Nav.Link eventKey="five" onClick={() => setShow('five')} href="#chatbox5">
                                                            <div className="d-flex align-items-center">
                                                                <div className="avatar me-2">
                                                                    <img loading="lazy" src={user9} alt="chatuserimage" className="avatar-50 " />
                                                                    <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-info"></i></span>
                                                                </div>
                                                                <div className="chat-sidebar-name">
                                                                    <h6 className="mb-0">Testing Team</h6>
                                                                    <span>Lorem Ipsum used</span>
                                                                </div>
                                                            </div>
                                                        </Nav.Link>
                                                    </li>
                                                </Nav>
                                                <h5 className="mt-3">Direct Message</h5>
                                                <Nav variant="pills" className="iq-chat-ui nav flex-column ">
                                                    <li>
                                                        <Nav.Link eventKey="six" onClick={() => setShow('six')} href="#chatbox6">
                                                            <div className="d-flex align-items-center">
                                                                <div className="avatar me-2">
                                                                    <img loading="lazy" src={user10} alt="chatuserimage" className="avatar-50 " />
                                                                    <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-dark"></i></span>
                                                                </div>
                                                                <div className="chat-sidebar-name">
                                                                    <h6 className="mb-0">Paul Molive</h6>
                                                                    <span>translation by</span>
                                                                </div>
                                                            </div>
                                                        </Nav.Link>
                                                    </li>
                                                    <li>
                                                        <Nav.Link eventKey="seven" onClick={() => setShow('seven')} href="#chatbox7">
                                                            <div className="d-flex align-items-center">
                                                                <div className="avatar me-2">
                                                                    <img loading="lazy" src={user5} alt="chatuserimage" className="avatar-50 " />
                                                                    <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-success"></i></span>
                                                                </div>
                                                                <div className="chat-sidebar-name">
                                                                    <h6 className="mb-0">Paige Turner</h6>
                                                                    <span>Lorem Ipsum which</span>
                                                                </div>
                                                            </div>
                                                        </Nav.Link>
                                                    </li>
                                                    <li>
                                                        <Nav.Link eventKey="eight" onClick={() => setShow('eight')} href="#chatbox8">
                                                            <div className="d-flex align-items-center">
                                                                <div className="avatar me-2">
                                                                    <img loading="lazy" src={user6} alt="chatuserimage" className="avatar-50 " />
                                                                    <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-primary"></i></span>
                                                                </div>
                                                                <div className="chat-sidebar-name">
                                                                    <h6 className="mb-0">Barb Ackue</h6>
                                                                    <span>simply random text</span>
                                                                </div>
                                                            </div>
                                                        </Nav.Link>
                                                    </li>
                                                    <li>
                                                        <Nav.Link eventKey="nine" onClick={() => setShow('nine')} href="#chatbox9">
                                                            <div className="d-flex align-items-center">
                                                                <div className="avatar me-2">
                                                                    <img loading="lazy" src={user7} alt="chatuserimage" className="avatar-50 " />
                                                                    <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-danger"></i></span>
                                                                </div>
                                                                <div className="chat-sidebar-name">
                                                                    <h6 className="mb-0">Maya Didas</h6>
                                                                    <span> but also the leap</span>
                                                                </div>
                                                            </div>
                                                        </Nav.Link>
                                                    </li>
                                                    <li>
                                                        <Nav.Link eventKey="ten" onClick={() => setShow('ten')} href="#chatbox10">
                                                            <div className="d-flex align-items-center">
                                                                <div className="avatar me-2">
                                                                    <img loading="lazy" src={user8} alt="chatuserimage" className="avatar-50 " />
                                                                    <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-warning"></i></span>
                                                                </div>
                                                                <div className="chat-sidebar-name">
                                                                    <h6 className="mb-0">Monty Carlo</h6>
                                                                    <span>Contrary to popular</span>
                                                                </div>
                                                            </div>
                                                        </Nav.Link>
                                                    </li>
                                                </Nav> */}
                                            </div>
                                        </Col>
                                        <Col lg={9} className=" chat-data p-0 chat-data-right">
                                            <Tab.Content>
                                                <Tab.Pane eventKey="start" className="tab-pane fade show" id="default-block" role="tabpanel">
                                                    <div className="chat-start">
                                                        <span className="iq-start-icon text-primary"><i className="material-symbols-outlined md-42">sms</i></span>
                                                        <Button id="chat-start" onClick={ChatSidebar} bsPrefix="btn bg-white mt-3">Start
                                                            Conversation!</Button>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey={selectedMemberDetails?.group_id ? selectedMemberDetails?.user_group?.slug : selectedMemberDetails?.member?.slug} className={`fade ${show === 'show' ? 'show' : ''}`} id="chatbox1" role="tabpanel">
                                                    <div className="chat-head" >
                                                        <header className="d-flex justify-content-between align-items-center bg-white pt-3  ps-3 pe-3 pb-3">
                                                            <div className="d-flex align-items-center">
                                                                <div className="sidebar-toggle">
                                                                    <i className="ri-menu-3-line"></i>
                                                                </div>
                                                                <div className="avatar chat-user-profile m-0 me-3">
                                                                    {
                                                                        selectedMemberDetails?.group_id ?
                                                                            <img loading="lazy" src={selectedMemberDetails?.user_group?.image ? selectedMemberDetails?.user_group?.image : "https://uzone360live.s3.amazonaws.com/noimage.png"}
                                                                                alt="avatar" className="avatar-50 " onClick={() => setShow2('true')} /> :
                                                                            <img loading="lazy" src={selectedMemberDetails?.member?.user_setting?.photo ? selectedMemberDetails?.member?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"}
                                                                                alt="avatar" className="avatar-50 " onClick={() => setShow2('true')} />
                                                                    }
                                                                    {
                                                                        selectedMemberDetails?.group_id ? null :
                                                                            selectedMemberDetails?.member?.is_online === 1 ?
                                                                                <span className="avatar-status"><i className="material-symbols-outlined text-success  md-14 filled">circle</i></span> :
                                                                                <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-warning"></i></span>
                                                                    }
                                                                </div>
                                                                <div className="chat-sidebar-name">
                                                                    {
                                                                        selectedMemberDetails?.group_id ?
                                                                            <h5 className="mb-0">{selectedMemberDetails?.user_group?.name}</h5> :
                                                                            <h5 className="mb-0">{selectedMemberDetails?.member?.first_name + " " + selectedMemberDetails?.member?.last_name}</h5>
                                                                    }
                                                                    {
                                                                        selectedMemberDetails?.group_id ?
                                                                            <Link onClick={() => setShowGroupModalShow(true)}>
                                                                                <h6>{totalMembers + " Members"}</h6>
                                                                            </Link> :
                                                                            selectedMemberDetails?.member?.is_online === 1 ?
                                                                                <span>Online</span> :
                                                                                <span>Offline</span>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className={`chat-user-detail-popup scroller ${show2 === 'true' ? 'show' : ''}`}>
                                                                <div className="user-profile">
                                                                    <Button type="submit" onClick={ChatSidebarClose} variant=" close-popup p-3"><i className="material-symbols-outlined md-18" onClick={() => setShow2('false')}>close</i></Button>
                                                                    <div className="user mb-4  text-center">
                                                                        <Link className="avatar m-0" to="">
                                                                            <img loading="lazy" src={selectedMemberDetails?.member?.user_setting?.photo ? selectedMemberDetails?.member?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="avatar" className="avatar-100 " />
                                                                        </Link>
                                                                        <div className="user-name mt-4">
                                                                            <h4>{selectedMemberDetails?.member?.first_name + " " + selectedMemberDetails?.member?.last_name}</h4>
                                                                        </div>
                                                                        <div className="user-desc">
                                                                            <p>Cape Town, RSA</p>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div className="chatuser-detail text-left mt-4">
                                                                        <Row>
                                                                            <Col md="6" className="col-6  title">Bni Name:</Col>
                                                                            <Col md="6" className="col-6  text-right">Bni</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Tel:</Col>
                                                                            <Col md="6" className="col-6 text-right">072 143 9920</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Date Of Birth:</Col>
                                                                            <Col md="6" className="col-6 text-right">July 12, 1989</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Gender:</Col>
                                                                            <Col md="6" className="col-6 text-right">Male</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Language:</Col>
                                                                            <Col md="6" className="col-6 text-right">Engliah</Col>
                                                                        </Row>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="chat-header-icons d-flex">
                                                                {/* <Link to="#" className="chat-icon-phone bg-soft-primary d-flex justify-content-center align-items-center">
                                                                    <i className="material-symbols-outlined md-18">phone</i>
                                                                </Link>
                                                                <Link to="#" className="chat-icon-phone bg-soft-primary d-flex justify-content-center align-items-center">
                                                                    <i className="material-symbols-outlined md-18">videocam</i>
                                                                </Link>
                                                                <Link to="#" className="chat-icon-phone bg-soft-primary d-flex justify-content-center align-items-center">
                                                                    <i className="material-symbols-outlined md-18">delete</i>
                                                                </Link> */}

                                                                {
                                                                    selectedMemberDetails?.group_id ?
                                                                        <>
                                                                            <Link to="#" onClick={() => setShowGroupModalShow(true)} className="chat-icon-phone bg-soft-primary d-flex justify-content-center align-items-center">
                                                                                <i className="material-symbols-outlined md-18">group</i>
                                                                            </Link>
                                                                            <Dropdown className="bg-soft-primary d-flex justify-content-center align-items-center" as="span">
                                                                                <Dropdown.Toggle as={CustomToggle} variant="material-symbols-outlined cursor-pointer md-18 nav-hide-arrow pe-0 show">
                                                                                    more_vert
                                                                                </Dropdown.Toggle>
                                                                                <Dropdown.Menu className="dropdown-menu-right">
                                                                                    <Dropdown.Item className="d-flex align-items-center" href="#" onClick={() => setShowGroupModalShow(true)}><i className="material-symbols-outlined md-18 me-1">group</i>View Members</Dropdown.Item>
                                                                                    {
                                                                                        selectedMemberDetails?.user_group?.user_id === profileData?.id ?
                                                                                            <Dropdown.Item className="d-flex align-items-center" href="#" onClick={() => setShowAddMemberModal(true)}><i className="material-symbols-outlined md-18 me-1">group_add</i>Add New Members</Dropdown.Item> : null
                                                                                    }
                                                                                    {
                                                                                        selectedMemberDetails?.user_group?.user_id === profileData?.id ?
                                                                                            <Dropdown.Item className="d-flex align-items-center" href="#"
                                                                                                onClick={() => {
                                                                                                    Swal.fire({
                                                                                                        title: 'Warning!',
                                                                                                        text: 'Are you sure, want to delete this ' + selectedMemberDetails?.user_group?.name + '?',
                                                                                                        icon: 'warning',
                                                                                                        showCancelButton: true,
                                                                                                        showConfirmButton: true,
                                                                                                        cancelButtonText: 'Cancel',
                                                                                                        confirmButtonText: 'Confirm',
                                                                                                    }).then((result) => {
                                                                                                        if (result.isConfirmed) {
                                                                                                            deleteGroupApi(selectedMemberDetails?.group_id)
                                                                                                        }
                                                                                                    })
                                                                                                }}><i className="material-symbols-outlined md-18 me-1">delete</i>Delete Group</Dropdown.Item> :
                                                                                            <Dropdown.Item className="d-flex align-items-center" href="#"
                                                                                                onClick={() => {
                                                                                                    Swal.fire({
                                                                                                        title: 'Warning!',
                                                                                                        text: 'Are you sure, want to exit from ' + selectedMemberDetails?.user_group?.name + '?',
                                                                                                        icon: 'warning',
                                                                                                        showCancelButton: true,
                                                                                                        showConfirmButton: true,
                                                                                                        cancelButtonText: 'Cancel',
                                                                                                        confirmButtonText: 'Confirm',
                                                                                                    }).then((result) => {
                                                                                                        if (result.isConfirmed) {
                                                                                                            exitGroupApi(selectedMemberDetails?.group_id, profileData?.id)
                                                                                                        }
                                                                                                    })
                                                                                                }}><i className="material-symbols-outlined md-18 me-1">group_remove</i>Exit Group</Dropdown.Item>
                                                                                    }
                                                                                    {/* <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">delete_outline</i>Delete chat</Dropdown.Item> */}
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </> : null
                                                                }
                                                            </div>
                                                        </header>
                                                    </div>

                                                    {/* <div key={elm?.id} className="chat-content scroller">
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:45</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>How can we help? We're here for you! 😄</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat chat-left">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user5} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:48</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Hey John, I am looking for the best admin template.</p>
                                                                    <p>Could you please help me to find it out? 🤔</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}

                                                    <div className="chat-content scroller" ref={chatListRef} onScroll={scrollTopFun}>
                                                        {
                                                            messagesList?.length > 0 ? messagesList?.map((elm, index) => (
                                                                <>
                                                                    {
                                                                        profileData?.id === elm?.sender_id ?
                                                                            <div className="chat d-flex other-user">
                                                                                <div className="chat-user">
                                                                                    <Link className="avatar m-0" to="">
                                                                                        <img loading="lazy" src={elm?.sender?.user_setting?.photo ? elm?.sender?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="avatar" className="avatar-35 " />
                                                                                    </Link>
                                                                                    {/* <span className="chat-time mt-1">{Moment(elm?.updated_at).format('hh:mm A')}</span> */}
                                                                                </div>
                                                                                <Dropdown>
                                                                                    <Dropdown.Toggle as={CustomToggle} variant="material-symbols-outlined cursor-pointer md-18 nav-hide-arrow pe-0 show">
                                                                                        more_vert
                                                                                    </Dropdown.Toggle>
                                                                                    <Dropdown.Menu className="dropdown-menu-right">
                                                                                        <Dropdown.Item className="d-flex align-items-center" href="#" onClick={() => {
                                                                                            Swal.fire({
                                                                                                title: 'Warning!',
                                                                                                text: 'Are you sure, want to delete this message?',
                                                                                                icon: 'warning',
                                                                                                showCancelButton: true,
                                                                                                showConfirmButton: true,
                                                                                                cancelButtonText: 'Cancel',
                                                                                                confirmButtonText: 'Confirm',
                                                                                            }).then((result) => {
                                                                                                if (result.isConfirmed) {
                                                                                                    deleteMessage(elm?.id, index)
                                                                                                }
                                                                                            })
                                                                                        }}><i className="material-symbols-outlined md-18 me-1">delete_outline</i>Delete chat</Dropdown.Item>
                                                                                        {/* <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">push_pin</i>Pin to top</Dropdown.Item>
                                                                                            <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">watch_later</i>Block</Dropdown.Item> */}
                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>
                                                                                <div className="chat-detail">
                                                                                    <div className="chat-message">
                                                                                        {
                                                                                            elm?.user_chat_images?.length > 0 ?
                                                                                                <ChatMediaView chatData={elm} key={elm?.id} /> : null
                                                                                        }
                                                                                        <p>{elm?.message}</p>
                                                                                        <div>
                                                                                            {
                                                                                                elm?.is_read === 0 ?
                                                                                                    <span className="chat-time mt-2 d-flex align-items-center">{Moment(elm?.updated_at).format('hh:mm A - D MMM Y')}<i className="material-symbols-outlined md-18 me-1">done_all</i></span> :
                                                                                                    <span className="chat-time mt-2 d-flex align-items-center">{Moment(elm?.updated_at).format('hh:mm A - D MMM Y')}<i className="material-symbols-outlined md-18 me-1">done</i></span>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div> :
                                                                            <div className="chat chat-left">
                                                                                <div className="chat-user">
                                                                                    <Link className="avatar m-0" to="">
                                                                                        <img loading="lazy" src={elm?.sender?.user_setting?.photo ? elm?.sender?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="avatar" className="avatar-35 " />
                                                                                    </Link>
                                                                                    <span className="chat-time mt-1">{Moment(elm?.updated_at).format('hh:mm A')}</span>
                                                                                </div>
                                                                                <div className="chat-detail">
                                                                                    <div className="chat-message">
                                                                                        {
                                                                                            elm?.user_chat_images?.length > 0 ?
                                                                                                <ChatMediaView chatData={elm} key={elm?.id} /> : null
                                                                                        }
                                                                                        <p>{elm?.message}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                    }

                                                                </>
                                                            )) : null
                                                        }
                                                    </div>

                                                    <div className="chat-footer p-3 bg-white" style={{ minHeight: "65px" }}>
                                                        <div className="row w-100" style={{ position: "absolute", bottom: 0 }}>
                                                            {imageArray.length > 0 || videoThumbArray.length > 0 ?
                                                                <>
                                                                    {
                                                                        imageArray?.length > 0 ? imageArray.map((elm, i) => (
                                                                            <div key={elm + i} className="col-md-2 col-sm-12 col-lg-2">
                                                                                <img style={{ height: 70, width: 70, marginRight: "17px", }} loading="lazy" src={elm}
                                                                                    alt="gallary" className="img-fluid" />
                                                                                <Link style={{ position: "absolute", marginTop: "-9px", marginLeft: "-30px", }}
                                                                                    onClick={() => {
                                                                                        imageFileArray.splice(i, 1)
                                                                                        imageArray.splice(i, 1)
                                                                                    }}>
                                                                                    {/* <i className="icon material-symbols-outlined">cancel</i> */}
                                                                                    <img style={{ height: 25, width: 25 }} src={cross} />
                                                                                </Link>
                                                                            </div>
                                                                        )) : null
                                                                    }
                                                                    {
                                                                        videoThumbArray?.length > 0 ? videoThumbArray.map((elm, i) => (
                                                                            <div key={"key" + i} className="col-md-2 col-sm-12 col-lg-2" >
                                                                                <div style={{ width: 70, height: 70 }} className="img-fluid">
                                                                                    <img style={{ height: 70, width: 70, marginRight: "17px", }} loading="lazy" src={videoThumbArray[i]}
                                                                                        alt="gallary" className="img-fluid" />
                                                                                </div>

                                                                                <Link style={{ position: "absolute", marginTop: "-79px", marginLeft: "55px", zIndex: 1000 }}
                                                                                    onClick={() => deleteVideo(i)}>
                                                                                    <img style={{ height: 25, width: 25 }} src={cross} />
                                                                                </Link>
                                                                                <Link style={{ position: "absolute", marginTop: "-48px", marginLeft: "26px", }}>
                                                                                    <img style={{ height: 25, width: 25 }} src={playButton} />
                                                                                </Link>
                                                                            </div>
                                                                        )) : null
                                                                    }
                                                                    <hr style={{ marginTop: "20px" }} />
                                                                </> : null
                                                            }
                                                            {showPicker ?
                                                                <EmojiPicker pickerStyle={{ width: "100%" }} onEmojiClick={(emojiObject) => setMessage((prevMsg) => prevMsg + emojiObject.emoji)} /> : null
                                                            }
                                                            <Form className="d-flex align-items-center" action="javascript:;" style={{ paddingBottom: 10 }}>
                                                                <div className="chat-attagement d-flex">
                                                                    {/* <Link to="#" className="me-2"><i className="far fa-smile pe-3" aria-hidden="true"></i></Link> */}
                                                                    {/* <Link to="#"><i className="fa fa-paperclip pe-3" aria-hidden="true"></i></Link> */}
                                                                    <div className="pointer me-3" onClick={() => setShowPicker((val) => !val)}>
                                                                        <img style={{ height: 25, width: 30, cursor: "pointer" }} loading="lazy" src={smileIcon} alt="icon" className="img-fluid" />
                                                                    </div>
                                                                    <div className="pointer me-2">
                                                                        <label htmlFor="imageUpload1">
                                                                            <img style={{ height: 25, width: 30, cursor: "pointer" }} loading="lazy" src={imageIcon} alt="icon" className="img-fluid" />
                                                                        </label>
                                                                        <input className="file-upload"
                                                                            id="imageUpload1" type="file" accept="image/*"
                                                                            multiple="multiple"
                                                                            onChange={handleImageChange} />
                                                                    </div>
                                                                    <div className="pointer me-2">
                                                                        <label htmlFor="videoUpload1">
                                                                            <img style={{ height: 35, width: 40, cursor: "pointer" }} loading="lazy" src={videoIcon} alt="icon" className="img-fluid" />
                                                                        </label>
                                                                        <input className="file-upload"
                                                                            id="videoUpload1" type="file" accept="video/*"
                                                                            multiple="multiple"
                                                                            onChange={handleVideoChange} />
                                                                    </div>
                                                                </div>
                                                                <Form.Control type="text" className="form-control me-3" placeholder="Type your message"
                                                                    value={message} onKeyUp={chatEnterKeyMessage} onChange={(e) => setMessage(e.target.value)} />
                                                                <Button style={{ marginRight: "20px" }} type="button" onClick={() => {
                                                                    sendMessage()
                                                                }} variant="primary d-flex align-items-center"><i className="far fa-paper-plane" aria-hidden="true"></i><span className="d-none d-lg-block ms-1">Send</span></Button>
                                                            </Form>
                                                        </div>
                                                        {
                                                            showGroupModalShow ?
                                                                <GroupMemberModal
                                                                    showGroupModalShow={showGroupModalShow}
                                                                    setShowGroupModalShow={setShowGroupModalShow}
                                                                    selectedMemberDetails={selectedMemberDetails} />
                                                                : null
                                                        }
                                                        {
                                                            showAddMemberModal ?
                                                                <AddNewMemberModal
                                                                    showAddMemberModal={showAddMemberModal}
                                                                    setShowAddMemberModal={setShowAddMemberModal}
                                                                    groupDetails={selectedMemberDetails} /> : null
                                                        }
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                            {/* <Tab.Content>
                                                <Tab.Pane eventKey="start" className="tab-pane fade show" id="default-block" role="tabpanel">
                                                    <div className="chat-start">
                                                        <span className="iq-start-icon text-primary"><i className="material-symbols-outlined md-42">sms</i></span>
                                                        <Button id="chat-start" onClick={ChatSidebar} bsPrefix="btn bg-white mt-3">Start
                                                            Conversation!</Button>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="first" className={`fade ${show === 'first' ? 'show' : ''}`} id="chatbox1" role="tabpanel">
                                                    <div className="chat-head">
                                                        <header className="d-flex justify-content-between align-items-center bg-white pt-3  ps-3 pe-3 pb-3">
                                                            <div className="d-flex align-items-center">
                                                                <div className="sidebar-toggle">
                                                                    <i className="ri-menu-3-line"></i>
                                                                </div>
                                                                <div className="avatar chat-user-profile m-0 me-3">
                                                                    <img loading="lazy" src={user5} alt="avatar" className="avatar-50 " onClick={() => setShow2('true')} />
                                                                    <span className="avatar-status"><i className="material-symbols-outlined text-success  md-14 filled">circle</i></span>
                                                                </div>
                                                                <h5 className="mb-0">Team Discussions</h5>
                                                            </div>
                                                            <div className={`chat-user-detail-popup scroller ${show2 === 'true' ? 'show' : ''}`}>
                                                                <div className="user-profile">
                                                                    <Button type="submit" onClick={ChatSidebarClose} variant=" close-popup p-3"><i className="material-symbols-outlined md-18" onClick={() => setShow2('false')}>close</i></Button>
                                                                    <div className="user mb-4  text-center">
                                                                        <Link className="avatar m-0" to="">
                                                                            <img loading="lazy" src={user5} alt="avatar" />
                                                                        </Link>
                                                                        <div className="user-name mt-4">
                                                                            <h4>Bni Jordan</h4>
                                                                        </div>
                                                                        <div className="user-desc">
                                                                            <p>Cape Town, RSA</p>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div className="chatuser-detail text-left mt-4">
                                                                        <Row>
                                                                            <Col md="6" className="col-6  title">Bni Name:</Col>
                                                                            <Col md="6" className="col-6  text-right">Bni</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Tel:</Col>
                                                                            <Col md="6" className="col-6 text-right">072 143 9920</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Date Of Birth:</Col>
                                                                            <Col md="6" className="col-6 text-right">July 12, 1989</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Gender:</Col>
                                                                            <Col md="6" className="col-6 text-right">Male</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Language:</Col>
                                                                            <Col md="6" className="col-6 text-right">Engliah</Col>
                                                                        </Row>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="chat-header-icons d-flex">
                                                                <Link to="#" className="chat-icon-phone bg-soft-primary d-flex justify-content-center align-items-center">
                                                                    <i className="material-symbols-outlined md-18">phone</i>
                                                                </Link>
                                                                <Link to="#" className="chat-icon-phone bg-soft-primary d-flex justify-content-center align-items-center">
                                                                    <i className="material-symbols-outlined md-18">videocam</i>
                                                                </Link>
                                                                <Link to="#" className="chat-icon-phone bg-soft-primary d-flex justify-content-center align-items-center">
                                                                    <i className="material-symbols-outlined md-18">delete</i>
                                                                </Link>
                                                                <Dropdown className="bg-soft-primary d-flex justify-content-center align-items-center" as="span">
                                                                    <Dropdown.Toggle as={CustomToggle} variant="material-symbols-outlined cursor-pointer md-18 nav-hide-arrow pe-0 show">
                                                                        more_vert
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu className="dropdown-menu-right">
                                                                        <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">push_pin</i>Pin to top</Dropdown.Item>
                                                                        <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">delete_outline</i>Delete chat</Dropdown.Item>
                                                                        <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">watch_later</i>Block</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                        </header>
                                                    </div>
                                                    <div className="chat-content scroller">
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:45</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>How can we help? We're here for you! 😄</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat chat-left">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user5} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:48</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Hey John, I am looking for the best admin template.</p>
                                                                    <p>Could you please help me to find it out? 🤔</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:49</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Absolutely!</p>
                                                                    <p>SocialV Dashboard is the responsive bootstrap 5 admin template.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat chat-left">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user5} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:52</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Looks clean and fresh UI.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:53</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Thanks, from ThemeForest.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat chat-left">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user5} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:54</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>I will purchase it for sure. 👍</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:56</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Okay Thanks..</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="chat-footer p-3 bg-white">
                                                        <Form className="d-flex align-items-center" action="#">
                                                            <div className="chat-attagement d-flex">
                                                                <Link to="#"><i className="far fa-smile pe-3" aria-hidden="true"></i></Link>
                                                                <Link to="#"><i className="fa fa-paperclip pe-3" aria-hidden="true"></i></Link>
                                                            </div>
                                                            <Form.Control type="text" className="me-3" placeholder="Type your message" />
                                                            <Button type="submit" variant="primary d-flex align-items-center"><i className="far fa-paper-plane" aria-hidden="true"></i><span className="d-none d-lg-block ms-1">Send</span></Button>
                                                        </Form>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="second" className={`fade ${show === 'second' ? 'show' : ''}`} id="chatbox2" role="tabpanel">
                                                    <div className="chat-head">
                                                        <header className="d-flex justify-content-between align-items-center bg-white pt-3  ps-3 pe-3 pb-3">
                                                            <div className="d-flex align-items-center">
                                                                <div className="sidebar-toggle">
                                                                    <i className="ri-menu-3-line"></i>
                                                                </div>
                                                                <div className="avatar chat-user-profile m-0 me-3">
                                                                    <img loading="lazy" src={user6} alt="avatar" className="avatar-50 " onClick={() => setShow2('true')} />
                                                                    <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-success"></i></span>
                                                                </div>
                                                                <h5 className="mb-0">Announcement</h5>
                                                            </div>
                                                            <div className={`chat-user-detail-popup scroller ${show2 === 'true' ? 'show' : ''}`} >
                                                                <div className="user-profile">
                                                                    <Button type="submit" onClick={ChatSidebarClose} variant=" close-popup p-3"><i className="material-symbols-outlined md-18" onClick={() => setShow2('false')}>close</i></Button>
                                                                    <div className="user mb-4 text-center">
                                                                        <Link className="avatar m-0" to="">
                                                                            <img loading="lazy" src={user6} alt="avatar" />
                                                                        </Link>
                                                                        <div className="user-name mt-4">
                                                                            <h4>Mark Jordan</h4>
                                                                        </div>
                                                                        <div className="user-desc">
                                                                            <p>Atlanta, USA</p>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div className="chatuser-detail text-left mt-4">
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Bni Name:</Col>
                                                                            <Col md="6" className="col-6  text-right">Mark</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Tel:</Col>
                                                                            <Col md="6" className="col-6  text-right">072 143 9920</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Date Of Birth:</Col>
                                                                            <Col md="6" className="col-6  text-right">July 12, 1989</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Gender:</Col>
                                                                            <Col md="6" className="col-6  text-right">Female</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Language:</Col>
                                                                            <Col md="6" className="col-6  text-right">Engliah</Col>
                                                                        </Row>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="chat-header-icons d-flex">
                                                                <Link to="#" className="chat-icon-phone bg-soft-primary d-flex justify-content-center align-items-center">
                                                                    <i className="material-symbols-outlined md-18">phone</i>
                                                                </Link>
                                                                <Link to="#" className="chat-icon-video bg-soft-primary d-flex justify-content-center align-items-center">
                                                                    <i className="material-symbols-outlined md-18">videocam</i>
                                                                </Link>
                                                                <Link to="#" className="chat-icon-delete bg-soft-primary d-flex justify-content-center align-items-center">
                                                                    <i className="material-symbols-outlined md-18">delete</i>
                                                                </Link>
                                                                <Dropdown className="bg-soft-primary d-flex justify-content-center align-items-center" as="span">
                                                                    <Dropdown.Toggle as={CustomToggle} variant="material-symbols-outlined cursor-pointer md-18 nav-hide-arrow pe-0 show">
                                                                        more_vert
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu className="dropdown-menu-right">
                                                                        <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">push_pin</i>Pin to top</Dropdown.Item>
                                                                        <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">delete_outline</i>Delete chat</Dropdown.Item>
                                                                        <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">watch_later</i>Block</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                        </header>
                                                    </div>
                                                    <div className="chat-content scroller">
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:45</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>How can we help? We're here for you! 😄</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat chat-left">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user6} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:48</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Hey John, I am looking for the best admin template.</p>
                                                                    <p>Could you please help me to find it out? 🤔</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:49</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Absolutely!</p>
                                                                    <p>SocialV Dashboard is the responsive bootstrap 5 admin template.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat chat-left">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user6} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:52</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Looks clean and fresh UI.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:53</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Thanks, from ThemeForest.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat chat-left">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user6} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:54</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>I will purchase it for sure. 👍</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:56</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Okay Thanks..</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="chat-footer p-3 bg-white">
                                                        <Form className="d-flex align-items-center" action="#">
                                                            <div className="chat-attagement d-flex">
                                                                <Link to="#"><i className="far fa-smile pe-3" aria-hidden="true"></i></Link>
                                                                <Link to="#"><i className="fa fa-paperclip pe-3" aria-hidden="true"></i></Link>
                                                            </div>
                                                            <Form.Control type="text" className="form-control me-3" placeholder="Type your message" />
                                                            <Button type="submit" className=" primary d-flex align-items-center"><i className="far fa-paper-plane" aria-hidden="true"></i><span className="d-none d-lg-block ms-1">Send</span></Button>
                                                        </Form>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="third" className={`fade ${show === 'third' ? 'show' : ''}`} id="chatbox3" role="tabpanel">
                                                    <div className="chat-head">
                                                        <header className="d-flex justify-content-between align-items-center bg-white pt-3 ps-3 pe-3 pb-3">
                                                            <div className="d-flex align-items-center">
                                                                <div className="sidebar-toggle">
                                                                    <i className="ri-menu-3-line"></i>
                                                                </div>
                                                                <div className="avatar chat-user-profile m-0 me-3">
                                                                    <img loading="lazy" src={user7} alt="avatar" className="avatar-50 " onClick={() => setShow2('true')} />
                                                                    <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-success"></i></span>
                                                                </div>
                                                                <h5 className="mb-0">Designer</h5>
                                                            </div>
                                                            <div className={`chat-user-detail-popup scroller ${show2 === 'true' ? 'show' : ''}`} >
                                                                <div className="user-profile ">
                                                                    <Button type="submit" onClick={ChatSidebarClose} variant=" close-popup p-3"><i className="material-symbols-outlined md-18" onClick={() => setShow2('false')}>close</i></Button>
                                                                    <div className="user text-center mb-4">
                                                                        <Link className="avatar m-0" to="">
                                                                            <img loading="lazy" src={user7} alt="avatar" />
                                                                        </Link>
                                                                        <div className="user-name mt-4">
                                                                            <h4>Paige Turner</h4>
                                                                        </div>
                                                                        <div className="user-desc">
                                                                            <p>Cape Town, RSA</p>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div className="chatuser-detail text-left mt-4">
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Bni Name:</Col>
                                                                            <Col md="6" className="col-6 text-right">pai</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Tel:</Col>
                                                                            <Col md="6" className="col-6 text-right">072 143 9920</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Date Of Birth:</Col>
                                                                            <Col md="6" className="col-6 text-right">July 12, 1989</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Gender:</Col>
                                                                            <Col md="6" className="col-6 text-right">Male</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Language:</Col>
                                                                            <Col md="6" className="col-6 text-right">Engliah</Col>
                                                                        </Row>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="chat-header-icons d-flex ">
                                                                <Link to="#" className="chat-icon-phone bg-soft-primary d-flex justify-content-center align-items-center">
                                                                    <i className="material-symbols-outlined md-18">phone</i>
                                                                </Link>
                                                                <Link to="#" className="chat-icon-video bg-soft-primary d-flex justify-content-center align-items-center">
                                                                    <i className="material-symbols-outlined md-18">videocam</i>
                                                                </Link>
                                                                <Link to="#" className="chat-icon-delete bg-soft-primary d-flex justify-content-center align-items-center">
                                                                    <i className="material-symbols-outlined md-18">delete</i>
                                                                </Link>
                                                                <Dropdown className="bg-soft-primary d-flex justify-content-center align-items-center" as="span">
                                                                    <Dropdown.Toggle as={CustomToggle} variant="material-symbols-outlined cursor-pointer md-18 nav-hide-arrow pe-0 show">
                                                                        more_vert
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu className="dropdown-menu-right">
                                                                        <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">push_pin</i>Pin to top</Dropdown.Item>
                                                                        <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">delete_outline</i>Delete chat</Dropdown.Item>
                                                                        <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">watch_later</i>Block</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                        </header>
                                                    </div>
                                                    <div className="chat-content scroller">
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:45</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>How can we help? We're here for you! 😄</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat chat-left">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user7} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:48</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Hey John, I am looking for the best admin template.</p>
                                                                    <p>Could you please help me to find it out? 🤔</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:49</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Absolutely!</p>
                                                                    <p>SocialV Dashboard is the responsive bootstrap 5 admin template.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat chat-left">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user7} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:52</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Looks clean and fresh UI.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:53</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Thanks, from ThemeForest.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat chat-left">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user7} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:54</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>I will purchase it for sure. 👍</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:56</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Okay Thanks..</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="chat-footer p-3 bg-white">
                                                        <Form className="d-flex align-items-center" action="#">
                                                            <div className="chat-attagement d-flex">
                                                                <Link to="#"><i className="far fa-smile pe-3" aria-hidden="true"></i></Link>
                                                                <Link to="#"><i className="fa fa-paperclip pe-3" aria-hidden="true"></i></Link>
                                                            </div>
                                                            <Form.Control type="text" className="form-control me-3" placeholder="Type your message" />
                                                            <Button type="submit" variant="primary d-flex align-items-center"><i className="far fa-paper-plane" aria-hidden="true"></i><span className="d-none d-lg-block ms-1">Send</span></Button>
                                                        </Form>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="forth" className={`fade ${show === 'forth' ? 'show' : ''}`} id="chatbox4" role="tabpanel">
                                                    <div className="chat-head">
                                                        <header className="d-flex justify-content-between align-items-center bg-white pt-3 ps-3 pe-3 pb-3">
                                                            <div className="d-flex align-items-center">
                                                                <div className="sidebar-toggle">
                                                                    <i className="ri-menu-3-line"></i>
                                                                </div>
                                                                <div className="avatar chat-user-profile m-0 me-3">
                                                                    <img loading="lazy" src={user8} alt="avatar" className="avatar-50 " onClick={() => setShow2('true')} />
                                                                    <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-success"></i></span>
                                                                </div>
                                                                <h5 className="mb-0">Developer</h5>
                                                            </div>
                                                            <div className={`chat-user-detail-popup scroller ${show2 === 'true' ? 'show' : ''}`}>
                                                                <div className="user-profile ">
                                                                    <Button type="submit" onClick={ChatSidebarClose} variant=" close-popup p-3"><i className="material-symbols-outlined md-18" onClick={() => setShow2('false')}>close</i></Button>
                                                                    <div className="user mb-4 text-center">
                                                                        <Link className="avatar m-0" to="">
                                                                            <img loading="lazy" src={user8} alt="avatar" />
                                                                        </Link>
                                                                        <div className="user-name mt-4">
                                                                            <h4>Barb Ackue</h4>
                                                                        </div>
                                                                        <div className="user-desc">
                                                                            <p>Cape Town, RSA</p>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div className="chatuser-detail text-left mt-4">
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Bni Name:</Col>
                                                                            <Col md="6" className="col-6 text-right">Babe</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Tel:</Col>
                                                                            <Col md="6" className="col-6 text-right">072 143 9920</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Date Of Birth:</Col>
                                                                            <Col md="6" className="col-6 text-right">July 12, 1989</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Gender:</Col>
                                                                            <Col md="6" className="col-6 text-right">Feale</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Language:</Col>
                                                                            <Col md="6" className="col-6 text-right">Engliah</Col>
                                                                        </Row>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="chat-header-icons d-flex">
                                                                <Link to="#" className="chat-icon-phone bg-soft-primary d-flex justify-content-center align-items-center">
                                                                    <i className="material-symbols-outlined md-18">phone</i>
                                                                </Link>
                                                                <Link to="#" className="chat-icon-video bg-soft-primary d-flex justify-content-center align-items-center">
                                                                    <i className="material-symbols-outlined md-18">videocam</i>
                                                                </Link>
                                                                <Link to="#" className="chat-icon-delete bg-soft-primary d-flex justify-content-center align-items-center">
                                                                    <i className="material-symbols-outlined md-18">delete</i>
                                                                </Link>
                                                                <Dropdown className="bg-soft-primary d-flex justify-content-center align-items-center" as="span">
                                                                    <Dropdown.Toggle as={CustomToggle} variant="material-symbols-outlined cursor-pointer md-18 nav-hide-arrow pe-0 show">
                                                                        more_vert
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu className="dropdown-menu-right">
                                                                        <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">push_pin</i>Pin to top</Dropdown.Item>
                                                                        <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">delete_outline</i>Delete chat</Dropdown.Item>
                                                                        <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">watch_later</i>Block</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                        </header>
                                                    </div>
                                                    <div className="chat-content scroller">
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:45</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>How can we help? We're here for you! 😄</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat chat-left">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user8} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:48</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Hey John, I am looking for the best admin template.</p>
                                                                    <p>Could you please help me to find it out? 🤔</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:49</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Absolutely!</p>
                                                                    <p>SocialV Dashboard is the responsive bootstrap 5 admin template.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat chat-left">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user8} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:52</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Looks clean and fresh UI.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:53</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Thanks, from ThemeForest.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat chat-left">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user8} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:54</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>I will purchase it for sure. 👍</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:56</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Okay Thanks..</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="chat-footer p-3 bg-white">
                                                        <Form className="d-flex align-items-center" action="#">
                                                            <div className="chat-attagement d-flex">
                                                                <Link to="#"><i className="far fa-smile pe-3" aria-hidden="true"></i></Link>
                                                                <Link to="#"><i className="fa fa-paperclip pe-3" aria-hidden="true"></i></Link>
                                                            </div>
                                                            <Form.Control type="text" className="form-control me-3" placeholder="Type your message" />
                                                            <Button type="submit" variant="primary d-flex align-items-center"><i className="far fa-paper-plane" aria-hidden="true"></i><span className="d-none d-lg-block ms-1">Send</span></Button>
                                                        </Form>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="five" className={`fade ${show === 'five' ? 'show' : ''}`} id="chatbox5" role="tabpanel">
                                                    <div className="chat-head">
                                                        <header className="d-flex justify-content-between align-items-center bg-white pt-3 ps-3 pe-3 pb-3">
                                                            <div className="d-flex align-items-center">
                                                                <div className="sidebar-toggle">
                                                                    <i className="ri-menu-3-line"></i>
                                                                </div>
                                                                <div className="avatar chat-user-profile m-0 me-3">
                                                                    <img loading="lazy" src={user9} alt="avatar" className="avatar-50 " />
                                                                    <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-success"></i></span>
                                                                </div>
                                                                <h5 className="mb-0">Testing Team</h5>
                                                            </div>
                                                            <div className={`chat-user-detail-popup scroller ${show2 === 'true' ? 'show' : ''}`}>
                                                                <div className="user-profile ">
                                                                    <Button type="submit" onClick={ChatSidebarClose} variant=" close-popup p-3"><i className="material-symbols-outlined md-18" onClick={() => setShow2('false')}>close</i></Button>
                                                                    <div className="user mb-4 text-center">
                                                                        <Link className="avatar m-0" to="">
                                                                            <img loading="lazy" src={user9} alt="avatar" />
                                                                        </Link>
                                                                        <div className="user-name mt-4">
                                                                            <h4>Peta Saireya</h4>
                                                                        </div>
                                                                        <div className="user-desc">
                                                                            <p>Cape Town, RSA</p>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div className="chatuser-detail text-left mt-4">
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Bni Name:</Col>
                                                                            <Col md="6" className="col-6 text-right">Pet</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Tel:</Col>
                                                                            <Col md="6" className="col-6 text-right">072 143 9920</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Date Of Birth:</Col>
                                                                            <Col md="6" className="col-6 text-right">July 12, 1989</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Gender:</Col>
                                                                            <Col md="6" className="col-6 text-right">Female</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Language:</Col>
                                                                            <Col md="6" className="col-6 text-right">Engliah</Col>
                                                                        </Row>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="chat-header-icons d-flex">
                                                                <Link to="#" className="chat-icon-phone bg-soft-primary d-flex justify-content-center align-items-center">
                                                                    <i className="material-symbols-outlined md-18">phone</i>
                                                                </Link>
                                                                <Link to="#" className="chat-icon-video bg-soft-primary d-flex justify-content-center align-items-center">
                                                                    <i className="material-symbols-outlined md-18">videocam</i>
                                                                </Link>
                                                                <Link to="#" className="chat-icon-delete bg-soft-primary d-flex justify-content-center align-items-center">
                                                                    <i className="material-symbols-outlined md-18">delete</i>
                                                                </Link>
                                                                <Dropdown className="bg-soft-primary d-flex justify-content-center align-items-center" as="span">
                                                                    <Dropdown.Toggle as={CustomToggle} variant="material-symbols-outlined cursor-pointer md-18 nav-hide-arrow pe-0 show">
                                                                        more_vert
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu className="dropdown-menu-right">
                                                                        <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">push_pin</i>Pin to top</Dropdown.Item>
                                                                        <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">delete_outline</i>Delete chat</Dropdown.Item>
                                                                        <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">watch_later</i>Block</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                        </header>
                                                    </div>
                                                    <div className="chat-content scroller">
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:45</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>How can we help? We're here for you! 😄</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat chat-left">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user9} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:48</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Hey John, I am looking for the best admin template.</p>
                                                                    <p>Could you please help me to find it out? 🤔</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:49</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Absolutely!</p>
                                                                    <p>SocialV Dashboard is the responsive bootstrap 5 admin template.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat chat-left">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user9} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:52</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Looks clean and fresh UI.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:53</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Thanks, from ThemeForest.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat chat-left">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user9} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:54</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>I will purchase it for sure. 👍</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:56</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Okay Thanks..</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="chat-footer p-3 bg-white">
                                                        <Form className="d-flex align-items-center" action="#">
                                                            <div className="chat-attagement d-flex">
                                                                <Link to="#"><i className="far fa-smile pe-3" aria-hidden="true"></i></Link>
                                                                <Link to="#"><i className="fa fa-paperclip pe-3" aria-hidden="true"></i></Link>
                                                            </div>
                                                            <Form.Control type="text" className="form-control me-3" placeholder="Type your message" />
                                                            <Button type="submit" className=" primary d-flex align-items-center"><i className="far fa-paper-plane" aria-hidden="true"></i><span className="d-none d-lg-block ms-1">Send</span></Button>
                                                        </Form>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="six" className={`fade ${show === 'six' ? 'show' : ''}`} id="chatbox6" role="tabpanel">
                                                    <div className="chat-head">
                                                        <header className="d-flex justify-content-between align-items-center bg-white pt-3 ps-3 pe-3 pb-3">
                                                            <div className="d-flex align-items-center">
                                                                <div className="sidebar-toggle">
                                                                    <i className="ri-menu-3-line"></i>
                                                                </div>
                                                                <div className="avatar chat-user-profile m-0 me-3">
                                                                    <img loading="lazy" src={user10} alt="avatar" className="avatar-50 " onClick={() => setShow2('true')} />
                                                                    <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-success"></i></span>
                                                                </div>
                                                                <h5 className="mb-0">Paul Molive</h5>
                                                            </div>
                                                            <div className={`chat-user-detail-popup scroller ${show2 === 'true' ? 'show' : ''}`}>
                                                                <div className="user-profile ">
                                                                    <Button type="submit" onClick={ChatSidebarClose} variant=" close-popup p-3"><i className="material-symbols-outlined md-18" onClick={() => setShow2('false')}>close</i></Button>
                                                                    <div className="user mb-4 text-center">
                                                                        <Link className="avatar m-0" to="">
                                                                            <img loading="lazy" src={user10} alt="avatar" />
                                                                        </Link>
                                                                        <div className="user-name mt-4">
                                                                            <h4>Paul Molive</h4>
                                                                        </div>
                                                                        <div className="user-desc">
                                                                            <p>Cape Town, RSA</p>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div className="chatuser-detail text-left mt-4">
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Bni Name:</Col>
                                                                            <Col md="6" className="col-6 text-right">Pau</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Tel:</Col>
                                                                            <Col md="6" className="col-6 text-right">072 143 9920</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Date Of Birth:</Col>
                                                                            <Col md="6" className="col-6 text-right">July 12, 1989</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Gender:</Col>
                                                                            <Col md="6" className="col-6 text-right">Male</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Language:</Col>
                                                                            <Col md="6" className="col-6 text-right">Engliah</Col>
                                                                        </Row>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="chat-header-icons d-flex">
                                                                <Link to="#" className="chat-icon-phone  bg-soft-primary d-flex justify-content-center align-items-center">
                                                                    <i className="material-symbols-outlined md-18">phone</i>
                                                                </Link>
                                                                <Link to="#" className="chat-icon-video  bg-soft-primary d-flex justify-content-center align-items-center">
                                                                    <i className="material-symbols-outlined md-18">videocam</i>
                                                                </Link>
                                                                <Link to="#" className="chat-icon-delete  bg-soft-primary d-flex justify-content-center align-items-center">
                                                                    <i className="material-symbols-outlined md-18">delete</i>
                                                                </Link>
                                                                <Dropdown className="bg-soft-primary d-flex justify-content-center align-items-center" as="span">
                                                                    <Dropdown.Toggle as={CustomToggle} variant="material-symbols-outlined cursor-pointer md-18 nav-hide-arrow pe-0 show">
                                                                        more_vert
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu className="dropdown-menu-right">
                                                                        <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">push_pin</i>Pin to top</Dropdown.Item>
                                                                        <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">delete_outline</i>Delete chat</Dropdown.Item>
                                                                        <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">watch_later</i>Block</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                        </header>
                                                    </div>
                                                    <div className="chat-content scroller">
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:45</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>How can we help? We're here for you! 😄</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat chat-left">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user10} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:48</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Hey John, I am looking for the best admin template.</p>
                                                                    <p>Could you please help me to find it out? 🤔</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:49</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Absolutely!</p>
                                                                    <p>SocialV Dashboard is the responsive bootstrap 5 admin template.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat chat-left">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user10} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:52</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Looks clean and fresh UI.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:53</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Thanks, from ThemeForest.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat chat-left">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user10} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:54</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>I will purchase it for sure. 👍</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:56</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Okay Thanks..</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="chat-footer p-3 bg-white">
                                                        <Form className="d-flex align-items-center" action="#">
                                                            <div className="chat-attagement d-flex">
                                                                <Link to="#"><i className="far fa-smile pe-3" aria-hidden="true"></i></Link>
                                                                <Link to="#"><i className="fa fa-paperclip pe-3" aria-hidden="true"></i></Link>
                                                            </div>
                                                            <Form.Control type="text" className="form-control me-3" placeholder="Type your message" />
                                                            <Button type="submit" variant="primary d-flex align-items-center"><i className="far fa-paper-plane" aria-hidden="true"></i><span className="d-none d-lg-block ms-1">Send</span></Button>
                                                        </Form>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="seven" className={`fade ${show === 'seven' ? 'show' : ''}`} id="chatbox7" role="tabpanel">
                                                    <div className="chat-head">
                                                        <header className="d-flex justify-content-between align-items-center bg-white pt-3 ps-3 pe-3 pb-3">
                                                            <div className="d-flex align-items-center">
                                                                <div className="sidebar-toggle">
                                                                    <i className="ri-menu-3-line"></i>
                                                                </div>
                                                                <div className="avatar chat-user-profile m-0 me-3">
                                                                    <img loading="lazy" src={user5} alt="avatar" className="avatar-50 " onClick={() => setShow2('true')} />
                                                                    <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-success"></i></span>
                                                                </div>
                                                                <h5 className="mb-0">Paige Turner</h5>
                                                            </div>
                                                            <div className={`chat-user-detail-popup scroller ${show2 === 'true' ? 'show' : ''}`}>
                                                                <div className="user-profile ">
                                                                    <Button type="submit" onClick={ChatSidebarClose} variant=" close-popup p-3"><i className="material-symbols-outlined md-18" onClick={() => setShow2('false')}>close</i></Button>
                                                                    <div className="user text-center mb-4">
                                                                        <Link className="avatar m-0" to="">
                                                                            <img loading="lazy" src={user5} alt="avatar" />
                                                                        </Link>
                                                                        <div className="user-name mt-4">
                                                                            <h4>Paige Turner</h4>
                                                                        </div>
                                                                        <div className="user-desc">
                                                                            <p>Cape Town, RSA</p>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div className="chatuser-detail text-left mt-4">
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Bni Name:</Col>
                                                                            <Col md="6" className="col-6 text-right">Pai</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Tel:</Col>
                                                                            <Col md="6" className="col-6 text-right">072 143 9920</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Date Of Birth:</Col>
                                                                            <Col md="6" className="col-6 text-right">July 12, 1989</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Gender:</Col>
                                                                            <Col md="6" className="col-6 text-right">Feale</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Language:</Col>
                                                                            <Col md="6" className="col-6 text-right">Engliah</Col>
                                                                        </Row>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="chat-header-icons d-flex">
                                                                <Link to="#" className="chat-icon-phone bg-soft-primary d-flex justify-content-center align-items-center">
                                                                    <i className="material-symbols-outlined md-18">phone</i>
                                                                </Link>
                                                                <Link to="#" className="chat-icon-video bg-soft-primary d-flex justify-content-center align-items-center">
                                                                    <i className="material-symbols-outlined md-18">videocam</i>
                                                                </Link>
                                                                <Link to="#" className="chat-icon-delete bg-soft-primary d-flex justify-content-center align-items-center">
                                                                    <i className="material-symbols-outlined md-18">delete</i>
                                                                </Link>
                                                                <Dropdown className="bg-soft-primary d-flex justify-content-center align-items-center" as="span">
                                                                    <Dropdown.Toggle as={CustomToggle} variant="material-symbols-outlined cursor-pointer md-18 nav-hide-arrow pe-0 show">
                                                                        more_vert
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu className="dropdown-menu-right">
                                                                        <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">push_pin</i>Pin to top</Dropdown.Item>
                                                                        <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">delete_outline</i>Delete chat</Dropdown.Item>
                                                                        <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">watch_later</i>Block</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                        </header>
                                                    </div>
                                                    <div className="chat-content scroller">
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:45</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>How can we help? We're here for you! 😄</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat chat-left">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user5} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:48</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Hey John, I am looking for the best admin template.</p>
                                                                    <p>Could you please help me to find it out? 🤔</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:49</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Absolutely!</p>
                                                                    <p>SocialV Dashboard is the responsive bootstrap 5 admin template.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat chat-left">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user5} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:52</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Looks clean and fresh UI.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:53</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Thanks, from ThemeForest.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat chat-left">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user5} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:54</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>I will purchase it for sure. 👍</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:56</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Okay Thanks..</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="chat-footer p-3 bg-white">
                                                        <Form className="d-flex align-items-center" action="#">
                                                            <div className="chat-attagement d-flex">
                                                                <Link to="#"><i className="far fa-smile pe-3" aria-hidden="true"></i></Link>
                                                                <Link to="#"><i className="fa fa-paperclip pe-3" aria-hidden="true"></i></Link>
                                                            </div>
                                                            <Form.Control type="text" className="form-control me-3" placeholder="Type your message" />
                                                            <Button type="submit" className=" primary d-flex align-items-center"><i className="far fa-paper-plane" aria-hidden="true"></i><span className="d-none d-lg-block ms-1">Send</span></Button>
                                                        </Form>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="eight" className={`fade ${show === 'eight' ? 'show' : ''}`} id="chatbox8" role="tabpanel">
                                                    <div className="chat-head">
                                                        <header className="d-flex justify-content-between align-items-center bg-white pt-3 ps-3 pe-3 pb-3">
                                                            <div className="d-flex align-items-center">
                                                                <div className="sidebar-toggle">
                                                                    <i className="ri-menu-3-line"></i>
                                                                </div>
                                                                <div className="avatar chat-user-profile m-0 me-3">
                                                                    <img loading="lazy" src={user6} alt="avatar" className="avatar-50 " onClick={() => setShow2('true')} />
                                                                    <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-success"></i></span>
                                                                </div>
                                                                <h5 className="mb-0">Barb Ackue</h5>
                                                            </div>
                                                            <div className={`chat-user-detail-popup scroller ${show2 === 'true' ? 'show' : ''}`}>
                                                                <div className="user-profile">
                                                                    <Button type="submit" onClick={ChatSidebarClose} variant=" close-popup p-3"><i className="material-symbols-outlined md-18" onClick={() => setShow2('false')}>close</i></Button>
                                                                    <div className="user text-center mb-4">
                                                                        <Link className="avatar m-0" to="">
                                                                            <img loading="lazy" src={user6} alt="avatar" />
                                                                        </Link>
                                                                        <div className="user-name mt-4">
                                                                            <h4>Barb Ackue</h4>
                                                                        </div>
                                                                        <div className="user-desc">
                                                                            <p>Cape Town, RSA</p>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div className="chatuser-detail text-left mt-4">
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Bni Name:</Col>
                                                                            <Col md="6" className="col-6 text-right">Babe</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Tel:</Col>
                                                                            <Col md="6" className="col-6 text-right">072 143 9920</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Date Of Birth:</Col>
                                                                            <Col md="6" className="col-6 text-right">July 12, 1989</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Gender:</Col>
                                                                            <Col md="6" className="col-6 text-right">Female</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Language:</Col>
                                                                            <Col md="6" className="col-6 text-right">Engliah</Col>
                                                                        </Row>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="chat-header-icons d-flex">
                                                                <Link to="#" className="chat-icon-phone bg-soft-primary d-flex justify-content-center align-items-center">
                                                                    <i className="material-symbols-outlined md-18">phone</i>
                                                                </Link>
                                                                <Link to="#" className="chat-icon-video bg-soft-primary d-flex justify-content-center align-items-center">
                                                                    <i className="material-symbols-outlined md-18">videocam</i>
                                                                </Link>
                                                                <Link to="#" className="chat-icon-delete bg-soft-primary d-flex justify-content-center align-items-center">
                                                                    <i className="material-symbols-outlined md-18">delete</i>
                                                                </Link>
                                                                <Dropdown className="bg-soft-primary d-flex justify-content-center align-items-center" as="span">
                                                                    <Dropdown.Toggle as={CustomToggle} variant="material-symbols-outlined cursor-pointer md-18 nav-hide-arrow pe-0 show">
                                                                        more_vert
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu className="dropdown-menu-right">
                                                                        <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">push_pin</i>Pin to top</Dropdown.Item>
                                                                        <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">delete_outline</i>Delete chat</Dropdown.Item>
                                                                        <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">watch_later</i>Block</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                        </header>
                                                    </div>
                                                    <div className="chat-content scroller">
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:45</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>How can we help? We're here for you! 😄</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat chat-left">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user6} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:48</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Hey John, I am looking for the best admin template.</p>
                                                                    <p>Could you please help me to find it out? 🤔</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:49</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Absolutely!</p>
                                                                    <p>SocialV Dashboard is the responsive bootstrap 5 admin template.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat chat-left">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user6} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:52</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Looks clean and fresh UI.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:53</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Thanks, from ThemeForest.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat chat-left">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user6} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:54</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>I will purchase it for sure. 👍</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:56</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Okay Thanks..</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="chat-footer p-3 bg-white">
                                                        <Form className="d-flex align-items-center" action="#">
                                                            <div className="chat-attagement d-flex">
                                                                <Link to="#"><i className="far fa-smile pe-3" aria-hidden="true"></i></Link>
                                                                <Link to="#"><i className="fa fa-paperclip pe-3" aria-hidden="true"></i></Link>
                                                            </div>
                                                            <Form.Control type="text" className="form-control me-3" placeholder="Type your message" />
                                                            <Button type="submit" variant="primary d-flex align-items-center"><i className="far fa-paper-plane" aria-hidden="true"></i><span className="d-none d-lg-block ms-1">Send</span></Button>
                                                        </Form>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="nine" className={`fade ${show === 'nine' ? 'show' : ''}`} id="chatbox9" role="tabpanel">
                                                    <div className="chat-head">
                                                        <header className="d-flex justify-content-between align-items-center bg-white pt-3 ps-3 pe-3 pb-3">
                                                            <div className="d-flex align-items-center">
                                                                <div className="sidebar-toggle">
                                                                    <i className="ri-menu-3-line"></i>
                                                                </div>
                                                                <div className="avatar chat-user-profile m-0 me-3">
                                                                    <img loading="lazy" src={user7} alt="avatar" className="avatar-50 " onClick={() => setShow2('true')} />
                                                                    <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-success"></i></span>
                                                                </div>
                                                                <h5 className="mb-0">Maya Didas</h5>
                                                            </div>
                                                            <div className={`chat-user-detail-popup scroller ${show2 === 'true' ? 'show' : ''}`}>
                                                                <div className="user-profile ">
                                                                    <Button type="submit" onClick={ChatSidebarClose} variant=" close-popup p-3"><i className="material-symbols-outlined md-18" onClick={() => setShow2('false')}>close</i></Button>
                                                                    <div className="user text-center mb-4">
                                                                        <Link className="avatar m-0" to="">
                                                                            <img loading="lazy" src={user7} alt="avatar" />
                                                                        </Link>
                                                                        <div className="user-name mt-4">
                                                                            <h4>Maya Didas</h4>
                                                                        </div>
                                                                        <div className="user-desc">
                                                                            <p>Cape Town, RSA</p>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div className="chatuser-detail text-left mt-4">
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Bni Name:</Col>
                                                                            <Col md="6" className="col-6 text-right">Babe</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Tel:</Col>
                                                                            <Col md="6" className="col-6 text-right">072 143 9920</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Date Of Birth:</Col>
                                                                            <Col md="6" className="col-6 text-right">July 12, 1989</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Gender:</Col>
                                                                            <Col md="6" className="col-6 text-right">Male</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Language:</Col>
                                                                            <Col md="6" className="col-6 text-right">Engliah</Col>
                                                                        </Row>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="chat-header-icons d-flex">
                                                                <Link to="#" className="chat-icon-phone bg-soft-primary d-flex justify-content-center align-items-center">
                                                                    <i className="material-symbols-outlined md-18">phone</i>
                                                                </Link>
                                                                <Link to="#" className="chat-icon-video bg-soft-primary d-flex justify-content-center align-items-center">
                                                                    <i className="material-symbols-outlined md-18">videocam</i>
                                                                </Link>
                                                                <Link to="#" className="chat-icon-delete bg-soft-primary d-flex justify-content-center align-items-center">
                                                                    <i className="material-symbols-outlined md-18">delete</i>
                                                                </Link>
                                                                <Dropdown className="bg-soft-primary d-flex justify-content-center align-items-center" as="span">
                                                                    <Dropdown.Toggle as={CustomToggle} variant="material-symbols-outlined cursor-pointer md-18 nav-hide-arrow pe-0 show">
                                                                        more_vert
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu className="dropdown-menu-right">
                                                                        <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">push_pin</i>Pin to top</Dropdown.Item>
                                                                        <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">delete_outline</i>Delete chat</Dropdown.Item>
                                                                        <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">watch_later</i>Block</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                        </header>
                                                    </div>
                                                    <div className="chat-content scroller">
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:45</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>How can we help? We're here for you! 😄</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat chat-left">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user7} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:48</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Hey John, I am looking for the best admin template.</p>
                                                                    <p>Could you please help me to find it out? 🤔</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:49</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Absolutely!</p>
                                                                    <p>SocialV Dashboard is the responsive bootstrap 5 admin template.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat chat-left">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user7} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:52</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Looks clean and fresh UI.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:53</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Thanks, from ThemeForest.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat chat-left">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user7} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:54</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>I will purchase it for sure. 👍</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:56</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Okay Thanks..</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="chat-footer p-3 bg-white">
                                                        <Form className="d-flex align-items-center" action="#">
                                                            <div className="chat-attagement d-flex">
                                                                <Link to="#"><i className="far fa-smile pe-3" aria-hidden="true"></i></Link>
                                                                <Link to="#"><i className="fa fa-paperclip pe-3" aria-hidden="true"></i></Link>
                                                            </div>
                                                            <Form.Control type="text" className="form-control me-3" placeholder="Type your message" />
                                                            <Button type="submit" variant="primary d-flex align-items-center"><i className="far fa-paper-plane" aria-hidden="true"></i><span className="d-none d-lg-block ms-1">Send</span></Button>
                                                        </Form>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="ten" id="chatbox10" role="tabpanel">
                                                    <div className="chat-head">
                                                        <header className="d-flex justify-content-between align-items-center bg-white pt-3 ps-3 pe-3 pb-3">
                                                            <div className="d-flex align-items-center">
                                                                <div className="sidebar-toggle">
                                                                    <i className="ri-menu-3-line"></i>
                                                                </div>
                                                                <div className="avatar chat-user-profile m-0 me-3">
                                                                    <img loading="lazy" src={user8} alt="avatar" className="avatar-50 " onClick={() => setShow2('true')} />
                                                                    <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-success"></i></span>
                                                                </div>
                                                                <h5 className="mb-0">Monty Carlo</h5>
                                                            </div>
                                                            <div className={`chat-user-detail-popup scroller ${show2 === 'true' ? 'show' : ''}`}>
                                                                <div className="user-profile ">
                                                                    <Button type="submit" onClick={ChatSidebarClose} variant=" close-popup p-3"><i className="material-symbols-outlined md-18" onClick={() => setShow2('false')}>close</i></Button>
                                                                    <div className="text-center user mb-4">
                                                                        <Link className="avatar m-0" to="">
                                                                            <img loading="lazy" src={user8} alt="avatar" />
                                                                        </Link>
                                                                        <div className="user-name mt-4">
                                                                            <h4>Monty Carlo</h4>
                                                                        </div>
                                                                        <div className="user-desc">
                                                                            <p>Cape Town, RSA</p>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div className="chatuser-detail text-left mt-4">
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Bni Name:</Col>
                                                                            <Col md="6" className="col-6 text-right">Babe</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Tel:</Col>
                                                                            <Col md="6" className="col-6 text-right">072 143 9920</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Date Of Birth:</Col>
                                                                            <Col md="6" className="col-6 text-right">July 12, 1989</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Gender:</Col>
                                                                            <Col md="6" className="col-6 text-right">Female</Col>
                                                                        </Row>
                                                                        <hr />
                                                                        <Row>
                                                                            <Col md="6" className="col-6 title">Language:</Col>
                                                                            <Col md="6" className="col-6 text-right">Engliah</Col>
                                                                        </Row>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="chat-header-icons d-flex">
                                                                <Link to="#" className="chat-icon-phone bg-soft-primary d-flex justify-content-center align-items-center">
                                                                    <i className="material-symbols-outlined md-18">phone</i>
                                                                </Link>
                                                                <Link to="#" className="chat-icon-video bg-soft-primary d-flex justify-content-center align-items-center">
                                                                    <i className="material-symbols-outlined md-18">videocam</i>
                                                                </Link>
                                                                <Link to="#" className="chat-icon-delete bg-soft-primary d-flex justify-content-center align-items-center">
                                                                    <i className="material-symbols-outlined md-18">delete</i>
                                                                </Link>
                                                                <Dropdown className="bg-soft-primary d-flex justify-content-center align-items-center" as="span">
                                                                    <Dropdown.Toggle as={CustomToggle} variant="material-symbols-outlined cursor-pointer md-18 nav-hide-arrow pe-0 show">
                                                                        more_vert
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu className="dropdown-menu-right">
                                                                        <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">push_pin</i>Pin to top</Dropdown.Item>
                                                                        <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">delete_outline</i>Delete chat</Dropdown.Item>
                                                                        <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">watch_later</i>Block</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                        </header>
                                                    </div>
                                                    <div className="chat-content scroller">
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:45</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>How can we help? We're here for you! 😄</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat chat-left">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user8} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:48</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Hey John, I am looking for the best admin template.</p>
                                                                    <p>Could you please help me to find it out? 🤔</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:49</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Absolutely!</p>
                                                                    <p>SocialV Dashboard is the responsive bootstrap 5 admin template.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat chat-left">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user8} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:52</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Looks clean and fresh UI.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:53</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Thanks, from ThemeForest.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat chat-left">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user8} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:54</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>I will purchase it for sure. 👍</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={user1} alt="avatar" className="avatar-35 " />
                                                                </Link>
                                                                <span className="chat-time mt-1">6:56</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Okay Thanks..</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="chat-footer p-3 bg-white">
                                                        <Form className="d-flex align-items-center" action="#">
                                                            <div className="chat-attagement d-flex">
                                                                <Link to="#"><i className="far fa-smile pe-3" aria-hidden="true"></i></Link>
                                                                <Link to="#"><i className="fa fa-paperclip pe-3" aria-hidden="true"></i></Link>
                                                            </div>
                                                            <Form.Control type="text" className="form-control me-3" placeholder="Type your message" 
                                                            value={message} onChange={(e) => setMessage(e.target.value)}/>
                                                            <Button type="button" onClick={()=> sendMessage()} variant="primary d-flex align-items-center"><i className="far fa-paper-plane" aria-hidden="true"></i><span className="d-none d-lg-block ms-1">Send</span></Button>
                                                        </Form>
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content> */}
                                        </Col>
                                    </Row>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Tab.Container >
        </>
    )
}
export default Chat;    