import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Image, Tab, OverlayTrigger, Tooltip, Button, Modal, ModalFooter } from 'react-bootstrap'
import Card from '../Card'
import { Link, useNavigate, Route } from 'react-router-dom'
import Moment from 'moment';
import { apiHandler } from '../../api/apihandler';
import { AD_LIST, AD_CLICK, AD_VIEW } from '../../api/constants'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { InView } from "react-intersection-observer";
import Swal from 'sweetalert2'

import img42 from '../../assets/images/page-img/42.png'
import img9 from '../../assets/images/small/img-1.jpg'
import img10 from '../../assets/images/small/img-2.jpg'


const RightSideAdsCard = () => {

    const navigate = useNavigate();
    const [adsList, setAdsList] = useState([]);
    const [adspage, setAdsPage] = useState(1);
    const [adsMaxPage, setAdsMaxPage] = useState(1);

    useEffect(() => {
        setAdsList([])
        getRightAdsList(1)
    }, []);

    async function getAdView(adId) {
        try {
            const res = await apiHandler(AD_VIEW, "POST", {
                ad_id: adId,
                device_type: 0,
            })
            // if (res.data?.response?.status === 200) {

            // }
        } catch (error) {
            console.error(error);
        }
    }

    async function getAdClick(adId) {
        try {
            const res = await apiHandler(AD_CLICK, "POST", {
                ad_id: adId,
                device_type: 0,
            })
            // if (res.data?.response?.status === 200) {

            // }
        } catch (error) {
            console.error(error);
        }
    }

    const loadMore = (inView) => {
        if (inView) {
            if (adspage < adsMaxPage) {
                getRightAdsList(adspage + 1)
                //console.log("scroll working...", page + 1)
                setAdsPage(adspage + 1)
            } else {
                //refreshApi()
            }
        }
    };

    function refreshApi() {
        setAdsPage(1)
        getRightAdsList(1)
    }

    async function getRightAdsList(pageVal) {
        setAdsPage(pageVal)
        try {
            const res = await apiHandler(AD_LIST, "POST", {
                page: pageVal,
                limit: 1,
                position: "1"
            })
            if (res.data?.response?.status === 200) {
                setAdsMaxPage(res?.data?.data?.userPosts?.meta?.last_page)
                if (res?.data?.data?.userPosts?.meta?.total > 0) {
                    // if (pageVal === 1) {
                    //     setAdsList(res?.data?.data?.userPosts?.data)
                    // } else {
                    setAdsList(adsList.concat(res?.data?.data?.userPosts?.data))
                    getAdView(res?.data?.data?.userPosts?.data[0]?.id)
                    // }
                }
            }
        } catch (error) {
            console.error(error);
        }
    }


    return (
        <>
            {
                adsList?.length > 0 ? adsList?.map((elm, i) => (
                    <Card>
                        <Card.Body>
                            <div className="d-flex align-items-center mb-3">
                                {/* <img src={img42} alt="story-img" className="rounded-circle img-fluid avatar-50" /> */}
                                <div className="stories-data ms-3">
                                    <h5>{elm?.user_post_ad?.title}</h5>
                                    <p className="mb-0">{elm?.content}</p>
                                </div>
                            </div>
                            <Link to={elm?.user_post_ad?.ad_link} target='_blank noopener,noreferrer' onClick={() => getAdClick(elm?.id)}>
                                <ResponsiveMasonry
                                    columnsCountBreakPoints={{ 350: 1, 750: 1, 900: 1 }}>
                                    <Masonry columnsCount={3} gutter="10px">
                                        {elm?.user_post_images.map((image, i) => (
                                            // image?.media_type === 1 ?
                                            //     <div key={image?.id}>
                                            //         <div className="img-fluid" style={{ position: "relative" }} >
                                            //             <img loading="lazy"
                                            //                 src={image?.thumb} alt="gallary" className="img-fluid" />
                                            //         </div>
                                            //         <Link style={{ position: "absolute", left: "47%", top: "47%", zIndex: 1 }}>
                                            //             <img style={{ height: 50, width: 50 }} src={playButton} />
                                            //         </Link>
                                            //     </div> :
                                            <img key={image?.id} loading="lazy" src={image?.image_path}
                                                alt="post" className="img-fluid w-100" />
                                        ))}
                                    </Masonry>
                                </ResponsiveMasonry>
                            </Link>
                            {
                                elm?.user_post_ad?.description ?
                                    <div className="mt-1">
                                        <h6>{elm?.user_post_ad?.description}</h6>
                                    </div> : null
                            }
                            <div className="mt-1 d-flex justify-content-center">
                                <Link to={elm?.user_post_ad?.ad_link} target='_blank noopener,noreferrer' onClick={() => getAdClick(elm?.id)}>
                                    <button type="submit" className="btn btn-primary d-block w-20">
                                        {elm?.user_post_ad?.button_text}</button>
                                </Link>
                            </div>
                        </Card.Body>
                    </Card>
                )) : null
            }
            {/* {
                showMoreDataLoader ?
                    <div className="col-sm-12 text-center">
                        <img src={loader} alt="loader" style={{ height: "100px" }} />
                    </div> : null
            } */}
            <InView as="div" onChange={(inView, entry) => loadMore(inView)} />
        </>
    )
}

export default RightSideAdsCard