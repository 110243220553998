import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Nav, Tab, OverlayTrigger, Tooltip, Button, Modal } from 'react-bootstrap'
import Card from '../Card'
import CustomToggle from '../dropdowns'
import ShareOffcanvas from '../share-offcanvas'
import { Link, useNavigate, Route } from 'react-router-dom'
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { apiHandler } from '../../api/apihandler';
import { COMMENT_LIST, COMMENT_POST, DELETE_COMMENT, FEEDS_LIST, FEED_DELETE, FEED_MEDIA_POST, FEED_POST, FOLLOWER_LIST, FOLLOWING_LIST, PROFILE_DETAILS } from "../../api/constants";
import Moment from 'moment';
import ReactFsLightbox from 'fslightbox-react';
import Swal from 'sweetalert2'
import EmojiPicker from 'emoji-picker-react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import cross from '../../assets/images/icon/cross.png'
import video from '../../assets/images/small/video.png'
import SingleUserComment from './singleUserComment'
import CommentBox from './commentBox'


const CommentList = ({ postId, refreshCommentList, setRefreshCommentList }) => {
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const { addToast } = useToasts();
    const [commentList, setCommentList] = useState([]);

    useEffect(() => {
        getCommentList()
    }, [])

    useEffect(() => {
        getCommentList()
        setRefreshCommentList(false)
    }, [refreshCommentList])


    async function getCommentList() {
        try {
            const res = await apiHandler(COMMENT_LIST, "POST", {
                page: 1,
                limit: 100,
                post_id: postId
            })
            if (res.data?.response?.status === 200) {
                setCommentList(res?.data?.data?.comments?.data)
            } else {
                //navigate('/auth/sign-in')
                //addToast(res?.response?.data?.data?.message, { appearance: 'error' });
                //console.log("profileRes",res)
            }
        } catch (error) {
            console.error(error);
        }
        finally {
        }
    }

    return (
        <>
            <div className="comment-area mt-3" style={{ marginTop: "10px" }}>
                <h5 style={{ marginTop: "5px" }}>List of Comments</h5>
                {
                    commentList?.map((elm, i) => (
                        <div key={elm?.id}>
                            <SingleUserComment postId={postId} commentElm={elm} setRefreshCommentList={setRefreshCommentList} />
                            {
                                elm?.replies?.map((innerElm, index) => (
                                    <div style={{ marginLeft: "40px" }}>
                                        <SingleUserComment postId={postId} commentElm={innerElm} setRefreshCommentList={setRefreshCommentList} />
                                        {
                                            innerElm?.replies?.map((innerElm2, index) => (
                                                <div style={{ marginLeft: "40px" }}>
                                                    <SingleUserComment postId={postId} commentElm={innerElm2} setRefreshCommentList={setRefreshCommentList} />
                                                    {
                                                        innerElm2?.replies?.map((innerElm3, index) => (
                                                            <div style={{ marginLeft: "40px" }}>
                                                                <SingleUserComment postId={postId} commentElm={innerElm3} setRefreshCommentList={setRefreshCommentList} />
                                                                {
                                                                    innerElm3?.replies?.map((innerElm4, index) => (
                                                                        <div style={{ marginLeft: "40px" }}>
                                                                            <SingleUserComment postId={postId} commentElm={innerElm4} setRefreshCommentList={setRefreshCommentList} />
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            ))
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    ))
                }
                {/* <CommentBox postId={postId} commentId={""} /> */}
            </div>
        </>
    )
}

export default CommentList
