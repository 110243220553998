import React, { useState, useEffect } from 'react'
import { Container, Card, Row, Col, Dropdown, OverlayTrigger, Tooltip, Button, Modal, Nav, Tab } from 'react-bootstrap'
import { Link, useParams, useNavigate } from 'react-router-dom'
import ProfileHeader from '../../../../components/profile-header'
import CustomToggle from '../../../../components/dropdowns'
import ShareOffcanvas from '../../../../components/share-offcanvas'
import LoaderModal from '../../../../components/loaderModal'
import { InView } from "react-intersection-observer";
import Moment from 'moment';
import { apiHandler } from '../../../../api/apihandler';
import { CLASS_DETAILS, FEEDS_LIST } from '../../../../api/constants'
import DOMPurify from "isomorphic-dompurify";

//image
import loader from '../../../../assets/images/page-img/page-load-loader.gif'
import header from '../../../../assets/images/page-img/profile-bg7.jpg'

// fullcalender
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from "@fullcalendar/list"

import user1 from '../../../../assets/images/user/05.jpg'
import user2 from '../../../../assets/images/user/06.jpg'
import user3 from '../../../../assets/images/user/07.jpg'
import user4 from '../../../../assets/images/user/08.jpg'
import user5 from '../../../../assets/images/user/09.jpg'
import user6 from '../../../../assets/images/user/10.jpg'
import user7 from '../../../../assets/images/user/11.jpg'
import user8 from '../../../../assets/images/user/12.jpg'
import NoDataFound from '../../../../components/noDataFound'
import ClassBuyPopup from '../../../../components/classes/classBuyPopup'

// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux';
import CreatePostInputBox from '../../../../components/createPost/createPostInputBox'
import FeedPostCard from '../../../../components/feeds/feedPostCard'

const ClassDetails = () => {
   let profileData = (useSelector((state) => state.setting.setting.user_data))
   profileData = profileData ? JSON.parse(profileData) : "";

   const { slug } = useParams();
   const [show, setShow] = useState(false);
   const [classDetailsData, setClassDetailsData] = useState('');
   const [showLoader, setShowLoader] = useState(false);
   const [calenderData, setCalenderData] = useState([]);
   const [showBuy, setShowBuy] = useState(false);
   const [showMoreDataLoader, setShowMoreDataLoader] = useState(false);
   const [feedsList, setFeedsList] = useState([]);
   const [page, setPage] = useState(1);
   const [maxPage, setMaxPage] = useState(1);
   const [feedsError, setFeedsError] = useState(false);

   const [isFirst, setIsFirst] = useState(true);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        setIsFirst(false)
    }, [isFirst]);

   useEffect(() => {
      getClassDetails()
   }, []);

   async function getClassDetails() {
      setShowLoader(true)
      try {
         const res = await apiHandler(CLASS_DETAILS, "POST", {
            slug: slug
         })
         if (res.data?.response?.status === 200) {
            setClassDetailsData(res?.data?.data?.classes)
            getFeedsList(1, res?.data?.data?.classes?.id)
            // if (res?.data?.data?.classes?.class_schedules?.length > 0) {
            //    for (let i = 0; i < res?.data?.data?.classes?.class_schedules?.length; i++) {
            //       // console.log("data", Moment(res?.data?.data?.classes?.class_schedules[i]?.start_time).format('yyyy-MM-D'))
            //       setCalenderData([...calenderData, {
            //          title: res?.data?.data?.classes?.class_schedules[i]?.start_time + " - " + res?.data?.data?.classes?.class_schedules[i]?.end_time,
            //          date: res?.data?.data?.classes?.class_schedules[i]?.class_date,
            //          textColor: 'white', backgroundColor: '#ff9b8a', borderColor: '#ff9b8a'
            //       }])
            //    }
            // }
         }
      } catch (error) {
         console.error(error);
      } finally {
         setShowLoader(false)
      }
   }

   const loadMore = (inView) => {
      if (inView) {
         if (page < maxPage) {
            getFeedsList(page + 1, classDetailsData?.id)
            //console.log("scroll working...", page + 1)
            setPage(page + 1)
         }
      }
   };

   function refreshApi() {
      setPage(1)
      getFeedsList(1, classDetailsData?.id)
   }

   async function getFeedsList(pageVal, classId) {
      setShowMoreDataLoader(true)
      try {
         const res = await apiHandler(FEEDS_LIST, "POST", {
            page: pageVal,
            limit: 10,
            type: "7",
            class_id: classId
         })
         if (res.data?.response?.status === 200) {
            setMaxPage(res?.data?.data?.feeds?.meta?.last_page)
            if (pageVal === 1) {
               setFeedsList(res?.data?.data?.feeds?.data)
            } else {
               setFeedsList(feedsList.concat(res?.data?.data?.feeds?.data))
            }
         } else {
            setFeedsList([])
            setFeedsError(true)
         }
      } catch (error) {
         console.error(error);
      } finally {
         setShowMoreDataLoader(false)
      }
   }

   function tConv24(time24) {
      var ts = time24;
      var H = +ts.substr(0, 2);
      var h = (H % 12) || 12;
      h = (h < 10) ? ("0" + h) : h;  // leading 0 at the left for 1 digit hours
      var ampm = H < 12 ? " AM" : " PM";
      ts = h + ts.substr(2, 3) + ampm;
      return ts;
   };

   return (
      <>
         <LoaderModal showLoader={showLoader} />
         {/* <ProfileHeader img={header} title="Events" /> */}
         <div id="content-page" className="content-page">
            <Container>
               <Row>
                  <Col lg="12">
                     <Card>
                        <Card.Body className=" profile-page p-0">
                           <div className="profile-header">
                              <div className="position-relative">
                                 <img loading="lazy" src={classDetailsData?.banner_image ? classDetailsData?.banner_image : header} alt="profile-bg" className="rounded img-fluid cover"
                                    style={{ height: "100%", width: "100%", objectFit: "fill", maxHeight: "250px" }} />
                              </div>
                              {
                                 localStorage.getItem("eventIsPast") === "true" ?
                                    <div className="profile-info p-3 d-flex align-items-center justify-content-between position-relative" style={{ minHeight: "60px" }}>
                                    </div> :
                                    <div className="profile-info p-3 d-flex align-items-center justify-content-between position-relative">
                                    </div>
                              }


                              <div className="d-flex align-items-center justify-content-between mb-3 w-100">
                                 <div className="group-info align-items-center w-100">
                                    {/* <div className="me-3">
                                       <img className="rounded-circle img-fluid avatar-100" src={classDetailsData?.user?.user_setting?.photo ? classDetailsData?.user?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="" />
                                    </div> */}
                                    <div className="info ms-3 me-5 ">
                                       <div className="d-flex justify-content-between mb-0 w-100 ">
                                          <h3>{classDetailsData?.name}</h3>
                                          <h3 >{"$" + classDetailsData?.price} {classDetailsData?.class_payment_type === 0 ? "/ W" : classDetailsData?.class_payment_type === 1 ? "/ M" : ""}</h3>
                                       </div>
                                       <p className="mb-0" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(classDetailsData?.description) }} />
                                       <p className="mb-0"><i className="ri-calendar-2-line pe-2"></i>
                                          {/* {classDetailsData?.status === 1 ? "Active" : "Inactive"} . */}
                                          {classDetailsData?.start_date + " - " + classDetailsData?.end_date}</p>
                                       {
                                          classDetailsData?.status === 0 || classDetailsData?.class_student?.class_transaction?.length > 0 ? null :
                                             <button type="submit" className="btn btn-primary mb-2 me-1 mt-1"
                                                onClick={() => {
                                                   setShowBuy(true)
                                                }}
                                             >Pay Now</button>
                                       }
                                    </div>
                                 </div>

                              </div>
                           </div>
                        </Card.Body>
                     </Card>
                  </Col>
                  {/* <Col lg="4">
                     <Card>
                        <Card.Header className="d-flex justify-content-between">
                           <div className="header-title">
                              <h4 className="card-title">About</h4>
                           </div>
                        </Card.Header>
                        <Card.Body>
                           <ul className="list-inline p-0 m-0">
                              <li className="mb-3">
                                 <p className="mb-0" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(classDetailsData?.description) }} />
                              </li>
                              <li className="mb-3 d-flex align-items-center">
                                 <div className="avatar-30 rounded-circle bg-gray text-center me-1"><i className="ri-wallet-3-line h4"></i></div>
                                 <h6 className="mb-0">{"Course fees: $" + classDetailsData?.price}</h6>
                              </li>
                           </ul>
                        </Card.Body>
                     </Card>
                  </Col> */}
                  <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                     <Card className="p-0">
                        <Card.Body className="p-0">
                           <div className="user-tabing">
                              <Nav as="ul" variant="pills" className="d-flex align-items-center justify-content-center profile-feed-items p-0 m-0">
                                 <Nav.Item as="li" className=" col-12 col-sm-4 p-0 ">
                                    <Nav.Link href="#pills-timeline-tab" eventKey="first" role="button" className=" text-center p-3">About Class</Nav.Link>
                                 </Nav.Item>
                                 <Nav.Item as="li" className="col-12 col-sm-4 p-0">
                                    <Nav.Link href="#pills-about-tab" eventKey="second" role="button" className="text-center p-3">Class Feeds</Nav.Link>
                                 </Nav.Item>
                                 <Nav.Item as="li" className="col-12 col-sm-4 p-0">
                                    <Nav.Link href="#pills-about-tab" eventKey="third" role="button" className="text-center p-3">Transaction History</Nav.Link>
                                 </Nav.Item>
                              </Nav>
                           </div>
                        </Card.Body>
                     </Card>
                     <Col sm={12}>
                        <Tab.Content>
                           <Tab.Pane eventKey="first" >
                              <Tab.Container id="left-tabs-example" defaultActiveKey="p1">
                                 <Row>
                                    <Col lg="4">
                                       <Card>
                                          <Card.Header className="d-flex justify-content-between">
                                             <div className="header-title">
                                                <h4 className="card-title">Assigned Teacher</h4>
                                             </div>
                                          </Card.Header>
                                          <Card.Body>
                                             {
                                                classDetailsData?.class_teachers?.length > 0 ? classDetailsData?.class_teachers.map((elm, i) => (
                                                   <div className="d-flex mt-0 mb-3">
                                                      <div className="me-3">
                                                         <img loading="lazy" className="rounded-circle  avatar-50"
                                                            src={elm?.user?.user_setting?.photo ? elm?.user?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="" />
                                                      </div>
                                                      <div>
                                                         <h5 className="mb-0 d-inline-block"><Link to="#">{elm?.user?.first_name + " " + elm?.user?.last_name}</Link></h5>
                                                         <p>
                                                            {
                                                               profileData?.slug === elm?.user?.slug ?
                                                                  <p className="mb-0 btn-primary">You</p> :
                                                                  <Link key={elm?.id} to={`/chat/index?type=user&slug=${elm?.user?.slug}`} target='_blank noopener,noreferrer'>
                                                                     <button type="submit" className="btn btn-sm btn-primary mb-2 me-1 mt-1"><i className="ri-message-3-line h6" style={{ color: "white" }}></i></button>
                                                                     {/* <p className="mb-0 btn-primary"><i className="ri-message-3-line h4"></i> Message</p> */}
                                                                  </Link>
                                                            }
                                                         </p>
                                                      </div>
                                                   </div>
                                                )) :
                                                   <NoDataFound />
                                             }
                                          </Card.Body>
                                       </Card>

                                    </Col>
                                    <Col lg="8">
                                       <Card>
                                          <Card.Header className="d-flex justify-content-between">
                                             <div className="header-title">
                                                <h4 className="card-title">{classDetailsData?.class_type === 0 ? "Weekly Schedule" : classDetailsData?.class_type === 1 ? "Monthly Schedule" : "One Time"}</h4>
                                             </div>
                                          </Card.Header>
                                          <Card.Body>
                                             {
                                                classDetailsData?.class_schedules?.length > 0 ?
                                                   <div>
                                                      {
                                                         classDetailsData?.class_type === 0 ?
                                                            <div className="table-responsive-sm">
                                                               <table className="table table-striped">
                                                                  <thead>
                                                                     <tr>
                                                                        <th scope="col">Day</th>
                                                                        <th scope="col">Start Time</th>
                                                                        <th scope="col">End Time</th>
                                                                     </tr>
                                                                  </thead>
                                                                  <tbody>
                                                                     {
                                                                        classDetailsData?.class_schedules.map((elm, i) => (
                                                                           <tr>
                                                                              {/* <th scope="row">{adDetails?.userPost?.user_post_ad?.transaction_id ? adDetails?.userPost?.user_post_ad?.transaction_id : "0"}</th> */}
                                                                              <td>{elm?.day?.name}</td>
                                                                              <td>{tConv24(elm?.start_time)}</td>
                                                                              <td>{tConv24(elm?.end_time)}</td>
                                                                              {/* <td>{Moment(elm?.start_time).format('hh:mm A')}</td>
                                                            <td>{Moment(elm?.end_time).format('hh:mm A')}</td> */}
                                                                           </tr>
                                                                        ))
                                                                     }
                                                                  </tbody>
                                                               </table>
                                                            </div> : null
                                                      }
                                                      {
                                                         classDetailsData?.class_type === 1 ?
                                                            <div className="table-responsive-sm">
                                                               <table className="table table-striped">
                                                                  <thead>
                                                                     <tr>
                                                                        <th scope="col">Day</th>
                                                                        <th scope="col">Start Time</th>
                                                                        <th scope="col">End Time</th>
                                                                     </tr>
                                                                  </thead>
                                                                  <tbody>
                                                                     {
                                                                        classDetailsData?.class_schedules.map((elm, i) => (
                                                                           <tr>
                                                                              <td>{elm?.class_date} {elm?.day?.name}</td>
                                                                              <td>{tConv24(elm?.start_time)}</td>
                                                                              <td>{tConv24(elm?.end_time)}</td>
                                                                           </tr>
                                                                        ))
                                                                     }
                                                                  </tbody>
                                                               </table>
                                                            </div>
                                                            // <FullCalendar
                                                            //    plugins={[dayGridPlugin, listPlugin]}
                                                            //    initialView='dayGridMonth'
                                                            //    headerToolbar={{
                                                            //       left: 'prev,next today',
                                                            //       center: 'title',
                                                            //       right: 'dayGridMonth',
                                                            //    }}
                                                            //    events={calenderData}
                                                            // /> 
                                                            : null
                                                      }
                                                      {
                                                         classDetailsData?.class_type === 2 ?
                                                            <div className="table-responsive-sm">
                                                               <table className="table table-striped">
                                                                  <thead>
                                                                     <tr>
                                                                        <th scope="col">Day</th>
                                                                        <th scope="col">Start Time</th>
                                                                        <th scope="col">End Time</th>
                                                                     </tr>
                                                                  </thead>
                                                                  <tbody>
                                                                     {
                                                                        classDetailsData?.class_schedules.map((elm, i) => (
                                                                           <tr>
                                                                              {/* <th scope="row">{adDetails?.userPost?.user_post_ad?.transaction_id ? adDetails?.userPost?.user_post_ad?.transaction_id : "0"}</th> */}
                                                                              <td>{elm?.day?.name}</td>
                                                                              <td>{tConv24(elm?.start_time)}</td>
                                                                              <td>{tConv24(elm?.end_time)}</td>
                                                                           </tr>
                                                                        ))
                                                                     }
                                                                  </tbody>
                                                               </table>
                                                            </div> : null
                                                      }
                                                   </div>
                                                   : <NoDataFound />
                                             }
                                          </Card.Body>
                                       </Card>
                                    </Col>
                                 </Row>
                              </Tab.Container>
                           </Tab.Pane>
                           <Tab.Pane eventKey="second" >
                              <Tab.Container id="left-tabs-example" defaultActiveKey="p1">
                                 <Row>
                                    <Col lg={12}>
                                       {
                                          classDetailsData?.class_student?.class_transaction?.length > 0 ?
                                             <CreatePostInputBox
                                                postType={"7"}
                                                refreshApi={refreshApi}
                                                eventId={classDetailsData?.id} /> : null
                                       }
                                       {
                                          feedsList?.length > 0 ? feedsList?.map((elm, i) => (
                                             <FeedPostCard elm={elm} getFeedsList={refreshApi} key={elm?.id} feedDetails={false} />
                                          )) :
                                             feedsError ? <NoDataFound txtData={"You are not allow to create and view feeds."} /> : <NoDataFound />
                                       }
                                       {
                                          showMoreDataLoader ?
                                             <div className="col-sm-12 text-center">
                                                <img src={loader} alt="loader" style={{ height: "100px" }} />
                                             </div> : null
                                       }
                                       <InView as="div" onChange={(inView, entry) => loadMore(inView)} />
                                    </Col>
                                 </Row>
                              </Tab.Container>
                           </Tab.Pane>
                           <Tab.Pane eventKey="third" >
                              <Tab.Container id="left-tabs-example" defaultActiveKey="p1">
                                 {
                                    classDetailsData?.class_student?.class_transaction?.length > 0 ?
                                       <Card>
                                          <Card.Header className="d-flex justify-content-between">
                                             <div className="header-title">
                                                <h4 className="card-title">Transaction History</h4>
                                             </div>
                                          </Card.Header>
                                          <Card.Body>
                                             {
                                                classDetailsData?.class_student?.class_transaction.map((elm, i) => (
                                                   <div className="mt-0 mb-3">
                                                      <div className="d-flex justify-content-between">
                                                         <h5 className="mb-0 d-inline-block btn-primary">{Moment(elm?.payment_date).format('yyyy-MMM-DD')}</h5>
                                                         <h4 className="mb-0 d-inline-block btn-primary">{"$" + elm?.price}</h4>
                                                      </div>
                                                      <div className="d-flex justify-content-between">
                                                         <div>
                                                            <h6 className="mb-0 d-inline-block w-100">{"Txn :  " + elm?.transaction_id}</h6>
                                                            <h6 className="mb-0 d-inline-block w-100"><i className="ri-currency-line pe-2"></i>{classDetailsData?.class_payment_type === 0 ? "Weekly Payment" :
                                                               classDetailsData?.class_payment_type === 1 ? "Monthly Payment" : "One Time Payment"}</h6>
                                                         </div>
                                                         <div>
                                                            <span className="d-flex align-items-center text-success mb-0 "> <i class="material-symbols-outlined ">verified</i>Success </span>
                                                         </div>
                                                      </div>
                                                   </div>
                                                ))
                                             }
                                          </Card.Body>
                                       </Card> : <NoDataFound />
                                 }
                              </Tab.Container>
                           </Tab.Pane>
                        </Tab.Content>
                     </Col>
                  </Tab.Container>


               </Row>
            </Container>
         </div>
         {
            showBuy ?
               <ClassBuyPopup elm={classDetailsData} showBuy={showBuy} setShowBuy={setShowBuy} /> : null
         }
      </>
   )
}

export default ClassDetails