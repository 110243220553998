import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Nav, Tab, OverlayTrigger, Tooltip, Button, Modal, ModalFooter } from 'react-bootstrap'
import Card from '../Card'
import CustomToggle from '../dropdowns'
import ShareOffcanvas from '../share-offcanvas'
import { Link, useNavigate, Route } from 'react-router-dom'
import { apiHandler } from '../../api/apihandler';
import { handleImageUpload } from "../../api/uploads";
import { CHALLENGE_PERTICIPANTS, CHALLENGE_PERTICIPATE, CHALLENGE_PERTICIPATE_MEDIA_POST, COMMENT_LIKE, FEED_DELETE, FEED_LIKE, FEED_LIKES, POST_FLAG, REPORT_FLAGS_LIST } from "../../api/constants";
import Moment, { now } from 'moment';
import ReactFsLightbox from 'fslightbox-react';
import Swal from 'sweetalert2'
import EmojiPicker from 'emoji-picker-react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import CommentList from '../comment/commentList'
import CommentBox from '../comment/commentBox'
//import VideoThumbnail from 'react-video-thumbnail';
import { VideoThumbnailGenerator } from 'browser-video-thumbnail-generator';
import LoaderModal from '../loaderModal'

// images
import likeFillIcon from '../../assets/images/icon/like_fill_icon.png'
import likeIcon from '../../assets/images/icon/like_icon.png'
import playButton from '../../assets/images/icon/play_button.png'
import user2 from '../../assets/images/user/02.jpg'
import user3 from '../../assets/images/user/03.jpg'
import small1 from '../../assets/images/small/07.png'
import small2 from '../../assets/images/small/08.png'
import small3 from '../../assets/images/small/09.png'
import small6 from '../../assets/images/small/12.png'
import cross from '../../assets/images/icon/cross.png'
import video from '../../assets/images/small/video.png'
import ParticipateList from './participateList'

// Fslightbox plugin
const FsLightbox = ReactFsLightbox.default ? ReactFsLightbox.default : ReactFsLightbox;


const ChallengeCard = ({ elm, getChallengeList, challengeDetails }) => {
    var today = new Date();
    const [showFlagModal, setFlagModalShow] = useState(false);
    const [commentShow, setCommentShow] = useState(false);
    const [postDetailsShow, setpostDetailsShow] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [feedMediaList, setFeedMediaList] = useState([]);
    const [reportFlagList, setReportFlagList] = useState([]);
    const [feedMediaTypeList, setFeedMediaTypeList] = useState([]);
    const [feedLikeUserList, setFeedLikeUserList] = useState([]);
    const [showLikesList, setShowLikesList] = useState(false);
    const [refreshCommentList, setRefreshCommentList] = useState(false);

    const [isParticipate, setIsparticipate] = useState(elm?.is_participate);
    const [winnerParticipateId, setWinnerParticipateId] = useState(elm?.winner_participate_id);
    const [postContent, setPostContent] = useState('');
    const [imageFileArray, setImageFileArray] = useState([]);
    const [imageArray, setImageArray] = useState([]);
    const [videoFileArray, setVideoFileArray] = useState([]);
    const [videoArray, setVideoArray] = useState([]);
    const [videoThumbFileArray, setVideoThumbFileArray] = useState([]);
    const [videoThumbArray, setVideoThumbArray] = useState([]);
    const [showPicker, setShowPicker] = useState(false);
    const [participateModalShow, setParticipateModalShow] = useState(false);

    const [imageController, setImageController] = useState({
        toggler: false,
        slide: 1
    });
    function imageOnSlide(number) {
        setImageController({
            toggler: !imageController.toggler,
            slide: number
        });
    }

    const handleClose = () => {
        setCommentShow(false)
        setpostDetailsShow(false)
        setParticipateModalShow(false)
        setFlagModalShow(false)
    };
    const handleCommentShow = () => setCommentShow(true);
    const handlePostDetailsShow = () => setpostDetailsShow(true);
    const handleParticipateModalShow = () => {
        setImageArray([]);
        setImageFileArray([])
        setVideoArray([]);
        setVideoFileArray([])
        setVideoThumbArray([])
        setVideoThumbFileArray([])
        setPostContent("")
        setParticipateModalShow(true)
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                //console.log("uploaded", file)
                setImageFileArray([...imageFileArray, file])
                setImageArray([...imageArray, reader.result])
            };
            reader.readAsDataURL(file);
        }
    };

    const handleVideoChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                await handleGenerateThumbnail(reader.result)
                setVideoFileArray([...videoFileArray, file])
                setVideoArray([...videoArray, reader.result])
            };
            reader.readAsDataURL(file);
        }
    };

    async function handleGenerateThumbnail(videoUrl) {
        const generator = new VideoThumbnailGenerator(videoUrl);
        generator.getThumbnail()
            .then((thumbnail) => {
                //console.log("thumbUrl", thumbnail)
                setVideoThumbArray([...videoThumbArray, thumbnail?.thumbnail])
                fetch(thumbnail?.thumbnail)
                    .then((res) => res.blob())
                    .then((blob) => {
                        const file = new File([blob], `${+new Date()}.jpg`, { type: 'image/jpeg' });
                        //console.log('Converted image to File object:', file);
                        setVideoThumbFileArray([...videoThumbFileArray, file])
                    })
                    .catch((error) => {
                        console.error('Error fetching image data:', error);
                    });

                //generator.revokeUrls();
            });
    };

    async function createMedia() {
        elm?.user_post_images.map((image, i) => {
            if (!feedMediaList.includes(image?.image_path)) {
                feedMediaList.push(image?.image_path)
                setFeedMediaList(feedMediaList)
                if (image?.media_type === 1) {
                    feedMediaTypeList.push("video")
                    setFeedMediaTypeList(feedMediaTypeList)
                } else {
                    feedMediaTypeList.push("image")
                    setFeedMediaTypeList(feedMediaTypeList)

                }
            }
        })
        //console.log("arrayList", feedMediaList)
    }

    useEffect(() => {
        createMedia()
        getFeedLikesUserList(elm?.id)
        //getFeedLikesUserList()
    }, [])

    async function getFeedLike(postId) {
        setShowLoader(true)
        try {
            const res = await apiHandler(FEED_LIKE, "POST", {
                post_id: postId
            })
            if (res.data?.response?.status === 200) {
                if (elm?.is_like === 1) {
                    elm['is_like'] = 0
                    elm['like_count'] = elm?.like_count - 1
                } else {
                    elm['is_like'] = 1
                    elm['like_count'] = elm?.like_count + 1
                }
                getFeedLikesUserList(elm?.id)
                // Swal.fire({
                //     title: 'Success',
                //     text: res?.response?.data?.data?.message,
                //     icon: 'success',
                //     confirmButtonText: 'Okay'
                // })
                //getChallengeList()
            } else {
                Swal.fire({
                    title: 'Error',
                    text: res?.response?.data?.data?.message,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setShowLoader(false)
        }
    }

    async function getFeedLikesUserList(postId) {
        try {
            const res = await apiHandler(FEED_LIKES, "POST", {
                post_id: postId
            })
            if (res.data?.response?.status === 200) {
                setFeedLikeUserList(res?.data?.data?.userLikes)
            } else {
                // Swal.fire({
                //     title: 'Error',
                //     text: res?.response?.data?.data?.message,
                //     icon: 'error',
                //     confirmButtonText: 'Okay'
                // })
            }
        } catch (error) {
            console.error(error);
        }
        finally {
        }
    }

    async function getFeedDelete(post_id) {
        try {
            const res = await apiHandler(FEED_DELETE, "DELETE", {
                post_id: post_id
            })
            if (res.data?.response?.status === 200) {
                Swal.fire({
                    title: 'Success',
                    text: 'Your post deleted successfully',
                    icon: 'success',
                    confirmButtonText: 'Okay'
                })
                getChallengeList()
            } else {
                Swal.fire({
                    title: 'Error',
                    text: res?.response?.data?.data?.message,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
                //navigate('/auth/sign-in')
                //addToast(res?.response?.data?.data?.message, { appearance: 'error' });
                //console.log("profileRes",res)
            }
        } catch (error) {
            console.error(error);
        }
        finally {
        }
    }


    function handlePostSubmit() {
        console.log("handle working")
        if (postContent != "" || imageArray.length > 0 || videoArray.length > 0) {
            var isCmplt = 0
            if (imageArray.length > 0 || videoArray.length > 0) {
                isCmplt = 0
            } else {
                isCmplt = 1
            }
            getParticipatePost(isCmplt)
        } else {
            Swal.fire({
                title: 'Warning!',
                text: 'Please enter post content or select post file!',
                icon: 'warning',
                confirmButtonText: 'Okay'
            })
        }
    };

    async function getParticipatePost(isCmplt) {
        setShowLoader(true)
        try {
            const res = await apiHandler(CHALLENGE_PERTICIPATE, "POST", {
                comment: postContent,
                is_completed: isCmplt,
                post_id: elm?.id,
                type: ""
            })
            if (res.data?.response?.status === 200) {
                if (isCmplt === 1) {
                    handleClose()
                    setIsparticipate(1)
                    setShowLoader(false)
                    Swal.fire({
                        title: 'Success',
                        text: 'Your post created successfully',
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    //handleFeedPostSubmit(res?.data?.data?.feed?.id)
                    await handleParticipatePostSubmit(res?.data?.data?.participate?.id)
                }
            } else {
                setShowLoader(false)
                Swal.fire({
                    title: 'Error',
                    text: res?.response?.data?.data?.message,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }
        } catch (error) {
            console.error(error);
        }
        finally {
        }
    }

    async function handleParticipatePostSubmit(participatePostId) {
        if (imageFileArray.length > 0) {
            for (let elm of imageFileArray) {
                const url = await handleImageUpload(elm, `feedImages/${Date.now()}`, "image/jpeg")
                //console.log("uploaded", url)
                await getParticipateMediaPost(url, "", "0", participatePostId)
            }
        }
        if (videoFileArray.length > 0) {
            // for (let elm of videoFileArray) {
            for (let i = 0; i < videoFileArray?.length; i++) {
                const url = await handleImageUpload(videoFileArray[i], `feedVideos/${Date.now()}`, "video/*")
                const thumb = await handleImageUpload(videoThumbFileArray[i], `feedImages/${Date.now()}`, "image/jpeg")
                //console.log("uploaded", url)
                await getParticipateMediaPost(url, thumb, "1", participatePostId)
            }
        }
        await getFeedFinalPost(participatePostId)
    };

    async function getFeedFinalPost(participatePostId) {
        try {
            const res = await apiHandler(CHALLENGE_PERTICIPATE, "POST", {
                content: postContent,
                type: "",
                is_completed: "1",
                post_id: elm?.id
            })
            if (res.data?.response?.status === 200) {
                handleClose()
                setIsparticipate(1)
                Swal.fire({
                    title: 'Success',
                    text: 'Your post created successfully',
                    icon: 'success',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Error',
                    text: res?.response?.data?.data?.message,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
                //navigate('/auth/sign-in')
                //addToast(res?.response?.data?.data?.message, { appearance: 'error' });
                //console.log("profileRes",res)
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setShowLoader(false)
        }
    }

    async function getParticipateMediaPost(awsPath, awsThumb, mediaType, participatePostId) {
        try {
            const res = await apiHandler(CHALLENGE_PERTICIPATE_MEDIA_POST, "POST", {
                user_post_participate_id: participatePostId,
                post_id: elm?.id,
                path: awsPath,
                media_type: mediaType,
                thumb: awsThumb
            })
            if (res.data?.response?.status === 200) {
            } else {
                Swal.fire({
                    title: 'Error',
                    text: res?.response?.data?.data?.message,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }
        } catch (error) {
            console.error(error);
        }
        finally {
        }
    }

    async function getReportFlagList() {
        try {
            const res = await apiHandler(REPORT_FLAGS_LIST, "POST")
            if (res.data?.response?.status === 200) {
                setReportFlagList(res?.data?.data?.reportFlags)
                setFlagModalShow(true)
            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    }

    async function flagPostApi(flagId) {
        try {
            const res = await apiHandler(POST_FLAG, "POST", {
                post_id: elm?.id,
                report_flag_id: flagId
            })
            if (res.data?.response?.status === 200) {
                Swal.fire({
                    title: 'Success',
                    text: res?.response?.data?.data?.message,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                })
                getChallengeList()
            } else {
                Swal.fire({
                    title: 'Error',
                    text: res?.response?.data?.data?.message,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }
        } catch (error) {
            console.error(error);
        }
        finally {
        }
    }

    return (
        <>
            <LoaderModal showLoader={showLoader}/>
            {
                feedMediaList?.length > 0 ?
                    // <FsLightbox
                    //     toggler={toggler}
                    //     sources={feedMediaList}
                    //     // sources={[
                    //     //     'https://uzone360live.s3.amazonaws.com/feedImages/img4.jpg',
                    //     //     'https://i.imgur.com/fsyrScY.jpg',
                    //     //     'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'
                    //     // ]}
                    //     types={feedMediaTypeList}
                    // /> 
                    <FsLightbox
                        toggler={imageController.toggler}
                        sources={feedMediaList}
                        slide={imageController.slide}
                        types={feedMediaTypeList}
                    />
                    : null
            }

            <Card >
                <Card.Body>
                    <div className="post-item">
                        <div className="user-post-data pb-3">
                            <div className="d-flex justify-content-between">
                                <div className="me-3">
                                    <img loading="lazy" className="rounded-circle  avatar-45"
                                        src={elm?.user?.user_setting?.photo ? elm?.user?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="" />
                                </div>
                                <div className="w-100">
                                    <div className="d-flex justify-content-between flex-wrap">
                                        <div>
                                            <h5 className="mb-0 d-inline-block"><Link to={`/dashboard/app/friend-profile/${elm?.user?.slug}`}>{elm?.user?.first_name + " " + elm?.user?.last_name}</Link></h5>
                                            {/* <p className="ms-1 mb-0 d-inline-block">Add New Post</p> */}
                                            <p className="mb-0">{Moment(elm?.updated_at).format('DD MMMM YYYY')}</p>
                                        </div>
                                        <div className="card-post-toolbar">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="bg-transparent">
                                                    <span className="material-symbols-outlined">
                                                        more_horiz
                                                    </span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className=" m-0 p-0">
                                                    {
                                                        elm?.is_own === 1 ?
                                                            <Dropdown.Item className=" p-3" to="#">
                                                                <div className="d-flex align-items-top">
                                                                    <i className="ri-delete-bin-7-line h4"></i>
                                                                    <div className="data ms-2" onClick={() => {
                                                                        Swal.fire({
                                                                            title: 'Warning!',
                                                                            text: 'Are you sure, want to delete this challenge?',
                                                                            icon: 'warning',
                                                                            showCancelButton: true,
                                                                            showConfirmButton: true,
                                                                            cancelButtonText: 'Cancel',
                                                                            confirmButtonText: 'Confirm',
                                                                        }).then((result) => {
                                                                            if (result.isConfirmed) {
                                                                                getFeedDelete(elm?.id)
                                                                            }
                                                                        })
                                                                    }}>
                                                                        <h6>Delete</h6>
                                                                        <p className="mb-0">Remove this Post from your profile</p>
                                                                    </div>
                                                                </div>
                                                            </Dropdown.Item> : null
                                                    }
                                                    <Dropdown.Item className=" p-3" to="#">
                                                        <div className="d-flex align-items-top"
                                                            onClick={() => getReportFlagList()}>
                                                            <i className="ri-flag-2-line h4"></i>
                                                            <div className="data ms-2">
                                                                <h6>Report Flag</h6>
                                                                <p className="mb-0">Flag reason for this post .</p>
                                                            </div>
                                                        </div>
                                                    </Dropdown.Item>
                                                    {/* <Dropdown.Item className=" p-3" to="#">
                                                        <div className="d-flex align-items-top">
                                                            <i className="ri-save-line h4"></i>
                                                            <div className="data ms-2">
                                                                <h6>Save Post</h6>
                                                                <p className="mb-0">Add this to your saved items</p>
                                                            </div>
                                                        </div>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item className=" p-3" to="#">
                                                        <div className="d-flex align-items-top">
                                                            <i className="ri-pencil-line h4"></i>
                                                            <div className="data ms-2">
                                                                <h6>Edit Post</h6>
                                                                <p className="mb-0">Update your post and saved items</p>
                                                            </div>
                                                        </div>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item className=" p-3" to="#">
                                                        <div className="d-flex align-items-top">
                                                            <i className="ri-close-circle-line h4"></i>
                                                            <div className="data ms-2">
                                                                <h6>Hide From Timeline</h6>
                                                                <p className="mb-0">See fewer posts like this.</p>
                                                            </div>
                                                        </div>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item className=" p-3" to="#">
                                                        <div className="d-flex align-items-top">
                                                            <i className="ri-delete-bin-7-line h4"></i>
                                                            <div className="data ms-2">
                                                                <h6>Delete</h6>
                                                                <p className="mb-0">Remove thids Post on Timeline</p>
                                                            </div>
                                                        </div>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item className=" p-3" to="#">
                                                        <div className="d-flex align-items-top">
                                                            <i className="ri-notification-line h4"></i>
                                                            <div className="data ms-2">
                                                                <h6>Notifications</h6>
                                                                <p className="mb-0">Turn on notifications for this post</p>
                                                            </div>
                                                        </div>
                                                    </Dropdown.Item> */}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            elm?.content ?
                                <div className="mt-1">
                                    <p>{elm?.content}</p>
                                </div> : null
                        }
                        <div className="user-post">
                            <Link to="#" >
                                {
                                    elm?.user_post_images?.length === 1 ?
                                        <ResponsiveMasonry
                                            columnsCountBreakPoints={{ 350: 1, 750: 1, 900: 1 }}
                                        >
                                            <Masonry columnsCount={3} gutter="10px">
                                                {elm?.user_post_images.map((image, i) => (
                                                    image?.media_type === 1 ?
                                                        <div key={image?.id} onClick={() => {
                                                            imageOnSlide(i + 1)
                                                        }}>
                                                            <div className="img-fluid" style={{ position: "relative" }} >
                                                                <img loading="lazy"
                                                                    src={image?.thumb} alt="gallary"
                                                                    style={{ display: "block", marginLeft: "auto", marginRight: "auto", maxWidth: "100%" }}/>
                                                            </div>
                                                            <Link style={{ position: "absolute", left: "47%", top: "47%", zIndex: 1 }}>
                                                                <img style={{ height: 50, width: 50 }} src={playButton} />
                                                            </Link>
                                                        </div> :
                                                        <img loading="lazy" src={image?.image_path}
                                                            alt="post" className="img-fluid w-100"
                                                            onClick={() => {
                                                                imageOnSlide(i + 1)
                                                            }} />
                                                ))}
                                            </Masonry>
                                        </ResponsiveMasonry> :
                                        elm?.user_post_images?.length === 2 ?
                                            <ResponsiveMasonry
                                                columnsCountBreakPoints={{ 350: 2, 750: 2, 900: 2 }}
                                            >
                                                <Masonry columnsCount={3} gutter="10px">
                                                    {elm?.user_post_images.map((image, i) => (
                                                        image?.media_type === 1 ?
                                                            <div key={image?.id} onClick={() => {
                                                                imageOnSlide(i + 1)
                                                            }} style={{ position: "relative" }}>
                                                                <div className="img-fluid">
                                                                    <img loading="lazy"
                                                                        src={image?.thumb} alt="gallary" className="img-fluid" />
                                                                </div>
                                                                <Link style={{ position: "absolute", left: "45%", top: "40%", zIndex: 1 }}>
                                                                    <img style={{ height: 50, width: 50 }} src={playButton} />
                                                                </Link>
                                                            </div> :
                                                            <img loading="lazy" src={image?.image_path}
                                                                alt="post" className="img-fluid w-100"
                                                                onClick={() => {
                                                                    imageOnSlide(i + 1)
                                                                }} />
                                                    ))}
                                                </Masonry>
                                            </ResponsiveMasonry> :
                                            <ResponsiveMasonry
                                                columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                                            >
                                                <Masonry columnsCount={3} gutter="10px">
                                                    {elm?.user_post_images.map((image, i) => (
                                                        image?.media_type === 1 ?
                                                            <div key={image?.id} onClick={() => {
                                                                imageOnSlide(i + 1)
                                                            }} style={{ position: "relative" }}>
                                                                <div className="img-fluid">
                                                                    <img loading="lazy"
                                                                        src={image?.thumb} alt="gallary" className="img-fluid" />
                                                                </div>
                                                                <Link className="video-img-btn">
                                                                    <img style={{ height: 50, width: 50 }} src={playButton} />
                                                                </Link>
                                                            </div> :
                                                            <img loading="lazy" src={image?.image_path}
                                                                alt="post" className="img-fluid w-100"
                                                                onClick={() => {
                                                                    imageOnSlide(i + 1)
                                                                }} />
                                                    ))}
                                                </Masonry>
                                            </ResponsiveMasonry>
                                }

                            </Link>
                            {/* <img loading="lazy" src={elm?.user_post_images[0]?.image_path} alt="post" className="img-fluid w-100" /> */}
                        </div>

                        <div className="comment-area mt-4">
                            <div className="d-flex justify-content-between align-items-center flex-wrap">
                                <div className="like-block position-relative d-flex align-items-center">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <h6 className="mb-0 d-inline-block">
                                                {
                                                    elm?.is_like === 1 ?
                                                        <img loading="lazy" src={likeFillIcon} alt="icon" className="img-fluid" style={{ marginTop: "-2px", cursor: "pointer" }}
                                                            onClick={() => { getFeedLike(elm?.id) }} /> :
                                                        <img loading="lazy" src={likeIcon} alt="icon" className="img-fluid" style={{ marginTop: "-5px", cursor: "pointer" }}
                                                            onClick={() => { getFeedLike(elm?.id) }} />
                                                }
                                                {
                                                    elm?.is_like === 1 ?
                                                        elm?.like_count > 1 ?
                                                            <Link to="#"
                                                                onMouseEnter={() => setShowLikesList(true)}
                                                                onMouseLeave={() => setShowLikesList(false)}>You and {((elm?.like_count) - 1) + " "} others</Link> :
                                                            <Link to="#"
                                                                onMouseEnter={() => setShowLikesList(true)}
                                                                onMouseLeave={() => setShowLikesList(false)}>You like</Link> :
                                                        <Link to="#" style={{ marginLeft: "6px" }}
                                                            onMouseEnter={() => setShowLikesList(true)}
                                                            onMouseLeave={() => setShowLikesList(false)}>{elm?.like_count + " "}like</Link>
                                                }
                                            </h6>
                                            <h6 className="mb-0 d-inline-block" style={{ marginLeft: "10px" }}>
                                                <Link to="#" onClick={handleCommentShow}
                                                >{elm?.comment_count + " "}Comment</Link>
                                            </h6>
                                            <h6 className="mb-0 d-inline-block" style={{ marginLeft: "10px" }}>
                                                <Link to="#"
                                                    // onMouseEnter={() => setShowParticipantList(true)}
                                                    // onMouseLeave={() => setShowParticipantList(false)}
                                                    onClick={handlePostDetailsShow}
                                                >{(elm?.participates_count ? elm?.participates_count : "0") + " Participants"}</Link>
                                            </h6>
                                        </div>

                                        {
                                            showLikesList ?
                                                <div className="card-header-toolbar d-flex align-items-center" style={{ position: "absolute" }}
                                                    onMouseEnter={() => setShowLikesList(true)}
                                                    onMouseLeave={() => setShowLikesList(false)}>
                                                    <Dropdown>
                                                        <Card className="dropdown-menu-right cardBackgroundColor">
                                                            <div style={{ backgroundColor: "#e4f0f1", margin: "1px" }}>
                                                                {
                                                                    feedLikeUserList ?
                                                                        feedLikeUserList?.map((elm, i) => (
                                                                            <Dropdown.Item href={`/dashboard/app/friend-profile/${elm?.user?.slug}`} >{elm?.user?.first_name + " " + elm?.user?.last_name}</Dropdown.Item>
                                                                        )) : null
                                                                }
                                                            </div>
                                                        </Card>
                                                    </Dropdown>
                                                </div> : null
                                        }

                                        {/* {
                                            showParticipantList ?
                                                <div className="card-header-toolbar d-flex align-items-center" style={{ position: "absolute" }}
                                                    onMouseEnter={() => setShowParticipantList(true)}
                                                    onMouseLeave={() => setShowParticipantList(false)}>
                                                    <Dropdown>
                                                        <Card className="dropdown-menu-right cardBackgroundColor">
                                                            <div style={{ backgroundColor: "#e4f0f1", margin: "1px" }}>
                                                                {
                                                                    partcipantUserList ?
                                                                        partcipantUserList?.map((elm, i) => (
                                                                            <Dropdown.Item href={`/dashboard/app/friend-profile/${elm?.user?.slug}`} >{elm?.user?.first_name + " " + elm?.user?.last_name}</Dropdown.Item>
                                                                        )) : null
                                                                }
                                                            </div>
                                                        </Card>
                                                    </Dropdown>
                                                </div> : null
                                        } */}

                                        {/* <div className="like-data">
                                            <Dropdown>
                                                <Dropdown.Toggle as={CustomToggle} >
                                                    <img loading="lazy" src={icon1} className="img-fluid" alt="" />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className=" py-2">
                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Like</Tooltip>} className="ms-2 me-2" ><img loading="lazy" src={icon1} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Love</Tooltip>} className="me-2" ><img loading="lazy" src={icon2} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Happy</Tooltip>} className="me-2" ><img loading="lazy" src={icon3} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                    <OverlayTrigger placement="top" overlay={<Tooltip>HaHa</Tooltip>} className="me-2" ><img loading="lazy" src={icon4} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Think</Tooltip>} className="me-2" ><img loading="lazy" src={icon5} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Sade</Tooltip>} className="me-2" ><img loading="lazy" src={icon6} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Lovely</Tooltip>} className="me-2" ><img loading="lazy" src={icon7} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="total-like-block ms-2 me-3">
                                            <Dropdown>
                                                <Dropdown.Toggle as={CustomToggle} id="post-option" >
                                                    {elm?.like_count} Likes
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item to="#">Max Emum</Dropdown.Item>
                                                    <Dropdown.Item to="#">Bill Yerds</Dropdown.Item>
                                                    <Dropdown.Item to="#">Hap E. Birthday</Dropdown.Item>
                                                    <Dropdown.Item to="#">Tara Misu</Dropdown.Item>
                                                    <Dropdown.Item to="#">Midge Itz</Dropdown.Item>
                                                    <Dropdown.Item to="#">Sal Vidge</Dropdown.Item>
                                                    <Dropdown.Item to="#">Other</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div> */}
                                    </div>
                                </div>
                                {
                                    // console.log("date", (Moment(today).format('DD MMMM YYYY')) <= (Moment("01 April 2024").format('DD MMMM YYYY')))
                                    // winnerParticipateId === 0 && (Moment(today).format('DD MMMM YYYY')) <= (Moment(elm?.expire_at).format('DD MMMM YYYY')) ?
                                    elm?.is_own === 1 ? null :
                                        winnerParticipateId === 0 && (Moment(today)) <= (Moment(elm?.expire_at)) ?
                                            isParticipate === 1 ?
                                                <Link to="#" className="me-3 btn btn-secondary rounded">Already Participate</Link> :
                                                <Link to="#" className="me-3 btn btn-primary rounded" onClick={() => {
                                                    handleParticipateModalShow()
                                                }}>
                                                    <i className="ri-user-add-line me-1"></i>Participate
                                                </Link> : null
                                }
                            </div>

                            {/* {
                                elm?.participates.length > 0 ?
                                    <div>
                                        <hr />
                                        <SingleUserParticipate postId={elm?.id} commentElm={elm?.participates[0]} />
                                    </div> : null
                            } */}

                            <Modal scrollable={true} show={commentShow} onHide={handleClose} size="lg" style={{ marginTop: 80, maxHeight: "650px" }}>
                                <Modal.Header className="d-flex justify-content-between">
                                    <div className="d-flex justify-content-between">
                                        <div className="me-3">
                                            <img loading="lazy" className="rounded-circle  avatar-40"
                                                src={elm?.user?.user_setting?.photo ? elm?.user?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="" />
                                        </div>
                                        <div className="w-100">
                                            <div className="d-flex justify-content-between flex-wrap">
                                                <div>
                                                    <h5 className="mb-0 d-inline-block"><Link to="#">{elm?.user?.first_name + " " + elm?.user?.last_name}</Link></h5>
                                                    <p className="mb-0">{Moment(elm?.created_at).format('DD MMMM YYYY')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" className="btn btn-secondary lh-1" onClick={handleClose} ><span className="material-symbols-outlined">close</span></button>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="post-item" style={{ maxHeight: "500px" }}>
                                        {
                                            elm?.content ?
                                                <div className="mt-1">
                                                    <p>{elm?.content}</p>
                                                </div> : null
                                        }
                                        <div className="user-post">
                                            <Link to="#">
                                                <ResponsiveMasonry
                                                //columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                                                >
                                                    <Masonry columnsCount={3} gutter="10px">
                                                        {elm?.user_post_images.map((image, i) => (
                                                            image?.media_type === 1 ?
                                                                <div onClick={() => {
                                                                    imageOnSlide(i + 1)
                                                                }}>
                                                                    <div className="img-fluid" style={{ width: 200, height: 200 }} >
                                                                        <img style={{ height: 200, width: 200, marginRight: "17px", }} loading="lazy"
                                                                            src={image?.thumb} alt="gallary" className="img-fluid" />
                                                                        {/* <VideoThumbnail
                                                                            width={200}
                                                                            height={200}
                                                                            videoUrl={image?.image_path} /> */}
                                                                    </div>
                                                                    <Link style={{ position: "absolute", marginTop: "-123px", marginLeft: "70px", zIndex: 1 }}>
                                                                        <img style={{ height: 50, width: 50 }} src={playButton} />
                                                                    </Link>
                                                                </div> :
                                                                <img loading="lazy" src={image?.image_path}
                                                                    alt="post" className="img-fluid w-100"
                                                                    onClick={() => {
                                                                        imageOnSlide(i + 1)
                                                                    }} />
                                                        ))}
                                                    </Masonry>
                                                </ResponsiveMasonry>
                                            </Link>
                                        </div>
                                        <div className="like-block position-relative d-flex align-items-center">
                                            <div className="d-flex align-items-center">
                                                <div style={{ marginTop: "10px" }}>
                                                    <h6 className="mb-0 d-inline-block"
                                                        onClick={() => { getFeedLike(elm?.id) }}
                                                    >
                                                        {
                                                            elm?.is_like === 1 ?
                                                                <img loading="lazy" src={likeFillIcon} alt="icon" className="img-fluid" style={{ marginTop: "-2px" }} /> :
                                                                <img loading="lazy" src={likeIcon} alt="icon" className="img-fluid" style={{ marginTop: "-5px" }} />
                                                        }

                                                        {
                                                            elm?.is_like === 1 ?
                                                                elm?.like_count > 1 ?
                                                                    <Link to="#">You and {((elm?.like_count) - 1) + " "} others</Link> :
                                                                    <Link to="#">You like</Link> :
                                                                <Link to="#" style={{ marginLeft: "6px" }}>{elm?.like_count + " "}like</Link>
                                                        }
                                                    </h6>
                                                    <h6 className="mb-0 d-inline-block" style={{ marginLeft: "10px" }}>
                                                        <Link to="#" onClick={handleCommentShow}
                                                        >{elm?.comment_count + " "}Comment</Link>
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                        <CommentList postId={elm?.id} refreshCommentList={refreshCommentList} setRefreshCommentList={setRefreshCommentList} />
                                    </div>
                                </Modal.Body>
                                <ModalFooter>
                                    <div className="w-100">
                                        <CommentBox postId={elm?.id} commentId={""} setRefreshCommentList={setRefreshCommentList} />
                                    </div>
                                </ModalFooter>
                            </Modal>

                            <Modal scrollable={true} show={postDetailsShow} onHide={handleClose} size="lg" style={{ marginTop: 80 }}>
                                <Modal.Header className="d-flex justify-content-between">
                                    <div className="d-flex justify-content-between">
                                        <div className="me-3">
                                            <img loading="lazy" className="rounded-circle  avatar-40"
                                                src={elm?.user?.user_setting?.photo ? elm?.user?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="" />
                                        </div>
                                        <div className="w-100">
                                            <div className="d-flex justify-content-between flex-wrap">
                                                <div>
                                                    <h5 className="mb-0 d-inline-block"><Link to="#">{elm?.user?.first_name + " " + elm?.user?.last_name}</Link></h5>
                                                    <p className="mb-0">{Moment(elm?.created_at).format('DD MMMM YYYY')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" className="btn btn-secondary lh-1" onClick={handleClose} ><span className="material-symbols-outlined">close</span></button>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="post-item" style={{ maxHeight: "500px" }}>
                                        {
                                            elm?.content ?
                                                <div className="mt-1">
                                                    <p>{elm?.content}</p>
                                                </div> : null
                                        }
                                        <div className="user-post">
                                            <Link to="#">
                                                <ResponsiveMasonry>
                                                    <Masonry columnsCount={3} gutter="10px">
                                                        {elm?.user_post_images.map((image, i) => (
                                                            image?.media_type === 1 ?
                                                                <div onClick={() => {
                                                                    imageOnSlide(i + 1)
                                                                }}>
                                                                    <div className="img-fluid" style={{ width: 200, height: 200 }} >
                                                                        <img style={{ height: 200, width: 200, marginRight: "17px", }} loading="lazy"
                                                                            src={image?.thumb} alt="gallary" className="img-fluid" />
                                                                        {/* <VideoThumbnail
                                                                            width={200}
                                                                            height={200}
                                                                            videoUrl={image?.image_path} /> */}
                                                                    </div>
                                                                    <Link style={{ position: "absolute", marginTop: "-123px", marginLeft: "70px", zIndex: 1 }}>
                                                                        <img style={{ height: 50, width: 50 }} src={playButton} />
                                                                    </Link>
                                                                </div> :
                                                                <img loading="lazy" src={image?.image_path}
                                                                    alt="post" className="img-fluid w-100"
                                                                    onClick={() => {
                                                                        imageOnSlide(i + 1)
                                                                    }} />
                                                        ))}
                                                    </Masonry>
                                                </ResponsiveMasonry>
                                            </Link>
                                        </div>
                                        <div className="like-block position-relative d-flex align-items-center">
                                            <div className="d-flex align-items-center">
                                                <div style={{ marginTop: "10px" }}>
                                                    <h6 className="mb-0 d-inline-block">
                                                        {
                                                            elm?.is_like === 1 ?
                                                                <img loading="lazy" src={likeFillIcon} alt="icon" className="img-fluid" style={{ marginTop: "-2px" }}
                                                                    onClick={() => { getFeedLike(elm?.id) }} /> :
                                                                <img loading="lazy" src={likeIcon} alt="icon" className="img-fluid" style={{ marginTop: "-5px" }}
                                                                    onClick={() => { getFeedLike(elm?.id) }} />
                                                        }

                                                        {
                                                            elm?.is_like === 1 ?
                                                                elm?.like_count > 1 ?
                                                                    <Link to="#">You and {((elm?.like_count) - 1) + " "} others</Link> :
                                                                    <Link to="#">You like</Link> :
                                                                <Link to="#" style={{ marginLeft: "6px" }}>{elm?.like_count + " "}like</Link>
                                                        }
                                                    </h6>
                                                    <h6 className="mb-0 d-inline-block" style={{ marginLeft: "10px" }}>
                                                        <Link to="#"
                                                        // onClick={handleCommentShow}
                                                        >{elm?.comment_count + " "}Comment</Link>
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                        <ParticipateList postId={elm?.id} winnerParticipateId={winnerParticipateId} setWinnerParticipateId={setWinnerParticipateId} />
                                    </div>
                                </Modal.Body>
                                {/* <ModalFooter>
                                    <div className="w-100">
                                        <CommentBox postId={elm?.id} commentId={""} />
                                    </div>
                                </ModalFooter> */}
                            </Modal>

                            <Modal show={participateModalShow} onHide={handleClose} size="lg" style={{ marginTop: 100 }}>
                                <Modal.Header className="d-flex justify-content-between">
                                    <h5 className="modal-title" id="post-modalLabel">Participate on this Challenge</h5>
                                    <button type="button" className="btn btn-secondary lh-1" onClick={handleClose} ><span className="material-symbols-outlined">close</span></button>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="d-flex align-items-center">
                                        {/* <div className="user-img">
                                            <img style={{ height: 60, width: 60 }} loading="lazy" src={profileData?.user_setting?.photo ? profileData?.user_setting?.photo :
                                                "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="userimg" className="avatar-60 rounded-circle img-fluid" />
                                        </div> */}
                                        <form className="post-text ms-3 w-100" action="">
                                            <input type="text" className="form-control rounded" placeholder="Write something here..." style={{ border: "none" }}
                                                value={postContent} onChange={(e) => { setPostContent(e.target.value) }} />
                                        </form>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        {imageArray?.length > 0 || videoArray?.length > 0 ?
                                            <>
                                                {
                                                    imageArray.map((elm, i) => (
                                                        <div className="col-md-2 col-sm-12 col-lg-2">
                                                            <img style={{ height: 70, width: 70, marginRight: "17px", }} loading="lazy" src={elm}
                                                                alt="gallary" className="img-fluid" />
                                                            <Link style={{ position: "absolute", marginTop: "-9px", marginLeft: "-30px", }}
                                                                onClick={() => {
                                                                    let tempImageFile = imageFileArray.slice();
                                                                    tempImageFile.splice(i, 1)
                                                                    setImageFileArray(tempImageFile);
                                                                    let tempImage = imageArray.slice();
                                                                    tempImage.splice(i, 1)
                                                                    setImageArray(tempImage);

                                                                    // imageFileArray.splice(i, 1)
                                                                    // imageArray.splice(i, 1)
                                                                }}>
                                                                <img style={{ height: 25, width: 25 }} src={cross} />
                                                            </Link>
                                                        </div>
                                                    ))
                                                }
                                                {
                                                    videoArray.map((elm, i) => (
                                                        <div className="col-md-2 col-sm-12 col-lg-2" >
                                                            <div style={{ width: 70, height: 70 }} className="img-fluid">
                                                                <img style={{ height: 70, width: 70, marginRight: "17px", }} loading="lazy"
                                                                    src={videoThumbArray[i]} alt="gallary" className="img-fluid" />
                                                                {/* <VideoThumbnail
                                                                    width={70}
                                                                    height={70}
                                                                    videoUrl={elm} /> */}
                                                            </div>
                                                            <Link style={{ position: "absolute", marginTop: "-79px", marginLeft: "55px", }}
                                                                onClick={() => {
                                                                    // videoFileArray.splice(i, 1)
                                                                    // videoArray.splice(i, 1)

                                                                    let tempVideoThumb = videoThumbArray.slice();
                                                                    tempVideoThumb.splice(i, 1)
                                                                    setVideoThumbArray(tempVideoThumb);

                                                                    let tempVideoThumbFile = videoThumbFileArray.slice();
                                                                    tempVideoThumbFile.splice(i, 1)
                                                                    setVideoThumbFileArray(tempVideoThumbFile);

                                                                    let tempVideoFile = videoFileArray.slice();
                                                                    tempVideoFile.splice(i, 1)
                                                                    setVideoFileArray(tempVideoFile);
                                                                }}>
                                                                <img style={{ height: 25, width: 25 }} src={cross} />
                                                            </Link>
                                                            <Link style={{ position: "absolute", marginTop: "-48px", marginLeft: "26px", }}>
                                                                <img style={{ height: 25, width: 25 }} src={playButton} />
                                                            </Link>

                                                        </div>
                                                    ))
                                                }
                                                <hr style={{ marginTop: "20px" }} />
                                            </> : null
                                        }
                                    </div>
                                    <ul className="d-flex flex-wrap align-items-center list-inline m-0 p-0">
                                        <li className="col-md-8 mb-3">
                                            <div className="rounded p-2 pointer me-3"><h5>Add to your post</h5></div>
                                        </li>
                                        <li className="col-md-1 mb-3">
                                            <div className="d-flex justify-content-center bg-soft-primary rounded p-2 pointer me-3">
                                                <label htmlFor="imageUpload1">
                                                    <img loading="lazy" src={small1} alt="icon" className="img-fluid" />
                                                </label>
                                                <input className="file-upload"
                                                    id="imageUpload1" type="file" accept="image/*"
                                                    multiple="multiple"
                                                    onChange={handleImageChange} />
                                            </div>
                                        </li>
                                        <li className="col-md-1 mb-3">
                                            <div className="d-flex justify-content-center bg-soft-primary rounded p-2 pointer me-3">
                                                <label htmlFor="videoUpload1" style={{ maxHeight: "25px" }}>
                                                    <img loading="lazy" src={video} alt="icon" className="img-fluid" style={{ maxHeight: "28px" }}/>
                                                </label>
                                                <input className="file-upload"
                                                    id="videoUpload1" type="file" accept="video/*"
                                                    multiple="multiple"
                                                    onChange={handleVideoChange} />
                                            </div>
                                        </li>
                                        <li className="col-md-1 mb-3">
                                            <div className="d-flex justify-content-center bg-soft-primary rounded p-2 pointer me-3"><Link to="#"></Link><img loading="lazy" src={small2} alt="icon" className="img-fluid" /></div>
                                        </li>
                                        <li className="col-md-1 mb-3">
                                            <div className="d-flex justify-content-center bg-soft-primary rounded p-2 pointer me-3" onClick={() => setShowPicker((val) => !val)}>
                                                <Link to="#"></Link><img loading="lazy" src={small3} alt="icon" className="img-fluid" />
                                            </div>
                                        </li>
                                        {/* <li className="col-md-1 mb-3">
                                            <div className="bg-soft-primary rounded p-2 pointer me-3"><Link to="#"></Link><img loading="lazy" src={small6} alt="icon" className="img-fluid" /></div>
                                        </li> */}
                                    </ul>
                                    {showPicker ?
                                        <EmojiPicker pickerStyle={{ width: "100%" }} onEmojiClick={(emojiObject) => setPostContent((prevMsg) => prevMsg + emojiObject.emoji)} /> : null
                                    }
                                    <hr />
                                    <Button variant="primary" className="d-block w-100 mt-3"
                                        onClick={() => handlePostSubmit()}
                                    >Participate</Button>
                                </Modal.Body>
                            </Modal>

                            <Modal scrollable={true} show={showFlagModal} onHide={handleClose} size="lg" style={{ marginTop: 80 }}>
                                <Modal.Header className="d-flex justify-content-between">
                                    <div className="d-flex justify-content-between">
                                        <div className="w-100">
                                            <div className="d-flex justify-content-between flex-wrap">
                                                <h4 className="mb-0 d-inline-block">Select Flag Reason</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" className="btn btn-secondary lh-1" onClick={handleClose} ><span className="material-symbols-outlined">close</span></button>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="post-item" style={{ maxHeight: "500px" }}>
                                        <ul className="request-list m-0 p-0">
                                            {
                                                reportFlagList?.map((elm, i) => (
                                                    <li key={elm?.id} className="d-flex flex-wrap " style={{ justifyContent: "center", cursor: "pointer" }}
                                                        onClick={() => {
                                                            handleClose()
                                                            flagPostApi(elm?.id)
                                                        }}>
                                                        <h5 className="mb-0 d-flex justify-content-between flex-wrap">
                                                            <Link to="#">{elm?.name}</Link>
                                                        </h5>
                                                    </li>


                                                ))
                                            }
                                        </ul>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    {
                        challengeDetails ?
                            <>
                                <hr />
                                <ParticipateList postId={elm?.id} winnerParticipateId={winnerParticipateId} setWinnerParticipateId={setWinnerParticipateId} />
                            </> : null
                    }
                </Card.Body>
            </Card>
        </>
    )
}

export default ChallengeCard
