import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, OverlayTrigger, Tooltip, Button, Modal, Form } from 'react-bootstrap'
import Card from '../Card'
import { Link, useNavigate, Route } from 'react-router-dom'
import Moment from 'moment';
import { handleImageUpload } from "../../api/uploads";
import { apiHandler } from '../../api/apihandler';
import { AD_POST, AD_PRICE_CALCULATE, FEED_MEDIA_POST } from "../../api/constants";
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { InView } from "react-intersection-observer";
import Swal from 'sweetalert2'
import EmojiPicker from 'emoji-picker-react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import img42 from '../../assets/images/page-img/42.png'
import img9 from '../../assets/images/small/img-1.jpg'
import img10 from '../../assets/images/small/img-2.jpg'
import small1 from '../../assets/images/small/07.png'
import small3 from '../../assets/images/small/09.png'

import playButton from '../../assets/images/icon/play_button.png'
import cross from '../../assets/images/icon/cross.png'

import StripeCardForAd from '../../components/stripe/stripeCardForAd'
import LoaderModal from '../loaderModal';


const CreateNewAd = ({ showCreateAdModal, handleClose, generalSettings, refreshApi }) => {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
    const navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(false);
    const [imageFileArray, setImageFileArray] = useState([]);
    const [imageArray, setImageArray] = useState([]);
    const [videoFileArray, setVideoFileArray] = useState([]);
    const [videoArray, setVideoArray] = useState([]);
    const [adContent, setAdContent] = useState('');
    const [adStartDate, setAdStartDate] = useState("");
    const [adExpiryDate, setAdExpiryDate] = useState("");
    const [adTitle, setAdTitle] = useState("");
    const [adDescription, setAdDescription] = useState("");
    const [adLink, setAdLink] = useState("");
    const [adButtonText, setAdButtonText] = useState("");
    const [adPosition, setAdPosition] = useState(2);
    const [adCalculatedPrice, setAdCalculatedPrice] = useState(0);
    const [showPicker, setShowPicker] = useState(false);

    const adPositionArray = [
        {
            id: 2,
            name: "Middle $" + generalSettings?.ad_center_price
        },
        {
            id: 1,
            name: "Right $" + generalSettings?.ad_right_price
        },
        {
            id: 3,
            name: "Left $" + generalSettings?.ad_left_price
        }
    ]

    const handleAdPositionChange = (e) => {
        e.preventDefault();
        setAdPosition(e.target.value);
        adCalculatedPriceApi(adStartDate, adExpiryDate, e.target.value)
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                //console.log("uploaded", file)
                // setImageFileArray([...imageFileArray, file])
                // setImageArray([...imageArray, reader.result])

                setImageFileArray([file])
                setImageArray([reader.result])
            };
            reader.readAsDataURL(file);
        }
    };

    const handleVideoChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                setVideoFileArray([...videoFileArray, file])
                setVideoArray([...videoArray, reader.result])
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
    }, []);

    async function adCalculatedPriceApi(startDate, expDate, position) {
        setShowLoader(true)
        try {
            const res = await apiHandler(AD_PRICE_CALCULATE, "POST", {
                ad_start_date: startDate,
                ad_end_date: expDate,
                position: position,
            })
            if (res.data?.response?.status === 200) {
                //console.log(res?.data?.data)
                setAdCalculatedPrice(res?.data?.data?.cost)
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setShowLoader(false)
        }
    }

    function handlePostSubmit(token) {
        setShowLoader(true)
        if (adStartDate != "" && adExpiryDate != "" && adTitle != "" && adDescription != "" && adContent != ""
            && adLink != "" && adButtonText != "" && adPosition != "") {
            if (imageArray.length > 0) {
                handleAdvertisePostSubmit(token)
            } else {
                Swal.fire({
                    title: 'Warning!',
                    text: 'Please select advertise image file!',
                    icon: 'warning',
                    confirmButtonText: 'Okay'
                })
                setShowLoader(false)
            }
        } else {
            Swal.fire({
                title: 'Warning!',
                text: 'Please fillup the all fields!',
                icon: 'warning',
                confirmButtonText: 'Okay'
            })
            setShowLoader(false)
        }
    };

    // async function advertisePostApi(isCmplt) {
    //     try {
    //         const res = await apiHandler(AD_POST, "POST", {
    //             is_completed: isCmplt,
    //             position: adPosition,
    //             content: adContent,
    //             ad_title: adTitle,
    //             ad_description: adDescription,
    //             ad_link: adLink,
    //             ad_button_text: adButtonText,
    //             ad_start_date: adStartDate,
    //             ad_end_date: adExpiryDate,
    //             //images: ""
    //         })
    //         if (res.data?.response?.status === 200) {
    //             if (isCmplt === 1) {
    //                 handleClose()
    //                 Swal.fire({
    //                     title: 'Success',
    //                     text: 'Your advertise created successfully',
    //                     icon: 'success',
    //                     confirmButtonText: 'Okay'
    //                 })
    //             } else {
    //                 handleAdvertisePostSubmit(res?.data?.data?.participate?.id)
    //             }
    //         } else {
    //             Swal.fire({
    //                 title: 'Error',
    //                 text: res?.response?.data?.data?.message,
    //                 icon: 'error',
    //                 confirmButtonText: 'Okay'
    //             })
    //         }
    //     } catch (error) {
    //         console.error(error);
    //     }
    //     finally {
    //     }
    // }

    async function handleAdvertisePostSubmit(token) {
        if (imageFileArray.length > 0) {
            for (let elm of imageFileArray) {
                const url = await handleImageUpload(elm, `feedImages/${Date.now()}`, "image/jpeg")
                //console.log("uploaded", url)
                advertiseFinalPostApi(url, token)
            }
        }
    };

    async function advertiseFinalPostApi(awsPath, token) {
        try {
            const res = await apiHandler(AD_POST, "POST", {
                is_completed: "1",
                position: adPosition,
                content: adContent,
                ad_title: adTitle,
                ad_description: adDescription,
                ad_link: adLink,
                ad_button_text: adButtonText,
                ad_start_date: adStartDate,
                ad_end_date: adExpiryDate,
                token: token
            })
            if (res.data?.response?.status === 200) {
                advertiseMediaPostApi(awsPath, res?.data?.data?.postAd?.user_post_id)
            } else {
                Swal.fire({
                    title: 'Error',
                    text: res?.response?.data?.data?.message,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
                //navigate('/auth/sign-in')
                //addToast(res?.response?.data?.data?.message, { appearance: 'error' });
                //console.log("profileRes",res)
            }
        } catch (error) {
            console.error(error);
            setShowLoader(false)
        }
        finally {
        }
    }

    async function advertiseMediaPostApi(awsPath, advertisePostId) {
        try {
            const res = await apiHandler(FEED_MEDIA_POST, "POST", {
                post_id: advertisePostId,
                path: awsPath,
                media_type: 0,
                thumb: ""
            })
            if (res.data?.response?.status === 200) {
                refreshApi()
                handleClose()
                Swal.fire({
                    title: 'Success',
                    text: 'Your advertise created successfully',
                    icon: 'success',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Error',
                    text: res?.response?.data?.data?.message,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setShowLoader(false)
        }
    }

    return (
        <>
            <LoaderModal showLoader={showLoader} />
            <Modal scrollable={true} show={showCreateAdModal} onHide={handleClose} size="lg" style={{ marginTop: 100, maxHeight: "630px" }}>
                <Modal.Header className="d-flex justify-content-between">
                    <h4 className="modal-title" id="post-modalLabel">Create New Advertise</h4>
                    <button type="button" className="btn btn-secondary lh-1" onClick={handleClose} ><span className="material-symbols-outlined">close</span></button>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: "650px" }}>
                    {/* <div className="d-flex align-items-center">
                                <div className="user-img">
                                    <img style={{ height: 50, width: 50 }} loading="lazy" src={profileData?.user_setting?.photo ? profileData?.user_setting?.photo :
                                        "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="userimg" className="avatar-60 rounded-circle img-fluid" />
                                </div>
                                <form className="post-text ms-3 w-100" action="">
                                    <input type="text" className="form-control rounded" placeholder="Ad content here..." style={{ border: "none" }}
                                        value={adContent} onChange={(e) => { setAdContent(e.target.value) }} />
                                </form>
                            </div>
                            <hr /> */}
                    <div className="row">
                        {imageArray?.length > 0 || videoArray?.length > 0 ?
                            <>
                                {
                                    imageArray.map((elm, i) => (
                                        <div className="col-md-2 col-sm-12 col-lg-2">
                                            <img style={{ height: 70, width: 70, marginRight: "17px", }} loading="lazy" src={elm}
                                                alt="gallary" className="img-fluid" />
                                            <Link style={{ position: "absolute", marginTop: "-9px", marginLeft: "-30px", }}
                                                onClick={() => {
                                                    imageFileArray.splice(i, 1)
                                                    imageArray.splice(i, 1)
                                                }}>
                                                <img style={{ height: 25, width: 25 }} src={cross} />
                                            </Link>
                                        </div>
                                    ))
                                }
                                {
                                    videoArray.map((elm, i) => (
                                        <div className="col-md-2 col-sm-12 col-lg-2" >
                                            <div style={{ width: 70, height: 70 }} className="img-fluid">
                                            </div>
                                            <Link style={{ position: "absolute", marginTop: "-79px", marginLeft: "55px", }}
                                                onClick={() => {
                                                    videoFileArray.splice(i, 1)
                                                    videoArray.splice(i, 1)
                                                }}>
                                                <img style={{ height: 25, width: 25 }} src={cross} />
                                            </Link>
                                            <Link style={{ position: "absolute", marginTop: "-48px", marginLeft: "26px", }}>
                                                <img style={{ height: 25, width: 25 }} src={playButton} />
                                            </Link>

                                        </div>
                                    ))
                                }
                                <hr style={{ marginTop: "20px" }} />
                            </> : null
                        }
                    </div>
                    <ul className="d-flex flex-wrap align-items-center list-inline m-0 p-0">
                        <li className="col-md-10 mb-3">
                            <div className="rounded p-2 pointer me-3"><h5>Add to your post</h5></div>
                        </li>
                        <li className="col-md-1 mb-3">
                            <div className="d-flex justify-content-center bg-soft-primary rounded p-2 pointer me-3">
                                <label htmlFor="imageUpload1">
                                    <img loading="lazy" src={small1} alt="icon" className="img-fluid" />
                                </label>
                                <input className="file-upload"
                                    id="imageUpload1" type="file" accept="image/*"
                                    multiple="multiple"
                                    onChange={handleImageChange} />
                            </div>
                        </li>
                        {/* <li className="col-md-1 mb-3">
                                    <div className="bg-soft-primary rounded p-2 pointer me-3">
                                        <label htmlFor="videoUpload1" style={{ maxHeight: "25px" }}>
                                            <img loading="lazy" src={video} alt="icon" className="img-fluid" style={{ maxHeight: "28px" }}/>
                                        </label>
                                        <input className="file-upload"
                                            id="videoUpload1" type="file" accept="video/*"
                                            multiple="multiple"
                                            onChange={handleVideoChange} />
                                    </div>
                                </li> */}
                        {/* <li className="col-md-1 mb-3">
                                    <div className="bg-soft-primary rounded p-2 pointer me-3"><Link to="#"></Link><img loading="lazy" src={small2} alt="icon" className="img-fluid" /></div>
                                </li> */}
                        <li className="col-md-1 mb-3">
                            <div className="d-flex justify-content-center bg-soft-primary rounded p-2 pointer me-3" onClick={() => setShowPicker((val) => !val)}>
                                <Link to="#"></Link><img loading="lazy" src={small3} alt="icon" className="img-fluid" />
                            </div>
                        </li>
                        {/* <li className="col-md-1 mb-3">
                                    <div className="bg-soft-primary rounded p-2 pointer me-3"><Link to="#"></Link><img loading="lazy" src={small6} alt="icon" className="img-fluid" /></div>
                                </li> */}
                    </ul>
                    {showPicker ?
                        <EmojiPicker pickerStyle={{ width: "100%" }} onEmojiClick={(emojiObject) => setAdContent((prevMsg) => prevMsg + emojiObject.emoji)} /> : null
                    }
                    <div>
                        <hr />
                        <Row className="align-items-center">
                            <Form.Group className="form-group col-sm-6">
                                <Form.Label htmlFor="adTitle" className="form-label">Ad Title</Form.Label>
                                <Form.Control type="text" className="mb-0" placeholder="Ad Title"
                                    value={adTitle} onChange={(e) => setAdTitle(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                                <Form.Label htmlFor="adLink" className="form-label">Ad Link</Form.Label>
                                <Form.Control type="text" className="mb-0" placeholder="Ad Link"
                                    value={adLink} onChange={(e) => setAdLink(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                                <Form.Label htmlFor="adContent" className="form-label">Ad Content</Form.Label>
                                <Form.Control type="text" className="mb-0" placeholder="Ad Content"
                                    value={adContent} onChange={(e) => setAdContent(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                                <Form.Label htmlFor="adDescription" className="form-label">Ad Description</Form.Label>
                                <Form.Control type="text" className="mb-0" placeholder="Ad Description"
                                    value={adDescription} onChange={(e) => setAdDescription(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-4">
                                <Form.Label htmlFor="startingDate" className="form-label">Starting Date</Form.Label>
                                <Form.Control type="date" className="mb-0" placeholder="Starting Date"
                                    min={new Date().toISOString().split("T")[0]}
                                    value={adStartDate} onChange={(e) => {
                                        setAdStartDate(e.target.value)
                                        adCalculatedPriceApi(e.target.value, adExpiryDate, adPosition)
                                    }} />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-4">
                                <Form.Label htmlFor="expiryDate" className="form-label">Expiry Date</Form.Label>
                                <Form.Control type="date" className="mb-0" placeholder="Expiry Date"
                                    min={new Date().toISOString().split("T")[0]}
                                    value={adExpiryDate} onChange={(e) => {
                                        setAdExpiryDate(e.target.value)
                                        adCalculatedPriceApi(adStartDate, e.target.value, adPosition)
                                    }} />
                            </Form.Group>
                            {/* <Form.Group className="form-group col-sm-6">
                                        <Form.Label htmlFor="adPosition" className="form-label">Ad Position</Form.Label>
                                        <Form.Control type="text" className="mb-0" id="dob" placeholder="Ad Position"
                                            value={adPosition} onChange={(e) => {
                                                setAdPosition(e.target.value)
                                                adCalculatedPriceApi()
                                                }} />
                                    </Form.Group> */}
                            <Form.Group className="form-group col-sm-4">
                                <Form.Label>Add position</Form.Label>
                                <Form.Select id="validationDefault04" required value={adPosition} onChange={handleAdPositionChange}>
                                    {adPositionArray.map((elm, i) => (
                                        <option value={elm?.id}>
                                            {elm?.name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                                <Form.Label htmlFor="adButtonText" className="form-label">Ad Button Text</Form.Label>
                                <Form.Control type="text" className="mb-0" placeholder="Ad Button Text"
                                    value={adButtonText} onChange={(e) => setAdButtonText(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                                <Form.Label htmlFor="adPosition" className="form-label">Ad Calculated Price</Form.Label>
                                <h4>${adCalculatedPrice}</h4>
                            </Form.Group>
                        </Row>
                    </div>

                    <hr />
                    <div style={{ marginTop: "20px" }}>
                        <Elements stripe={stripePromise}>
                            <StripeCardForAd handlePostSubmit={handlePostSubmit} />
                        </Elements>
                    </div>

                    {/* <Button variant="primary" className="d-block w-100 mt-3"
                                onClick={() => { 
                                    // handlePostSubmit()
                                    }}>Create New Advertise</Button> */}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CreateNewAd