import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Modal } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link, useNavigate, Route } from 'react-router-dom'
import { InView } from "react-intersection-observer";
import { apiHandler } from '../../../api/apihandler';
import { FOLLOW_UNFOLLOW, PEOPLE_YOU_MAY_KNOW, PROFILE_DETAILS } from "../../../api/constants";

import LoaderModal from '../../../components/loaderModal';

// img

import user5 from '../../../assets/images/user/05.jpg'
import user6 from '../../../assets/images/user/06.jpg'
import user7 from '../../../assets/images/user/07.jpg'
import user8 from '../../../assets/images/user/08.jpg'
import user9 from '../../../assets/images/user/09.jpg'
import user10 from '../../../assets/images/user/10.jpg'
import user11 from '../../../assets/images/user/11.jpg'
import user12 from '../../../assets/images/user/12.jpg'
import user13 from '../../../assets/images/user/13.jpg'
import user14 from '../../../assets/images/user/14.jpg'
import user15 from '../../../assets/images/user/15.jpg'
import user16 from '../../../assets/images/user/16.jpg'
import user17 from '../../../assets/images/user/17.jpg'
import loader from '../../../assets/images/page-img/page-load-loader.gif'
//Sweet alert
import Swal from 'sweetalert2'
import NoDataFound from '../../../components/noDataFound';


const FriendReqest = () => {

   const navigate = useNavigate();
   const [peopleYouMayKnow, setPeopleYouMayKnow] = useState([]);
   const [page, setPage] = useState(1);
   const [maxPage, setMaxPage] = useState(1);
   const [showLoader, setShowLoader] = useState(false);
   const [showMoreDataLoader, setShowMoreDataLoader] = useState(false);

   const [isFirst, setIsFirst] = useState(true);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        setIsFirst(false)
    }, [isFirst]);

   useEffect(() => {
      //console.log("token",localStorage.getItem("authToken"))
      if (localStorage.getItem("uzoneAuthToken")) {
         setPeopleYouMayKnow([])
         getPeopleYouMayKnow(1)
      } else {
         navigate('/auth/sign-in')
      }
   }, []);

   const loadMore = (inView) => {
      if (inView) {
         if (page < maxPage) {
            getPeopleYouMayKnow(page + 1)
            //console.log("scroll working...", page + 1)
            setPage(page + 1)
         }
      }
   };

   async function getPeopleYouMayKnow(pageVal) {
      setShowMoreDataLoader(true)
      //setShowLoader(true)
      try {
         const res = await apiHandler(PEOPLE_YOU_MAY_KNOW, "POST", {
            page: pageVal,
            limit: 10
         })
         if (res.data?.response?.status === 200) {
            setMaxPage(res?.data?.data?.users?.meta?.last_page)
            //console.log("profileRes", maxPage)
            if (pageVal === 1) {
               setPeopleYouMayKnow(res?.data?.data?.users?.data)
            } else {
               setPeopleYouMayKnow(peopleYouMayKnow.concat(res?.data?.data?.users?.data))
            }

         } else {
            navigate('/auth/sign-in')
            //addToast(res?.response?.data?.data?.message, { appearance: 'error' });
            //console.log("profileRes",res)
         }
      } catch (error) {
         console.error(error);
      }
      finally {
         setShowMoreDataLoader(false)
         //setShowLoader(false)
      }
   }

   async function getFollowUnfollow(followerId, index, isFollowingVal) {
      try {
         const res = await apiHandler(FOLLOW_UNFOLLOW, "POST", {
            follower_id: followerId
         })
         if (res.data?.response?.status === 200) {
            let temporaryarray = peopleYouMayKnow.slice();
            temporaryarray[index]['is_following'] = isFollowingVal;
            setPeopleYouMayKnow(temporaryarray);
         } else {
            Swal.fire({
               title: 'Error',
               text: res?.response?.data?.data?.message,
               icon: 'error',
               confirmButtonText: 'Okay'
            })
         }
      } catch (error) {
         //console.error(error);
      }
      finally {
      }
   }

   const questionAlert = () => {
      const swalWithBootstrapButtons = Swal.mixin({
         customClass: {
            cancelButton: 'btn btn-outline-primary btn-lg ms-2',
            confirmButton: 'btn btn-primary btn-lg',
         },
         buttonsStyling: false
      })

      swalWithBootstrapButtons.fire({
         title: 'Are you sure?',
         text: "You won't be able to revert this!",
         icon: 'warning',
         showCancelButton: true,
         cancelButtonText: 'cancel',
         confirmButtonText: 'Yes, delete it!',

         reverseButtons: false,
         showClass: {
            popup: 'animate__animated animate__zoomIn'
         },
         hideClass: {
            popup: 'animate__animated animate__zoomOut'
         }

      }).then((result) => {
         if (result.isConfirmed) {
            swalWithBootstrapButtons.fire({
               title: 'Deleted!',
               text: 'Your Request has been deleted.',
               icon: 'success',
               showClass: {
                  popup: 'animate__animated animate__zoomIn'
               },
               hideClass: {
                  popup: 'animate__animated animate__zoomOut'
               }
            })
         } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
         ) {
            swalWithBootstrapButtons.fire({
               title: 'Your Request is safe!',
               showClass: {
                  popup: 'animate__animated animate__zoomIn'
               },
               hideClass: {
                  popup: 'animate__animated animate__zoomOut'
               }
            })
         }
      })
   }

   return (
      <>
         <LoaderModal showLoader={showLoader}/>
         <div id='content-page' className='content-page'>
            <Container>
               <Row>
                  <Col sm="12">
                     <Card>
                        <Card.Header className="d-flex justify-content-between">
                           <div className="header-title">
                              <h4 className="card-title">People You May Know</h4>
                           </div>
                        </Card.Header>
                        <Card.Body>
                           <ul className="request-list m-0 p-0">
                              {peopleYouMayKnow?.length > 0 ? peopleYouMayKnow.map((elm, i) => (
                                 <li className="d-flex align-items-center  flex-wrap">
                                    <div className="user-img img-fluid flex-shrink-0">
                                       <img src={elm?.user_setting?.photo ? elm?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"}
                                          alt="story-img" className="rounded-circle avatar-40" />
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                       <Link to={`/dashboard/app/friend-profile/${elm?.slug}`}>
                                          <h5>{elm?.first_name && (elm?.first_name + " " + elm?.last_name)}</h5>
                                       </Link>
                                       <p className="mb-0">{elm?.follower_count + " Followers " + " ● " + elm?.following_count + " Following "}</p>
                                    </div>
                                    <div className="d-flex align-items-center mt-2 mt-md-0">
                                       {
                                          elm?.is_following == 0 ?
                                             <Link to="#" className="me-3 btn btn-primary rounded"
                                                onClick={() => {
                                                   getFollowUnfollow(elm?.id, i, 1)
                                                }}><i className="ri-user-add-line me-1"></i>Follow</Link> :
                                             <Link to="#" className="me-3 btn btn-secondary rounded"
                                                onClick={() => {
                                                   getFollowUnfollow(elm?.id, i, 0)
                                                }}>Following</Link>
                                       }
                                       {/* <Link to="#" className="me-3 btn btn-primary rounded"
                                       onClick={() => {
                                          setPage(page + 1)
                                          getPeopleYouMayKnow()
                                       }}><i className="ri-user-add-line me-1"></i>Follow</Link> */}
                                       {/* <Link to="#" onClick={questionAlert} className="btn btn-secondary rounded" data-extra-toggle="delete" data-closest-elem=".item">Remove</Link> */}
                                    </div>
                                 </li>
                              )): <NoDataFound/>}
                              {
                                 showMoreDataLoader ?
                                    <div className="col-sm-12 text-center">
                                       <img src={loader} alt="loader" style={{ height: "100px" }} />
                                    </div> : null
                              }
                              <InView as="div" onChange={(inView, entry) => loadMore(inView)} />
                           </ul>
                        </Card.Body>
                     </Card>

                  </Col>
               </Row>
            </Container>
         </div>
      </>
   )
}

export default FriendReqest