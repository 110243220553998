import React, { useState, useEffect } from 'react'
import { Card, Container, Form, Nav, Row } from 'react-bootstrap'
import { apiHandler } from '../../../api/apihandler';
import { BLOG_LIST, CMS_DETAILS, PUBLIC_EVENT_LIST } from "../../../api/constants";
import { InView } from "react-intersection-observer";

//profile-header
import ProfileHeader from '../../../components/profile-header'

// images
import img6 from '../../../assets/images/page-img/profile-bg6.jpg'
import loader from '../../../assets/images/page-img/page-load-loader.gif'
import PublicBlogCard from '../../../components/blog/publicBlogCard';

const PublicBlog = () => {
    const [blogList, setBlogList] = useState([]);
    const [page, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [showMoreDataLoader, setShowMoreDataLoader] = useState(false);

    const [isFirst, setIsFirst] = useState(true);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        setIsFirst(false)
    }, [isFirst]);

    useEffect(() => {
        blogRefreshApi()
    }, []);

    function blogRefreshApi() {
        setPage(1)
        getBlogList(1)
    }

    const loadMore = (inView) => {
       if (inView) {
          if (page < maxPage) {
            getBlogList(page + 1)
             setPage(page + 1)
          }
       }
    };


    async function getBlogList(pageVal) {
        setShowMoreDataLoader(true)
        try {
            const res = await apiHandler(BLOG_LIST, "POST", {
                page: pageVal,
                limit: 10
            })
            if (res.data?.response?.status === 200) {
                setMaxPage(res?.data?.data?.blogs?.meta?.last_page)
                if (res?.data?.data?.blogs?.meta?.total > 0) {
                    if (pageVal === 1) {
                        setBlogList(res?.data?.data?.blogs?.data)
                    } else {
                        setBlogList(blogList.concat(res?.data?.data?.blogs?.data))
                    }
                }
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setShowMoreDataLoader(false)
        }
    }


    return (
        <>
            <div style={{ paddingTop: "10px", paddingBottom: "70px", backgroundColor: "#F5F7FE" }}>
                <div className="title-on-header text-center mt-5 mb-5">
                    <div className="data-block">
                        <h1>Our Blogs</h1>
                        <h5>Login to like and comment on our blog</h5>
                    </div>
                </div>
                <div id="content-page" >
                    <Container >
                        <div className="d-grid gap-3 d-grid-template-1fr-19 mb-0">
                            {
                                blogList?.length > 0 ? blogList.map((elm, i) => (
                                    <PublicBlogCard elm={elm} key={elm?.id} />
                                )) : null
                            }
                        </div>
                        {
                            showMoreDataLoader ?
                                <div className="col-sm-12 text-center">
                                    <img src={loader} alt="loader" style={{ height: "100px" }} />
                                </div> : null
                        }
                        <InView as="div" onChange={(inView, entry) => loadMore(inView)} />
                    </Container>
                </div>
            </div>
        </>
    )

}

export default PublicBlog;